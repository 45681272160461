import React, { useEffect, useMemo } from 'react'
import BreadCrumb from "../../../components/common/BreadCrumb";
import { Card, CardBody, CardHeader, Col, Row, Button } from "react-bootstrap";
import { TableResponsive } from "../../../components/common/TableResponsive";
import { NewBatchModal } from './NewBatchModal';
import { UpdateBatchModel } from './UpdateBatchModel';
import { batch } from '../../../helper/api_url';
import { usePageInitialtor } from '../../../helper/custom_hook';
import { batchSetCode, formatDate, timeFormat } from '../../../helper/formatDate';
export const Batch = () => {
    const { tableData, apiHandler } = usePageInitialtor(batch);
    const handleBatchDelete = (batchId, name) => apiHandler.handleDelete({ name: name, id: batchId });
    const columns = useMemo(() => [
        { Header: "SrNo", accessor: "id", Cell: cell => { return (<p className="">{cell.row.index + 1}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
        {
            Header: "Batch Name", HeaderClass: 'text-center', DataClass: 'text-center',
            Cell: cell => {
                const row=cell.row.original;
                return (<p>{batchSetCode(row)}</p> )
            },
        },
        {
            Header: "Day",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (<span>{row.day == "all" ? "Mon to Sat" : "-"} </span>)
            }
        },
        {
            Header: "Announce Date",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (<span>{formatDate(row.announce_date)} </span>)
            }
        },
        {
            Header: "Time",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (<span className={`badge badge-outline-success p-2 ms-1`} >{timeFormat(row.start_time)} to {timeFormat(row.end_time)} </span>)
            }
        },

        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
               
                return (
                    <div className="">
                        {/* <ViewBatchModal data={row} /> */}
                        <UpdateBatchModel data={row} apiHandler={apiHandler} />
                        <Button onClick={() => {const batchCode = batchSetCode(row);  handleBatchDelete(row.id, batchCode);}} className="btn btn-sm btn-soft-danger me-1" >
                            <i className="ri-delete-bin-fill" />
                        </Button>
                    </div>
                )
            },
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                return (
                    <div className="d-flex">
                        <div className="flex-grow-1" data-id="1">
                            <h5 className="fs-13 mb-1">
                                <span>{row?.course?.code}</span>
                                <span>{batchSetCode(row)} </span>
                            </h5>
                            <p className="text-muted mb-0">
                                <span className={`badge badge-outline-success p-2 ms-1`} >{timeFormat(row.start_time)} to {timeFormat(row.end_time)} </span> | Announce : {row.announce_date}  </p>
                        </div>
                        <div className="flex-shrink-0">
                            <div>
                                <UpdateBatchModel data={row} apiHandler={apiHandler} />
                                <button onClick={() => { handleBatchDelete(row.id, row.type) }} className="btn btn-sm btn-soft-danger me-1" data-id="1"> <i className="ri-delete-bin-fill"></i> </button>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    ]);
    return (
        <>
            <BreadCrumb title="Users" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Batch List</h5>
                            <NewBatchModal apiHandler={apiHandler} />
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive columns={columns} data={tableData} noRecord="No Record Batch List" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
