import React from 'react'
import { Modal} from 'react-bootstrap'
import {  role } from '../../../helper/api_url'
import { priority } from '../../../helper/constants';
import { Tooltip } from 'antd';
import { useModalHandler } from '../../../helper/custom_hook';
export function NewRoleModal({ apiHandler }) {
    const {toggleModal,status} = useModalHandler();
    const roleType=[
        {value:'admin',label:'admin'},
        {value:'staff',label:'staff'},
        {value:'student',label:'student'},
        {value:'visitor',label:'visitor'},
    ]
    const priorityTypeDesc=<ul className="list-group">
                <span className='text-center'>Accessable</span>
                <li>Highest Priority: Menu, Modules, Setting</li>
                <li>Medium Priority: Menu, </li>
                </ul>;
    return (
        <>
            <button onClick={toggleModal} className='btn btn-soft-success add-btn waves-effect'>
                <i className="ri-add-line align-bottom me-1"></i>
                <span>Add Role</span>
            </button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>New Role</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={e => apiHandler.handleSubmit(e,role.add,toggleModal)}>
                        <div className="row g-3">
                            <div className="col-12">
                                <div>
                                    <label htmlFor="roleName" className="form-label">Role Name</label>
                                    <input type="text" className="form-control" id='roleName' name="name" placeholder="Enter Role Name" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="type" className="form-label">Role Type</label>
                                    {/* <input type="text" className="form-control" id='type' name="type" placeholder="Enter Role type" /> */}
                                    <select className='form-control' name='type'>
                                        {roleType.map((r, id) => (
                                            <option key={id} value={r.value}>{r.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-6">
                                <div>
                                    <label htmlFor="priority" className="form-label">Priority</label>
                                        <Tooltip  placement="top" title={priorityTypeDesc} ><i className='text-danger ps-1 align-center bx bx-help-circle' /></Tooltip>
                                    <select className='form-control'  name='priority'>
                                        {priority.map((p, id) => (
                                            <option key={id} value={p.value}>{p.key}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-4">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    );
}