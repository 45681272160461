import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { batch,course} from '../../../helper/api_url'
import { swal } from '../../../helper/swal';
import {useModalHandler} from '../../../helper/custom_hook';
export const UpdateBatchModel = ({data,apiHandler}) => {
    const {toggleModal,status} = useModalHandler();
    const [tableData,setTableData]=useState([]);
    useEffect(()=>{
        if(status)
        course.list().then(r=>setTableData(r.data[Object.keys(r.data)[0]])).catch(err=>swal.error(err.response?err.response.data.message:err.message))
      },[status]);
    const handlegetId = (e) => {
        const course_id=e.target.value;
        document.getElementById('course_id').value = course_id;
      };
  return (
    <>
    <button onClick={toggleModal} className='btn btn-sm btn-soft-success me-1'><i className="ri-pencil-fill"></i>  </button>
        <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Update Batch</h5></Modal.Title>
                </Modal.Header>
                    <form onSubmit={e => apiHandler.handleUpdate(e,batch.update,toggleModal)}>
                <Modal.Body>
                        <div className="row g-3">
                            <div className="col-12">
                                <input type="hidden" name="batch_id" defaultValue={data.id} />
                                {/* <div>
                                <label htmlFor="course_name" className="form-label">Select Course</label>
                                    <select id="course_name" name='course_data_id' defaultValue={data.course_id} className='form-control' onChange={handlegetId}>
                                        <option value="">--Select Course--</option>
                                        {tableData.length ? tableData.map((course, idx) => (<option key={idx} value={course.id}>{course.name}</option>)) : (<option disabled >No Fees Found</option>)}
                                    </select>
                                </div> */}
                            </div>
                            <div className="col-12">
                                <input type="hidden" name="course_id" id="course_id" defaultValue={data.course_id} />
                                <div>
                                    {/* <label htmlFor="batchName" className="form-label">Batch Name</label> */}
                                    <input type="hidden" className="form-control" id="batchName" name="name" defaultValue={data.name} placeholder="" />
                                </div>
                            </div>
                            {/* <div className="col-12">
                                <div>
                                    <label htmlFor="semester" className="form-label">Semester</label>
                                    <input type="number" className="form-control" id="semester" name="semester" defaultValue={data.semester} placeholder="" />
                                </div>
                            </div> */}
                            <div className="col-12">
                                <div>
                                    <label htmlFor="announce_date" className="form-label">Announce Date</label>
                                    <input type="date" className="form-control" id="announce_date" name="announce_date" defaultValue={data.announce_date} placeholder="" />
                                </div>
                            </div>
                            
                            <div className="col-6">
                                <div>
                                    <label htmlFor="start_time" className="form-label">Start Time</label>
                                    {/* <input type="hidden" name="start_time" id='stime' /> */}
                                    <input type="time" className="form-control" id='start_time' name="start_time" defaultValue={data.start_time} placeholder=""  />
                                </div>
                            </div>

                            <div className="col-6">
                                <div>
                                    <label htmlFor="end_time" className="form-label">End Time</label>
                                    {/* <input type="hidden" name="end_time" id='etime' /> */}
                                    <input type="time" className="form-control" id='end_time' name="end_time" defaultValue={data.end_time} placeholder='' />
                                </div>
                            </div>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                            <div className="col-lg-12">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update</button>
                                </div>
                            </div>
                            </Modal.Footer>
                    </form>
            </Modal>

    </>
  )
}
