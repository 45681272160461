export const QuestionsTemplate= ()=>{

    const XLSX = require('xlsx');
    
    function createExcelTemplate() {
        // Define the headers
        const headers = ["Subject","Question", "Option A", "Option B", "Option C", "Option D", "Correct Answer"];
    
        // Define some sample data
        const data = [
            ["Computer Fundamental","What is AI?", "Machine", "Device", "Software", "All of these", "D"],
            ["Any subject","Capital of USA?", "New York", "Washington D.C.", "Los Angeles", "Chicago", "B"]
        ];
    
        // Combine headers and data
        const worksheetData = [headers, ...data];
    
        // Create a new workbook and a worksheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    
        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Questions');
    
        // Write the workbook to a file
        XLSX.writeFile(workbook, 'QuestionsTemplate.xlsx');
    }
    
    // Call the function to create the Excel template
    createExcelTemplate();
}

