import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { useModalHandler } from '../../../helper/custom_hook';
import CustomSelect from '../../../components/CustomSelect';
import { ASSET_URL, fee, student } from '../../../helper/api_url';
import { TableResponsive } from '../../../components/common/TableResponsive';
import { swal } from '../../../helper/swal';
import { useDispatch } from "react-redux";
import { setPreloader } from '../../../features/Ui/uiSlice';
import { useNavigate } from 'react-router-dom';

function PaymentFormModal(prop) {
    const { status, toggleModal } = useModalHandler();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [totalPayable, setTotalPayable] = useState(prop.data.fees.reduce((total, fee) => total + parseFloat(fee.amount) * (fee.total_installments - fee.pivot.installment_paid), 0));
    const handleViewInvoice = (id) => {
        navigate('/view-invoice', { state: { invoiceId:id } });
    }
    const handlePaymentSubmit = e => {
        e.preventDefault();
        dispatch(setPreloader({ loader: true, message: "Please wait ..." }));
        const formData = new FormData(e.target);
        const paymentData = {
            payment_info: prop?.data?.fees?.map(fee => ({
                user_fee_id: e.target[`user_fee_id_${fee.id}`].value,
                discount: parseFloat(e.target[`discount_${fee.id}`].value),
                discount_type: 'percentage',
                deposit: parseFloat(e.target[`deposit_${fee.id}`].value || 0),
                dues: (parseFloat(fee.amount) * parseFloat(fee.total_installments)) - (fee.pivot.discount / 100 * (parseFloat(fee.amount) * parseFloat(fee.total_installments))) - fee.pivot.paid_amt
            })),
            user_id: prop.data.id,
            date_time: Date.now(),
            payment_mode: formData.get('payment_mode')
        };
        fee.duesPayment(paymentData).then(res => {
            toggleModal();
            if (res?.data?.student?.fees?.length)
                prop.setTableData(state => state.map(i => i.id === res.data.student.id ? res.data.student : i))
            else prop.setTableData(state => state.filter(i => i.id !== res.data.student.id))
            handleViewInvoice(res.data?.invoice?.id);
        }).catch(err => swal.error('Oppsss...', err.response ? err.response.data.message : err.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: "" })))
    }

    function handleChangeDiscount(e,row) {
        if(row.pivot.discount == 0){
            const total_amount = parseFloat(row.amount) *parseFloat(row.total_installments);
            const discounted_amount = total_amount - (total_amount*e.target.value)/100;
            document.getElementById('deposit_' + row.id).value = (discounted_amount - row.pivot.paid_amt).toFixed(2);
        }
    }
    const calculateTotalPayableAmount = e => {
        let payableAmount = 0 ;
        prop?.data?.fees.map(f=>{
           payableAmount += parseFloat(document.getElementById('deposit_'+f.id).value || 0);
        })
        document.getElementById('total_deposit').value = payableAmount.toFixed(2);
    }
    return (
        <>
            {prop.children ? (<div onClick={toggleModal}>{prop.children}</div>) : (
                <button onClick={toggleModal} className='btn btn-sm btn-soft-success me-1'>
                    Pay
                </button>
            )}
            <Modal backdrop='static' size='lg' className="fade" centered={true} show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Payment  - {prop.data.first_name} {prop.data.last_name}</h5></Modal.Title>
                </Modal.Header>
                <form onSubmit={handlePaymentSubmit}>
                    <Modal.Body>
                        <Row className='px-2 mt-2'>
                            <Col xs={12} className='mb-2' >
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={ASSET_URL + prop?.data.avatar} className='rounded-circle border' width="100px" height="100px" />
                                </div>
                            </Col>
                            <Col xs={12} >
                                <TableResponsive showCustomOptionPage={false} showFilter={false} isPagination={false} columns={[
                                    {
                                        Header: "Fees",
                                        Cell: cell => (<span>
                                            {cell.row.original.name} X <span>{cell.row.original.total_installments}</span>
                                        </span>)

                                    },
                                    {
                                        Header: "Fee - Paid amount = Dues",
                                        HeaderClass: 'text-center',
                                        DataClass: 'text-center',
                                        Cell: cell => (<span>
                                            {(parseFloat(cell.row.original.amount).toFixed(2) * parseFloat(cell.row.original.total_installments)).toFixed(2)} - {cell.row.original.pivot.paid_amt} =
                                            {((parseFloat(cell.row.original.amount) * parseFloat(cell.row.original.total_installments)) - cell.row.original.pivot.paid_amt).toFixed(2)}
                                        </span>)
                                    },
                                    {
                                        Header: "Discount %",
                                        HeaderClass: 'text-center',
                                        DataClass: 'text-center',
                                        Cell: cell => (<div className='w-100 d-flex justify-content-center'>
                                            <input
                                                type='number' 
                                                name={'discount_' + cell.row.original.id}
                                                id={'discount_' + cell.row.original.id}
                                                style={{ maxWidth: '80px' }} step={0.01}
                                                className='form-control'
                                                max={100}
                                                defaultValue={cell.row.original.pivot.discount}
                                                onChange={e=>handleChangeDiscount(e,cell.row.original,)}
                                            />
                                            <input type='hidden' name={'user_fee_id_' + cell.row.original.id} value={cell.row.original.pivot.id} />
                                        </div>)

                                    },
                                    {
                                        Header: "Paytable Amount",
                                        HeaderClass: 'text-center',
                                        DataClass: 'text-center d-flex justify-content-center',
                                        Cell: cell => (<input
                                            className='form-control'
                                            type='number' step={0.01}
                                            style={{ maxWidth: '100px' }}
                                            name={'deposit_' + cell.row.original.id}
                                            id={'deposit_' + cell.row.original.id}
                                            max={(parseFloat(cell.row.original.amount) *
                                                parseFloat(cell.row.original.total_installments)).toFixed(2) -
                                                cell.row.original.pivot.paid_amt}
                                            defaultValue={0.00}
                                            onChange={calculateTotalPayableAmount}
                                        />)
                                    },

                                ]} data={prop?.data?.fees} />
                            </Col>
                            <Col xs={4}>
                                <div className='mb-2'>
                                    <label className='control-label'>Total Amount</label>
                                    <input readOnly value={totalPayable.toFixed(2)} className='form-control' />
                                </div>
                            </Col>
                            
                            <Col xs={4}>
                                <div className='mb-2'>
                                    <label className='control-label'>Payment Mode<span className='text-danger ms-1'>*</span></label>
                                    <CustomSelect isSearchable name="payment_mode" defaultValue={{ label: 'UPI', value: 'upi' }} options={[
                                        { label: 'Cash', value: 'cash' },
                                        { label: 'UPI', value: 'upi' },
                                        { label: 'Online Banking', value: 'online_banking' },
                                        { label: 'NEFT', value: 'neft' },
                                        { label: 'RTGS', value: 'rtgs' },
                                    ]} />
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className='mb-2'>
                                    <label className='control-label'>Total Deposit</label>
                                    <input type='number' id='total_deposit' step={0.01} defaultValue={0} readOnly className='form-control' />
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className='text-end'>
                        <button type="submit" className="btn btn-primary btn-label right ms-auto nexttab nexttab" >
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Pay
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>

    );
}

export default PaymentFormModal