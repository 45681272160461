import React, { useEffect, useMemo, useState } from 'react'
import { ASSET_URL, accounts, fee } from '../../../../helper/api_url'
import BreadCrumb from '../../../../components/common/BreadCrumb'
import { Card, CardBody, CardHeader, Col, Row } from "react-bootstrap"
import { TableResponsive } from '../../../../components/common/TableResponsive'
import { formatDate } from '../../../../helper/formatDate'

function Transactions() {
    const [tableData,setTableData] = useState([]);
    const [isLoading,setIsLoading] = useState(true);
    useEffect(()=>{
        accounts.txnHistory()
        .then(r=>setTableData(r.data.transactions))
        .catch(e=>console.error(e))
        .finally(()=>setIsLoading(false))
    },[]);
    const columns = useMemo(()=>[
        {
            Header: "User",
            accessor: "user_name",
            HeaderClass:'text-center',
            DataClass:'text-center',
        },
        {
            Header: "Transaction Date",
            accessor: "created_at",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell:(cell)=>formatDate(cell.row.original.created_at)
        },
        {
            Header: "Transaction Amount",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell:(cell)=>{
                const row = cell.row.original;
                return (<p>{row.amount} </p>)                
            }
        },
        {
            Header: "Discount",
            accessor: "discount",
            HeaderClass:'text-center',
            DataClass:'text-center',
        },
        {
            Header: "Paid Amount",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell:(cell)=>{
                const row = cell.row.original;
                return (<p className='badge badge-soft-success p-2 ms-1'>{row.paidamt} </p>)                
            }
        },
        {
            Header: "Transaction By",
            accessor: "staff_name",
            HeaderClass:'text-center',
            DataClass:'text-center',
        },
        {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                
                return (
                <div className="d-flex">
                    <div className="flex-grow-1" data-id="1">
                        <p className="mb-0">{row.user_name} | Transaction By: {row.staff_name}</p>
                        <p className="text-muted mb-0">{row.amount} | Pay Amount: <span className='badge badge-soft-success p-2 ms-1'>{row.paidamt}</span></p>
                    </div>
                    <div className="flex-shrink-0">
                        <p className="text-muted mb-0">({formatDate(row.created_at)})</p>
                    </div>
                </div>
                )
            }
        }
    ]);
    return (
        <>
            <BreadCrumb title="Transaction History" prevPage="Home" prevPath="/dashboard" />  
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Recent Transactions</h5>
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={isLoading} columns={columns} data={tableData} noRecord="No Records in Transactin History" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Transactions