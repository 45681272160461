
import Profile from "../pages/Profile/Profile"
import { AddmissionList } from "../pages/admin/Admission/AddmissionList"
import Admission from "../pages/admin/Admission/Admission"
import AllUsers from "../pages/admin/AllUser/AllUsers"
import Staffs from "../pages/admin/AllUser/Staffs"
import Students from "../pages/admin/AllUser/Students"
// import  AdmissionForm  from "../pages/admin/Admission/AdmissionForm"
// import Teachers from "../pages/admin/AllUser/Teacher"
import Visitors from "../pages/admin/AllUser/Visitors"
import { Batch } from "../pages/admin/Batch/Batch"
import Course from "../pages/admin/Course/Course"
import Enquiry from "../pages/admin/Enquiry/Enquiry"
import EnquiryHistroy from "../pages/admin/Enquiry/EnquiryHistroy"
import FollowUp from "../pages/admin/Enquiry/FollowUp"
import DuesList from "../pages/admin/Fees/DuesList"
import Fees from "../pages/admin/Fees/Fees"
import Role from "../pages/admin/Role/Role"
import ForgotPassword from "../pages/auth/ForgotPassword"
import Login from "../pages/auth/Login"
import Home from "../pages/public/Home"
import LandingPage from "../pages/public/LandingPage"
import Locked from "../pages/public/Locked"
import Issues from "../pages/public/Support/Issues"
import Transactions from "../pages/admin/Fees/Transactions/Transactions"
import { Attendance } from "../pages/admin/Attendance/Attendance"
import { AttendanceList } from "../pages/admin/Attendance/AttendanceList"
import StudentDues from "../pages/admin/Accounts/Index"
import { Dashboard } from "../pages/admin/Dashboard"
import ViewInvoice from "../pages/admin/Accounts/ViewInvoice"
import InvoiceList from "../pages/admin/Accounts/InvoiceList"
import AdmissionSlip from "../pages/admin/Admission/AdmissionSlip"
import EnquirySlip from "../pages/admin/Enquiry/EnquirySlip"
import { CreateQuestion } from "../pages/admin/Exam/CreateQuestion"
import { AssignmetList } from "../pages/admin/Assignment/AssignmentList"
import { AddCourseFees } from "../pages/admin/Course/AddCourseFees"
import IDCard from "../pages/admin/AllUser/student/IDCard"

export const authenticatedRoutes = [
    //Menu
    {label:'Report',path:'/report',element:<EnquiryHistroy/>,users:['admin','staff'],children:[]},
    {label:'Report',path:'/add-course',element:<AddCourseFees/>,users:['admin','staff'],children:[]},
    {label:'Admission',path:'/admission/new',element:<Admission />,users:['admin','staff'],children:[]},
    {label:'Profile',path:'/profile',element:<Profile />,users:['admin','staff','student'],children:[]},
    {label:'inovice',path:'/view-invoice',element:<ViewInvoice/>,users:['admin','staff'],children:[]},
    {label:'admission slip',path:'/admission-slip',element:<AdmissionSlip/>,users:['admin','staff'],children:[]},
    {label:'Enquiry slip',path:'/enquiry-slip',element:<EnquirySlip/>,users:['admin','staff'],children:[]},
    {label:'Idcard',path:'/idcard/:studentID',element:<IDCard />,users:['admin','staff','student'],children:[]},
    
    {type:'menu',label:'Dashboard',path:'/dashboard',element:<Dashboard />,icon:'ri-home-8-line',users:['admin','staff','student'],children:[]},
    {type:'menu',label:'Enquiry',path:'/about',icon:'ri-questionnaire-fill',users:['admin','staff'],children:[
        {label:'Enquiry',path:'/enquiry',element:<Enquiry />},
        {label:'Follow Up',path:'/followup',element:<FollowUp />},
        
    ]},
    {type:'menu',label:'User Management',path:'/about',icon:'ri-team-fill',users:['admin','staff'],children:[
        {label:'All Users',path:'/users',element:<AllUsers />,users:['admin']},
        {label:'staff',path:'/staff',element:<Staffs />,users:['admin']},
        {label:'students',path:'/student',element:<Students />},
        {label:'Visitor',path:'/visitor',element:<Visitors />},
    ]},
    {type:'menu',label:'Admission',path:'/admission',element:<AddmissionList/>,icon:' ri-user-add-line',users:['admin','staff'],children:[]},
    {type:'menu',label:'Support',path:'/support',element:<Issues />,icon:'ri-customer-service-2-fill',users:['admin','staff'],children:[]},
    {type:'menu',label:'Attendance',path:'/attendance',element:<Attendance />,icon:' bx bx-user-check',users:['admin',],children:[]},
    {type:'menu',label:'Course',path:'/course',element:<Course />,icon:'ri-book-line',users:['admin'],children:[]},
    {type:'menu',label:'Batch',path:'/batch',element:<Batch />,icon:'ri-time-line',users:['admin'],children:[]},
    {path:'/attendance-list',element:<AttendanceList  />,icon:' ri-customer-service-2-fill',users:['admin','staff'],children:[]},
    // Modules
    {type:'module',label:'Exam Management',path:'/examination',icon:' ri-survey-line',users:['admin'],children:[
        {label:'Questions Bank',path:'/question',element:<CreateQuestion />,users:['admin']},
    ]},
    {type:'module',label:'Assignment Manage',path:'/examination',icon:' ri-survey-line',users:['admin'],children:[
        {label:'Assignment',path:'/assignment',element:<AssignmetList />,users:['admin']},
    ]},
    
    {type:'module',label:'Accounts',icon:' ri-coins-line',users:['admin'],children:[
        // {label:'Dues/Fees',path:'/dues',element:<DuesList/>,users:['admin','staff']},
        {label:'Fees',path:'/fees',element:<Fees />},
        {label:'Students Dues',path:'/student-dues',element:<StudentDues/>,users:['admin','staff']},
        {label:'Invoice',path:'/invoice',element:<InvoiceList/>,users:['admin','staff']},
        {label:'Transactions',path:'/transactions',element:<Transactions/>,users:['admin','staff']},
    ]},
    {type:'module',label:'Annoucements',path:'/services',element:<Locked />,icon:' bx bxs-megaphone',users:['admin'],children:[]},
    {type:'module',label:'Leave Management',path:'/leave',element:<Locked/>,icon:' ri-community-fill',users:['admin',],children:[]},
    {type:'module',label:'Digital Class Room',icon:' ri-live-fill',users:['admin','student','staff'],children:[
        {label:'Live Class',path:'/live-class',element:<Locked />},
        {label:'Video Class',path:'/video-class',element:<Locked />},
    ]},
    {type:'setting',label:'Setting',icon:'bx bx-cog',users:['admin'],children:[
        {label:'Role',path:'/role',element:<Role />},
        // {label:'Course',path:'/course',element:<Course />},
        // {label:'Batch',path:'/batch',element:<Batch />},
        {label:'Fees',path:'/fees',element:<Fees />},
        {label:'Personalization',path:'/persolization',element:<Home/>}
    ]},

    
]
export const publicRoutes = [
    {label:'Home',path:'/',element:<LandingPage /> ,icon:'null',users:[],children:[]},

]
export const authRoutes = [
    {label:'Login',path:'/login',element:<Login /> ,icon:'null',users:[],children:[]},
    {label:'Password Reset',path:'/passwordReset',element:<ForgotPassword /> ,icon:'null',users:[],children:[]},
]