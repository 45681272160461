import React, { useEffect, useState } from 'react'
import SimpleBar from 'simplebar-react';
import BreadCrumb from '../../../components/common/BreadCrumb'
import { Card, CardBody, CardHeader, Col, Row, Button } from "react-bootstrap";
import CustomSelect from '../../../components/CustomSelect';
import { Link } from 'react-router-dom';
import { ASSET_URL, attendance, batch, course } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { batchSetCode, formatDate } from '../../../helper/formatDate';
import { TableResponsive } from '../../../components/common/TableResponsive';
import ImageViewer from '../../../components/ImageViewer';
export const Attendance = () => {
    const [batches, setBatches] = useState([]);
    const [batchId, setBatchId] = useState();
    const [batchStudents, setBatchStudents] = useState([]);
    useEffect(() => {
        batch.list().then(r => setBatches(r.data.batches)).catch(e => console.error(e));
    }, [])
    const handleGetBatchStudents = (e) => {
        setBatchId(e.value);
        batch.students(e.value).then(r => setBatchStudents(r.data.user)).catch(e => console.error(e))
    }
    const handleSaveAttendance = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const studentsAttendance = {
            attendanceData: batchStudents.map(student => ({
                user_id: student?.id,
                attendance: formData.get(`attendance-${student.id}`),
            })),
            batch_id: batchId,
            attendance_date: formatDate(Date.now(),'YYYY-MM-DD'),
        };
        attendance.add(studentsAttendance).then(res => {
            console.log(res)
        }).catch(err => swal.error('Oppsss...', err.response ? err.response.data.message : err.message))
    }

    return (
        <>
        <style>
                {`
                    
                    .form-check-input:checked.rd-success {
                        background-color: #0ab39c; 
                    }

                    .form-check-input:checked.rd-danger {
                        background-color: #f06548; 
                    }

                    .form-check-input:checked.rd-warning {
                        background-color: #f7b84b; 
                    }
                `}
            </style>
            <div className='d-none d-lg-block'><BreadCrumb title="Attendance" prevPage="Home" prevPath="/dashboard" /></div>
            <Row className=''>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Attendance</h5>
                            <Link to="/attendance-list" className='btn btn-soft-dark add-btn waves-effect me-2'>
                                <i className="ri-contacts-fill"></i>
                            </Link>
                        </CardHeader>
                        <CardBody className="">
                            <Row className='my-3'>
                                <div className="col-12 mt-2">
                                    <div>
                                        <label htmlFor="batch" className="form-label">Select Batch</label>
                                        <CustomSelect name="batch_id" id="batch_id" onChange={handleGetBatchStudents} isSearchable options={batches?.map(i => ({ label: batchSetCode(i), value: i.id }))} />
                                    </div>
                                </div>
                            </Row>

                            <div className='d-none d-lg-block'>
                                <form onSubmit={handleSaveAttendance}>
                                    <TableResponsive showCustomOptionPage={false} showFilter={false} isPagination={false} columns={[
                                        {
                                            Header: "Id",
                                            HeaderClass: 'text-center',
                                            DataClass: 'text-center',
                                            accessor: 'id'

                                        },
                                        {
                                            Header: "Student",
                                            Cell: (cell) => {
                                                const imageUrl = ASSET_URL + cell.row.original.avatar;
                                                return (
                                                    <ImageViewer image={imageUrl}>
                                                        <span> <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={imageUrl} alt="User Avatar" />{cell.row.original.first_name} {cell.row.original.last_name}</span>
                                                    </ImageViewer>
                                            )
                                            }

                                        },
                                        {
                                            Header: "Phone",
                                            HeaderClass: 'text-center',
                                            DataClass: 'text-center',
                                            accessor: 'phone'
                                        },
                                        {
                                            Header: formatDate(Date.now()),
                                            HeaderClass: 'text-center',
                                            DataClass: 'text-center',
                                            Cell: cell => {
                                                const row = cell.row.original;
                                                return (
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <div className="form-check text-end">
                                                            <label className="form-check-label" for={`present-${row.id}`}>
                                                                <input style={{fontSize:'20px'}} className="form-check-input rd-success me-2" type="radio" name={`attendance-${row.id}`} id={`present-${row.id}`} value="P" />
                                                                Present
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className="form-check">
                                                            <label className="form-check-label" for={`absent-${row.id}`}>
                                                                <input style={{fontSize:'20px'}} className="form-check-input rd-danger me-2" type="radio" name={`attendance-${row.id}`} id={`absent-${row.id}`} value="A" />
                                                                Absent
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className="form-check">
                                                            <label className="form-check-label" for={`leave-${row.id}`}>
                                                                <input style={{fontSize:'20px'}} className="form-check-input rd-warning me-2" type="radio" name={`attendance-${row.id}`} id={`leave-${row.id}`} value="L" />
                                                                On Leave
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                            }
                                        },

                                    ]} data={batchStudents} />
                                    <div className="d-lg-flex align-items-start gap-3 mt-4 d-none" id='showSaveAttendance'>
                                        <button type="submit" className="btn btn-primary btn-label right ms-auto nexttab nexttab" ><i className="bx bxs-send label-icon align-middle fs-16 ms-2"></i>Save</button>
                                    </div>

                                </form>
                            </div>
                            <div className="d-lg-none">
                                <div id="user_list_lst_container">
                                    <div className='row mb-3'>
                                        <div className='col'>
                                            {/* <input type="text" className='form-control' placeholder="Search..." value={searchQuery} onChange={e => setSearchQuery(e.target.value)} /> */}
                                        </div>
                                    </div>
                                    <form onSubmit={handleSaveAttendance}>
                                        <ul className="list list-group list-group-flush mb-0">
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={6}><span className='fw-bold'>Candidate Name </span></Col>
                                                    <Col className='text-center' xs={6}>
                                                        <span className='text-wrap'>{formatDate(Date.now())} </span>
                                                    </Col>
                                                </Row>
                                            </li>
                                            <SimpleBar style={{ height: "calc(90vh - 350px)" }}>
                                                {batchStudents?.map((student, index) => (
                                                    <li key={index} className="list-group-item ">
                                                        <Row>
                                                            <Col xs={6}><span className='fw-bold'><span> <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={ASSET_URL + student.avatar} alt="User Avatar" /> {student.first_name} {student.last_name}</span> </span></Col>

                                                            <Col className='d-flex align-items-center justify-content-between' xs={6}>
                                                              
                                                                        <div className="form-check d-flex align-items-center">
                                                                            <label style={{lineHeight:'28px'}} className="form-check-label" for={`present-${student.id}`}>
                                                                                <input style={{fontSize:'24px'}} className="form-check-input rd-success" type="radio" name={`attendance-${student.id}`} id={`present-${student.id}`} value="P" />
                                                                                P
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check d-flex align-items-center">
                                                                            <label style={{lineHeight:'28px'}} className="form-check-label" for={`absent-${student.id}`}>
                                                                                <input style={{fontSize:'24px'}} className="form-check-input rd-danger" type="radio" name={`attendance-${student.id}`} id={`absent-${student.id}`} value="A" />
                                                                                A
                                                                            </label>
                                                                        </div>
                                                                    
                                                                        <div className="form-check d-flex align-items-center">
                                                                            <label style={{lineHeight:'28px'}} className="form-check-label" for={`leave-${student.id}`}>
                                                                                <input style={{fontSize:'24px'}} className="form-check-input rd-warning" type="radio" name={`attendance-${student.id}`} id={`leave-${student.id}`} value="L" />
                                                                                L
                                                                            </label>
                                                                        </div>
                                                                    
                                                            </Col>
                                                        </Row>
                                                    </li>

                                                ))}
                                            </SimpleBar>
                                        </ul>
                                        <div className="d-flex align-items-start gap-3 mt-4" id='showSaveAttendanceM'>
                                            <button type="submit" className="btn btn-primary btn-label right ms-auto nexttab nexttab" ><i className="bx bxs-send label-icon align-middle fs-16 ms-2"></i>Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className='flex-column d-none'>
                <Col xs={12} className='d-flex justify-content-center'>
                    <Card style={{ maxWidth: '380px' }}>
                        <Card.Header>
                            <Card.Title>Student Name</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col className='d-flex justify-content-center mb-3'>
                                    <img src="https://skicst.org/backend/assets/images/users/avatar/h2e8mlUWxkOQLmqhEX3RvTl1OqMKDRFU1wvU9dgo.jpg"
                                        height={'100px'} style={{ aspectRatio: '1/1', borderRadius: '8px' }} />
                                </Col>
                                <Col xs={12}>
                                    <ul class="list-group">
                                        <li class="list-group-item">
                                            <div class="row">
                                                <div class="col-5"><span class="fw-bold">Father's Name </span> </div>
                                                <div class="col-1"><span class="fw-bold">:</span></div>
                                                <div class="text-start col-6"><span class="text-nowrap"> Vidya Sagar yadav </span></div>
                                            </div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="row">
                                                <div class="col-5"><span class="fw-bold">Fee Dues </span></div>
                                                <div class="col-1"><span class="fw-bold">:</span></div>
                                                <div class="text-start col-6"><span>&#8377; 450.00 /- </span></div>
                                            </div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="row">
                                                <div class="col-5"><span class="fw-bold">Course </span> </div>
                                                <div class="col-1"><span class="fw-bold">:</span></div>
                                                <div class="text-start col-6"><span class="text-nowrap"> DOAP </span></div>
                                            </div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="row">
                                                <div class="col-5"><span class="fw-bold">Total Present </span> </div>
                                                <div class="col-1"><span class="fw-bold">:</span></div>
                                                <div class="text-start col-6"><span class="text-nowrap"> 15 </span></div>
                                            </div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="row">
                                                <div class="col-5"><span class="fw-bold">Total Absent </span> </div>
                                                <div class="col-1"><span class="fw-bold">:</span></div>
                                                <div class="text-start col-6"><span class="text-nowrap"> 05 </span></div>
                                            </div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="row">
                                                <div class="col-5"><span class="fw-bold">Attendance </span> </div>
                                                <div class="col-1"><span class="fw-bold">:</span></div>
                                                <div class="text-start col-6"><span class="text-nowrap"> 75% </span></div>
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xs={12}>
                                    <div className='d-flex justify-content-between align-items-center mt-4'>
                                        <button className='btn btn-soft-info'><i className="ri-phone-fill"></i></button>
                                        <button className='btn btn-soft-success'><i className="ri-whatsapp-fill"></i></button>
                                        <button className='btn btn-soft-warning'><i className="ri-mail-fill"></i></button>
                                        <button className='btn btn-soft-danger'><i className="bx bx-block"></i></button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className='d-flex justify-content-end align-items-center'>
                            <button className='btn btn-success me-2'>Present</button>
                            <button className='btn btn-danger'>Absent</button>
                        </Card.Footer>
                    </Card>
                </Col>

            </Row>
        </>
    )
}
