import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { ASSET_URL, student } from '../../../helper/api_url';
import image_helper from '../../../helper/image_helper';
import { formatDate } from '../../../helper/formatDate';
import QRCode from 'react-qr-code';
import { useLocation } from 'react-router-dom';

function AdmissionSlip() {
    const { state } = useLocation();
    const [studentData, setStudentData] = useState(null);
    const [qrData, setQrData] = useState(null);
    const [signature, setSignature] = useState(null);
    const id = state && state.id;
    useEffect(() => {
        student.profile(id).then(res => {
            setStudentData(res.data.profile)
            setQrData(res.data.qrData)
            setSignature(res.data.hash)
        }).catch(e => console.error(e))
    }, []);
    const date = new Date();
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const currentMonth = monthNames[date.getMonth()];
    const currentYear = date.getFullYear().toString().slice(-2);
    return (
        <>
        <style>
                {`
                    .logo-container {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                    }

                    .card-logo {
                        width: 100%;
                        height: 50px;
                        object-fit: contain;
                    }

                    .card-logo-dark, .card-logo-light {
                        max-width: 400px;
                    }
                `}
            </style>
            <Row className='d-sm-flex justify-content-center border' style={{ margin: '40px 0', padding: '40px 0' }}>
                <Col md={6} className='d-sm-flex justify-content-center'>
                    <Card style={{ width: '350px', height: '250px', overflow: 'hidden' }} className='border border-primary'>
                        <Card.Header className='bg-soft-success d-flex align-items-center justify-content-between border-bottom py-2'>
                            <div className='logo-container'>
                                <img src={image_helper.logo} className="card-logo card-logo-dark" alt="logo dark" height="50" width={300} />
                                <img src={image_helper.logo} className="card-logo card-logo-light" alt="logo light" height="50" width={300} />
                            </div>
                            <Card.Title className='m-0 fs-6 fw-bold'></Card.Title>
                        </Card.Header>
                        <Card.Body style={{ padding: '15px', height: '200px' }}>
                            <Row>
                                <Col xs={12} className='d-flex align-items-center justify-content-center border-bottom  mb-2 '>
                                    <Card.Title style={{ fontSize: '10px' }} className='m-0 fw-bold'>I-Card</Card.Title>
                                </Col>
                                <Col xs={4}>
                                    <img
                                        src={ASSET_URL + studentData?.avatar}
                                        alt="Header Avatar"
                                        className='rounded'
                                        height={100}
                                        style={{ aspectRatio: '1/1', objectFit: 'cover' }}
                                    />
                                </Col>
                                <Col xs={8}>
                                    <Row>
                                        <Col xs={5} style={{ fontSize: '8px' }} className='text-right fw-bold mb-1'> User Id </Col>
                                        <Col xs={7} style={{ fontSize: '8px' }} className='text-right'>: <span className='ms-1'>SK01{currentMonth}{currentYear}0{studentData?.id}</span></Col>
                                        <Col xs={5} style={{ fontSize: '8px' }} className='text-right fw-bold mb-1'>Student Name </Col>
                                        <Col xs={7} style={{ fontSize: '8px' }} className='text-right fw-bold mb-1'>: <span className='ms-1'> {studentData?.first_name} {studentData?.last_name} </span></Col>
                                        <Col xs={5} style={{ fontSize: '8px' }} className='text-right fw-bold mb-1'> Father's Name</Col>
                                        <Col xs={7} style={{ fontSize: '8px' }} className='text-right'>: <span className='ms-1'>{studentData?.personal_details?.father_name}</span></Col>
                                        <Col xs={5} style={{ fontSize: '8px' }} className='text-right fw-bold mb-1'> Dob </Col>
                                        <Col xs={7} style={{ fontSize: '8px' }} className='text-right'>: <span className='ms-1'>{formatDate(studentData?.dob)}</span></Col>
                                        <Col xs={5} style={{ fontSize: '8px' }} className='text-right fw-bold mb-1'> Course </Col>
                                        <Col xs={7} style={{ fontSize: '8px' }} className='text-right'>: <span className='ms-1'>{
                                            studentData?.courses?.map((c, i) => (<span key={i} className='badge badge-soft-primary me-1'>{c.name}</span>))
                                        }</span></Col>
                                        <Col xs={5} style={{ fontSize: '8px' }} className='text-right fw-bold mb-1'> Joining Date</Col>
                                        <Col xs={7} style={{ fontSize: '8px' }} className='text-right'>: <span className='ms-1'> {formatDate(studentData?.created_at)}</span></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer style={{ fontSize: '10px' }} className='d-flex justify-content-between align-items-center py-1'>
                            skicst.org <span>Issue Date : {formatDate(Date.now())}</span>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col className='d-sm-flex justify-content-center' md={6}>
                    <Card style={{ width: '350px', height: '250px', overflow: 'hidden' }} className='border border-primary'>
                        <Card.Header className='bg-soft-success d-flex align-items-center justify-content-between border-bottom py-2'>
                            <div>
                                <img src={image_helper.logo} className="card-logo card-logo-dark" alt="logo dark" height="22" />
                                <img src={image_helper.logo} className="card-logo card-logo-light" alt="logo light" height="22" />
                            </div>
                            {/* <Card.Title className='m-0 fs-6 fw-bold'>{studentData?.first_name} {studentData?.last_name}</Card.Title> */}
                        </Card.Header>
                        <Card.Body style={{ padding: '15px', height: '180px' }}>
                            <Row>
                                <Col xs={7}>
                                    <Row>
                                        <Col xs={4} style={{ fontSize: '8px' }} className='text-right fw-bold mb-1'> Address </Col><Col xs={8} style={{ fontSize: '8px' }} className='text-right'>:
                                            <div className='ms-1 d-inline'>
                                                <span className='me-1'>{studentData?.personal_details?.current_address?.addr1} </span>
                                                <span className='me-1'>{studentData?.personal_details?.current_address?.addr2} </span>
                                            </div>
                                        </Col>
                                        <Col xs={4} style={{ fontSize: '8px' }} className='text-right fw-bold mb-1'> City </Col>
                                        <Col xs={8} style={{ fontSize: '8px' }} className='text-right'>: <span className='ms-1'>{studentData?.personal_details?.current_address?.city}</span></Col>
                                        <Col xs={4} style={{ fontSize: '8px' }} className='text-right fw-bold mb-1'> District </Col>
                                        <Col xs={8} style={{ fontSize: '8px' }} className='text-right'>: <span className='ms-1'>{studentData?.personal_details?.current_address?.district}</span></Col>
                                        <Col xs={4} style={{ fontSize: '8px' }} className='text-right fw-bold mb-1'> State</Col>
                                        <Col xs={8} style={{ fontSize: '8px' }} className='text-right'>: <span className='ms-1'>{studentData?.personal_details?.current_address?.state}</span></Col>
                                        <Col xs={4} style={{ fontSize: '8px' }} className='text-right fw-bold mb-1'> Pin</Col>
                                        <Col xs={8} style={{ fontSize: '8px' }} className='text-right'>: <span className='ms-1'>{studentData?.personal_details?.current_address?.pin}</span></Col>
                                    </Row>
                                </Col>
                                <Col xs={5}>
                                    <QRCode
                                        size={112}
                                        value={btoa(JSON.stringify(qrData)) + '.' + signature}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer style={{ fontSize: '10px' }} className='d-flex justify-content-between align-items-center py-2'>
                            skicst.org
                            <span>Issue Date : {formatDate(Date.now())}</span>
                        </Card.Footer>
                    </Card>
                </Col>

            </Row>
            <Row>
                <Col className=''>
                    <div className="hstack gap-2 justify-content-center d-print-none mt-4">
                        <a href="javascript:window.print()" className="btn btn-success  px-5"><i className="ri-printer-line align-bottom me-1"></i> Print</a>
                    </div>
                </Col>
            </Row>
        </>
    )
}
export default AdmissionSlip