import React, { useEffect, useMemo, useState } from 'react'
import BreadCrumb from '../../../components/common/BreadCrumb'
import { TableResponsive } from '../../../components/common/TableResponsive';
import { Button, Card, CardBody, CardHeader, CardImg, Col, Row } from 'react-bootstrap';
import { CreateQuestionModal } from './CreateQuestionModal';
import { ASSET_URL, exam } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import ImageViewer from '../../../components/ImageViewer';
import { UpdateQuestionModal } from './UpdateQuestionModal';
import { QuestionsTemplate } from '../../../helper/QuestionsTemplate';

export const CreateQuestion = () => {
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false)
    useEffect(() => {
        setTableLoading(true);
        exam.read_question_or_assignment("question")
            .then(res => setTableData(res.data.question))
            .catch((err) => swal.error(err.response ? err.response.data.message : err.message))
            .finally(() => {
                setTableLoading(false);
            });
    }, [])
    const handleQuestionDelete = (qid) => {
        dispatch(setPreloader({ loader: true, message: 'please wait' }));
        exam.delete_question_or_assignment(qid)
            .then((res) => {
                setTableData(state => state.filter(item => item.id !== res.data.question.id));
                swal.success(res.message);
            })
            .catch((err) => swal.error(err.response ? err.response.data.message : err.message))
            .finally(() => {
                dispatch(setPreloader({ loader: false, message: "" }));
            });
    }
    const columns = useMemo(() => [
        { Header: "SrNo", accessor: "id", Cell: cell => { return (<p className="">{cell.row.index + 1}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: 'Subject Name', accessor: 'subject', DataClass: 'text-center', HeaderClass: 'text-center' },
        {
            Header: "Question",
            HeaderClass: 'text-center',
            DataClass: 'text-center bg-soft-warning',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        <p>{row.instruction}</p>
                        {row.image_video ? <ImageViewer image={ASSET_URL + row.image_video}>
                            <CardImg src={ASSET_URL + row.image_video} style={{ aspectRatio: '16/7', objectFit: 'cover', height: 'auto' }} alt="issue screenshot" />
                        </ImageViewer> : ''}
                    </>)
            }
        },
        { Header: 'Option A', accessor: 'options[0].option_text', DataClass: 'text-center', HeaderClass: 'text-center' },
        { Header: 'Option B', accessor: 'options[1].option_text', DataClass: 'text-center', HeaderClass: 'text-center' },
        { Header: 'Option C', accessor: 'options[2].option_text', DataClass: 'text-center', HeaderClass: 'text-center' },
        { Header: 'Option D', accessor: 'options[3].option_text', DataClass: 'text-center', HeaderClass: 'text-center' },
        {
            Header: "Answer",
            HeaderClass: 'text-center',
            DataClass: 'text-center bg-soft-success',
            Cell: (cell) => {
                const row = cell.row.original;
                return (<>{row?.options?.map((item, idx) => (<span>{item.is_answer ? item.option_text : ''}</span>))}</>)
            }
        },
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <div className="">
                        {/* <UpdateQuestionModal setTableData={setTableData} row={row}  /> */}
                        <Button onClick={() => handleQuestionDelete(row.id)} className="btn btn-sm btn-soft-danger me-1" ><i className="ri-delete-bin-fill" /></Button>
                    </div>
                )
            },
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                // const index=1
                return (
                    <div className="d-flex">
                        <div className="flex-grow-1" data-id="1">
                                <p>{parseInt(row.index) + 1}. {row.instruction}</p>
                                <ol type="A">
                                    {row.options.map((option, index) => (
                                        <li key={index}>
                                            {index===0?"A). ":index===1?"B). ":index===2?"C). ":index===3?"D). ":''}
                                            {option.is_answer === 1 ? (
                                                <span className='badge badge-outline-success'>
                                                    {option.option_text}
                                                </span>
                                            ) : (
                                                option.option_text
                                            )}
                                        </li>
                                    ))}
                                </ol>
                        
                        </div>
                        <div className="flex-shrink-0">
                            <div>
                                <Button onClick={() => handleQuestionDelete(row.id)} className="btn btn-sm btn-soft-danger me-1">
                                    <i className="ri-delete-bin-fill" />
                                </Button>
                            </div>
                        </div>
                    </div>

                )
            }
        }
    ]);
    return (
        <>
            <BreadCrumb title="Questions Bank" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">All Question's List</h5>

                            <Button onClick={() => { QuestionsTemplate(); }} className="btn btn-primary ms-3 d-none d-lg-block">
                                Download Sample Template
                            </Button>

                            <CreateQuestionModal setTableData={setTableData} />
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={tableLoading} columns={columns} data={tableData} noRecord="No Record Question List" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
