import React from 'react'
import { Row, Col, Modal,Card,CardHeader,CardBody} from 'react-bootstrap';
import { useModalHandler } from '../../../helper/custom_hook';
import { batchSetCode, formatDate, timeFormat } from '../../../helper/formatDate';
export const ViewBatchModal = ({batch}) => {
    const { status, setStatus, toggleModal } = useModalHandler();
    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-info me-1 mx-2'>
                {batch?.map((item,idx)=><span key={idx} className='px-1'>{batchSetCode(item)}</span>)}
            </button>

            <Modal className="fade" centered={true} show={status} onHide={toggleModal}>
               <span>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Batch Time </h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row className='px-2 text-center mt-2'>
                    {batch?.map((item,idx)=>(
                        <Col xs={12} key={idx}>
                            <h4 className='text-center'>{batchSetCode(item)} ({formatDate(item.announce_date)})</h4>
                            <Card>
                            <CardHeader className="d-flex align-items-center justify-content-between">
                                <h5 className="card-title mb-0">Morning</h5>
                            </CardHeader>
                            <CardBody className="">
                                <ul className="verti-timeline" style={{borderLeft:'3px dashed #eff2f7',margin:'0 10px'}}>
                                            <li className=''>
                                                <div className=" d-flex mt-3">
                                                    <i className='bx bx-right-arrow-circle  font-size-20 pt-1 bx-fade-right text-dangertext-info' />
                                                    <div className="ms-3 ">
                                                        <h5 className="font-size-10">  
                                                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                                        </h5>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div>{timeFormat(item.start_time)} to {timeFormat(item.end_time)}</div>
                                                    </div>
                                                </div>
                                            </li>
                                </ul>
                    
                            </CardBody>
                        </Card>
                            <Card>
                            <CardHeader className="d-flex align-items-center justify-content-between">
                                <h5 className="card-title mb-0">After Noon</h5>
                            </CardHeader>
                            <CardBody className="">
                                <ul className="verti-timeline" style={{borderLeft:'3px dashed #eff2f7',margin:'0 10px'}}>
                                            <li className=''>
                                                <div className=" d-flex mt-3">
                                                    <i className='bx bx-right-arrow-circle  font-size-20 pt-1 text-dangertext-info' />
                                                    <div className="ms-3 ">
                                                        <h5 className="font-size-10">  
                                                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                                        </h5>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div>-</div>
                                                    </div>
                                                </div>
                                            </li>
                                </ul>
                    
                            </CardBody>
                        </Card>
                            <Card>
                            <CardHeader className="d-flex align-items-center justify-content-between">
                                <h5 className="card-title mb-0">Evening</h5>
                            </CardHeader>
                            <CardBody className="">
                                <ul className="verti-timeline" style={{borderLeft:'3px dashed #eff2f7',margin:'0 10px'}}>
                                            <li className=''>
                                                <div className=" d-flex mt-3">
                                                    <i className='bx bx-right-arrow-circle  font-size-20 pt-1  text-dangertext-info' />
                                                    <div className="ms-3 ">
                                                        <h5 className="font-size-10">  
                                                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                                        </h5>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div>-</div>
                                                    </div>
                                                </div>
                                            </li>
                                </ul>
                    
                            </CardBody>
                        </Card>
                        </Col>
                    ))}
                    </Row>
                </Modal.Body>
                </span> 
            </Modal>
        </>
    )
}
