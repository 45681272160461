import React from "react";
import LayoutHeader from "./LayoutHeader";
import LayoutSidebar from "./LayoutSidebar";
import LayoutFooter from "./LayoutFooter";
import { Container } from "react-bootstrap";
import SimpleBar from "simplebar-react";

const Layout = (prop) => {
  const handleClickOnOverlay = () =>{
    document.getElementsByTagName('body')[0].classList.remove('vertical-sidebar-enable');
  }
  return (
    <>
      <div id="layout-wrapper" >
        <LayoutHeader />
        <LayoutSidebar />
        <div onClick={handleClickOnOverlay} className="vertical-overlay"></div>
        <div className="main-content">
          <SimpleBar id="scrollbar" className='custom-scrollbar' style={{ maxHeight: "calc(110vh - 120px)" }} >
          <div className="page-content">
            <Container fluid >{prop.children}</Container>
          </div>
            </SimpleBar>
        </div>
          <LayoutFooter />
      </div>
    </>
  );
};

export default Layout;
