import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { enquiry, course, ASSET_URL } from '../../../helper/api_url'
import Camera from '../../../components/common/Camera';
import SimpleBar from 'simplebar-react';
import { useModalHandler, useWebCam } from '../../../helper/custom_hook';
import ModalProfileImage from '../../../components/common/ModalProfileImage';
import CustomSelect from '../../../components/CustomSelect';
import ImageViewer from '../../../components/ImageViewer';

export function UpateEnquiryModal({ data, apiHandler }) {

    const { genders, toggleModal, status, userProfile, setUserProfile } = useModalHandler();
    const { webcam, toggleWebcam, setCapturedImageToInput } = useWebCam(setUserProfile);
    const { handleUpdate } = apiHandler;
    const [coursesData, setCoursesData] = useState([]);
    const onSave = f => { setCapturedImageToInput(f, document.getElementById('avatarInput')) }

    useEffect(() => {
        if (status) {
            const viewImage = ASSET_URL + data.avatar;
            setUserProfile(viewImage);
            course.list()
                .then(res => setCoursesData([...res.data.course.map(course => ({ value: course.id, label: course.type }))]))
                .catch(err => console.log(err.response ? err.response.data.message : err.message))
        }
    }, [status, setCoursesData, data.avatar, setUserProfile]);


    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-success me-1'>
                <i className="ri-pencil-fill" />
            </button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Update Enquiry</h5></Modal.Title>
                </Modal.Header>
                <form onSubmit={e => handleUpdate(e, enquiry.update, toggleModal)}>
                    <SimpleBar className='custom-scrollbar' id="scrollbar" style={{ maxHeight: "calc(90vh - 100px)" }} >
                        <Modal.Body>
                            <div className='position-relative'>
                                {webcam ? (
                                    <div className='position-absolute top-0 bottom-0 d-flex justify-content-center align-items-center start-0 end-0 modal-content border-0' style={{ zIndex: 9 }}>
                                        <Camera onSave={f => { onSave(f); toggleWebcam() }} onClose={() => toggleWebcam()} />
                                    </div>) : null}
                                <div className="row g-3">
                                    {userProfile && 
                                    <ImageViewer image={userProfile}>
                                        <ModalProfileImage viewimage={userProfile} />
                                    </ImageViewer>
                                    }
                                    <input type="hidden" name="enquiry_id" value={data.id} />
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="enquiryName" className="form-label">Name</label>
                                            <input type="text" className="form-control" id='enquiryName' name="name" defaultValue={data.name} placeholder="Enter Full Name" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="enquiryFatherName" className="form-label">Fathsr's Name</label>
                                            <input type="text" className="form-control" id='enquiryFatherName' defaultValue={data.father_name} name="father_name" placeholder="Enter Father's Name" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="genderInput" className="form-label">Gender</label>
                                        <CustomSelect options={genders} defaultValue={{ value: data.gender, label: data.gender }} isSearchable name='gender' />
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="qualification" className="form-label">Qualification</label>
                                            <input type="text" className="form-control" id='qualification' defaultValue={data.qualification} name="qualification" placeholder="Enter Your Qualification" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="phoneNumber" className="form-label">Phone</label>
                                            <input type="tel" className="form-control" name='phone' defaultValue={data.phone} id="phoneNumber" placeholder="Enter Phone number" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="whatsappNumber" className="form-label">Whatsapp</label>
                                            <input type="tel" className="form-control" name='whatsapp' defaultValue={data.whatsapp} id="whatsappNumber" placeholder="Enter Whatsapp number" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="address1" className="form-label">Address 1</label>
                                            <input type="text" className="form-control" id='address1' name="address_1" defaultValue={data.address_1} placeholder="Enter Your Address" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="state" className="form-label">State</label>
                                            <input type="text" className="form-control" id='state' name="state" defaultValue={data.state} placeholder="Enter Your state" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="district" className="form-label">District</label>
                                            <input type="text" className="form-control" id='district' name="district" defaultValue={data.district} placeholder="Enter district" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="pin" className="form-label">Pin code</label>
                                            <input type="number" className="form-control" id='pin' name="pin" defaultValue={data.pin} placeholder="Enter pin code" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="course" className="form-label">Select a Course</label>
                                            <CustomSelect name="course_id" isSearchable id='course' defaultValue={{ value: data.course.id, label: data.course.type }} placeholder="Select Course" options={coursesData} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="preffered_batch" className="form-label">Preffered Batch</label>
                                            <CustomSelect name="preffered_batch" defaultValue={{ value: data.preffered_batch, label: data.preffered_batch }} id="preffered_batch" options={[{ value: 'morning', label: 'Morning' }, { value: 'evening', label: 'Evening' }, { value: 'afternoon', label: 'Afternoon' },]} />
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <label htmlFor="avatarInput" className="form-label">Profile Image</label>
                                        <div className="input-group mb-3">
                                            <input type="file" name="avatar" id="avatarInput" onChange={(e) => onSave(e.target.files[0])} className='form-control' />
                                            <button onClick={toggleWebcam} type='button' className='btn btn-soft-dark input-group-text'><i className='bx bx-camera'></i></button>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="tryal_date" className="form-label">Trail start Date</label>
                                            <input type="date" className="form-control" id='tryal_date' name="tryal_date" defaultValue={data.tryal_date} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="tryal_period" className="form-label">Trail Period</label>
                                            <input type="number" className="form-control" id='tryal_period' defaultValue={data.tryal_period} name="tryal_period" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="joining_date" className="form-label">When to Join</label>
                                            <input type="date" className="form-control" id='joining_date' name="joining_date" defaultValue={data.joining_date} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </SimpleBar>
                    <Modal.Footer>
                        <div className="col-lg-12">
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                <button type="submit" className="btn btn-primary">Update</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}