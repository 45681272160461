import React, { useEffect, useMemo, useState } from 'react'
import BreadCrumb from '../../../components/common/BreadCrumb'
import { TableResponsive } from '../../../components/common/TableResponsive';
import { Button, Card, CardBody, CardHeader, CardImg, Col, Row } from 'react-bootstrap';
import { ASSET_URL, exam } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { CreateAssignmentModal } from './CreateAssignmentModal';
import ImageViewer from '../../../components/ImageViewer';

export const AssignmetList = () => {
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false)
    useEffect(() => {
        setTableLoading(true);
        exam.read_question_or_assignment("assignment")
            .then(res => setTableData(res.data.question))
            .catch((err) => swal.error(err.response ? err.response.data.message : err.message))
            .finally(() => {setTableLoading(false);});
    }, [])
    const handleAssignmentDelete = (aid) => {
        dispatch(setPreloader({ loader: true, message: 'please wait' }));
        exam.delete_question_or_assignment(aid)
            .then((res) => {
                setTableData(state => state.filter(item => item.id !== res.data.question.id));
                swal.success(res.message);
            })
            .catch((err) => swal.error(err.response ? err.response.data.message : err.message))
            .finally(() => {
                dispatch(setPreloader({ loader: false, message: "" }));
            });
    }
    const columns = useMemo(() => [
        { Header: "SrNo", accessor: "id", Cell: cell => { return (<p className="">{cell.row.index + 1}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: 'Subject', accessor: 'subject', DataClass: 'text-center', HeaderClass: 'text-center' },
        { Header: 'Assignment Name', accessor: 'files[0].name', DataClass: 'text-center', HeaderClass: 'text-center' },
        {
            Header: "Question",
            HeaderClass: 'text-center',
            DataClass: 'text-center bg-soft-warning',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        <p>{row.instruction}</p>
                        {row.image_video ? <ImageViewer image={ASSET_URL + row.image_video}>
                        <div style={{ width: '400px', height: '200px', overflow: 'hidden' }}>
                            <CardImg src={ASSET_URL + row.image_video} style={{ aspectRatio: '16/7', objectFit: 'cover', height:'auto'}} alt="issue screenshot" />
                            </div>
                        </ImageViewer> : ''}
                    </>)
            }
        },
        {
            Header: "File",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                const fileUrl = `${ASSET_URL}${row.files[0]?.path}`;
                return (
                    <>
                         <Button
                            href={fileUrl}
                            download
                            className="btn btn-sm btn-soft-primary me-1"
                        >
                            <i className="ri-download-2-line" />
                        </Button>

                    </>)
            }
        },
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <div className="">
                        {/* <UpdateQuestionModal setTableData={setTableData} row={row}  /> */}
                        <Button onClick={() => handleAssignmentDelete(row.id)} className="btn btn-sm btn-soft-danger me-1" ><i className="ri-delete-bin-fill" /></Button>
                    </div>
                )
            },
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                // const index=1
                const fileUrl = `${ASSET_URL}${row.files[0]?.path}`;
                return (
                    <div className="d-flex">
                        <div className="flex-grow-1" data-id="1">
                                <p>{parseInt(row.index) + 1}. {row.instruction}</p>
                                {row.image_video ? <ImageViewer image={ASSET_URL + row.image_video}>
                        <div style={{ width: '400px', height: '200px', overflow: 'hidden' }}>
                            <CardImg src={ASSET_URL + row.image_video} style={{ aspectRatio: '16/7', objectFit: 'cover', height:'auto'}} alt="issue screenshot" />
                            </div>
                        </ImageViewer> : ''}
                        
                        </div>
                        <div className="flex-shrink-0">
                            <div>
                            <Button
                            href={fileUrl}
                            download
                            className="btn btn-sm btn-soft-primary me-1"
                        >
                            <i className="ri-download-2-line" />
                        </Button>
                                <Button onClick={() => handleAssignmentDelete(row.id)} className="btn btn-sm btn-soft-danger me-1">
                                    <i className="ri-delete-bin-fill" />
                                </Button>
                            </div>
                        </div>
                    </div>

                )
            }
        }

    ]);
    return (
        <>
            <BreadCrumb title="Assignment Bank" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">All Assignment List</h5>
                            <CreateAssignmentModal setTableData={setTableData} />
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={tableLoading} columns={columns} data={tableData} noRecord="No Record Assignment List" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
