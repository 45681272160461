import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import img from '../../helper/image_helper';
export const AboutUs = () => {
    return (
        <>
            <section className="section bg-light" id="about">
                <div className="bg-overlay bg-overlay-pattern"></div>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="text-center mb-5">
                                <h3 className="mb-3 fw-semibold">About Us</h3>
                                <p className="text-muted mb-4 ff-secondary">SK Group of institution was started on 29-July-2014 in the initial phase as. SK INSTITUTE OF COMPUTER SCIENCE AND TECHNOLOGY.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="row g-lg-5 g-4">
                        <Col lg={6} className='col-xs-12'>
                            <div className="d-flex align-items-center mb-2">
                                <div className="flex-shrink-0 me-1">
                                    <i className="ri-shield-keyhole-line fs-24 align-middle text-success me-1"></i>
                                </div>
                                <div className="flex-grow-1">
                                    <h5 className="mb-0 fw-semibold">Detais & About's</h5>
                                </div>
                            </div>
                            <div className="accordion custom-accordionwithicon custom-accordion-border accordion-border-box" id="privacy-accordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="privacy-headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#privacy-collapseOne" aria-expanded="false" aria-controls="privacy-collapseOne">
                                            Director’s Message
                                        </button>
                                    </h2>
                                    <div id="privacy-collapseOne" className="accordion-collapse collapse" aria-labelledby="privacy-headingOne" data-bs-parent="#privacy-accordion">
                                        <div className="accordion-body ff-secondary">
                                            Dear Students,<br />

                                            The objectives of the S K Group of Institutions family are clear and the intentions are strong. In the present time the education system is changing. In which more emphasis is being laid on technical education. It is our endeavor that we train every student in such a way that they can get a good job or also create their self-employment on the strength of their skill and hard work. At present, in the name of preparation for one day examination in coaching etc., high fees are being charged and taught in the old system. We are trying to prepare according to the syllabus of every subject with new technical mediums and shortcut techniques.
                                            <br />
                                            Technical and computer education instills confidence in the person. And inspires you to do something on your own.
                                            <br />
                                            SK Group of Institutions under SK Institute of Computer Science and Technology guarantees training and certified certification through expert trainers in computer education who can be employed in any part of the country in government or non-government jobs .
                                            <br />
                                            The team of enlightened mentors at SK Institute of One Day Exams fully assists the students in cracking the one day examinations.
                                            <br />
                                            “All my students are family member of this institute. Whom selection yet proved own success.”
                                            <br />
                                            Our mantra is based on the principle of “Sankalpit Rahe Aap Samarpit Rahe Ham”. Our dedication to your resolve is our core.
                                            <br />
                                            Mr. Girijesh Kumar Kesarwani<br />
                                            Director of SK Group of Institutions
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="aboutUs-headingTwo">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#aboutUs-collapseTwo" aria-expanded="true" aria-controls="aboutUs-collapseTwo">
                                            About Institute
                                        </button>
                                    </h2>
                                    <div id="aboutUs-collapseTwo" className="accordion-collapse collapse" aria-labelledby="aboutUs-headingTwo" data-bs-parent="#aboutUs-accordion">
                                        <div className="accordion-body ff-secondary">
                                            SK Group of institution was started on 29-july-2014 in the initial phase as. SK INSTITUTE OF COMPUTER SCIENCE AND TECHNOLOGY. The Institute is run by the S.K memorial social Development society, which is regarded by the society and public trust Act 21, 1860 (Reg.No: 1534/AL-35329).
                                            <br />
                                            The organization was officiated to Rajiv Gandhi Computer Literacy (Shaksharta) mission. Kota Rajasthan in the year 2016 (Code: up-333) in which training is being given under computer courses DOAP, ADCA, DCA, DCAA, TALLY Etc. Conducted by RGCSM.
                                            <br />
                                            In the year of 2016 Major organization conduct NIELIT by (National Institute of electronics & Information Technology) conducted by Indian Government. NIELIT is erstwhile (DOEACC). It affiliated by, (Code:8803771) in which conducted course is CCC and ‘O’ Level.
                                            <br />
                                            In the year 2016 under Pradhan Mantri Kaushal Vikas Yojana training of students was done in MPKVY L.O and in the year 2018 under PMKVY 2.0, 320 Students were also trained Under job role of retail and telecom sector and date 26-27 may Student’s campus selection was done by organizing the kaushal mela. In the year 2019 January, training of RPL was done in the Kumbh special program of PMKVY 2.0. In the year 2020 S.K INSTITUTE OF ONE DAY EXAMS started for the preparation of one day exams of students in Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="privacy-headingTwo">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#privacy-collapseTwo" aria-expanded="true" aria-controls="privacy-collapseTwo">
                                            Vision
                                        </button>
                                    </h2>
                                    <div id="privacy-collapseTwo" className="accordion-collapse collapse" aria-labelledby="privacy-headingTwo" data-bs-parent="#privacy-accordion">
                                        <div className="accordion-body ff-secondary">
                                            To provide a good education to every student of rural are by connecting them with the institute so that they can make their future better and by removing the discrepancies in education and imparting now technical education.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="privacy-headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#privacy-collapseThree" aria-expanded="false" aria-controls="privacy-collapseThree">
                                            Mission
                                        </button>
                                    </h2>
                                    <div id="privacy-collapseThree" className="accordion-collapse collapse" aria-labelledby="privacy-headingThree" data-bs-parent="#privacy-accordion">
                                        <div className="accordion-body ff-secondary">
                                            At present there are many such institute where many courses are Providing educational very high fees. Our mission is to provide good education in low fees, so that all those who are deprived of a good education due to high fees.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="privacy-headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#privacy-collapseFour" aria-expanded="false" aria-controls="privacy-collapseFour">
                                            Value
                                        </button>
                                    </h2>
                                    <div id="privacy-collapseFour" className="accordion-collapse collapse" aria-labelledby="privacy-headingFour" data-bs-parent="#privacy-accordion">
                                        <div className="accordion-body ff-secondary">
                                            The main function of the society is to provide higher technical education in nominal charges for every group of society of Urban & Rural areas all over India and get success in computer revolution which is the main dream of Indian Govt.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" className='col-xs-12'>
                            <div>
                                <img
                                    src={img.aboutLogo}
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
