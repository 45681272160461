import React, { useEffect, useMemo, useState } from 'react'
import { CardImg, Image, Modal, Row, Card, Col } from 'react-bootstrap'
import CustomSelect from '../../../../components/CustomSelect'
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../../features/Ui/uiSlice';
import { toast } from 'react-toastify';
import { swal } from '../../../../helper/swal';
import { ASSET_URL, student, userQualification } from '../../../../helper/api_url';
import { useModalHandler } from '../../../../helper/custom_hook';
import { formatDate } from '../../../../helper/formatDate';
import ImageViewer from '../../../../components/ImageViewer';
import { TableResponsive } from '../../../../components/common/TableResponsive';

export const QualificationDetail = ({ studentId, userId, setActiveStep, editAdmission }) => {
  const { status, toggleModal } = useModalHandler();
  const [documents, setDocument] = useState([]);
  const dispatch = useDispatch();
  const [editDocument, setEditDocument] = useState();
  const handleSubmit = e => {
    e.preventDefault();
    dispatch(setPreloader({ loader: true, message: 'please wait...' }))
    const formData = new FormData(e.target);
    userQualification.add(formData).then(res => {
      toast.success(res.message);
      document.getElementById('activeStep5').onclick = () => setActiveStep(5);
      setDocument(state => [...res.data.qualifications, ...state]);
      toggleModal();
      e.target.reset();
    })
      .catch(err => swal.error(err.response ? err.response.data.message : err.message))
      .finally(() => dispatch(setPreloader({ loader: false, message: '' })))
  }




  const documentListColumns = useMemo(() => [
    {
      Header: 'Document',
      Cell: cell => {
        const row = cell.row.original;
        const document_split = row.document?.path.split('.');
        const extension = document_split?.reverse()[0];
        const imageExtensions = ['png', 'jpg', 'jpeg'];
        const imageUrl = ASSET_URL + row.document?.path;
        return (
          <div className='d-flex align-items-center justify-content-start'>
            <ImageViewer image={imageExtensions.includes(extension) ? imageUrl : extension}>
              <img
                className="me-2 rounded-circle header-profile-user"
                style={{ objectFit: 'cover' }}
                src={imageExtensions.includes(extension) ? imageUrl : extension}
                alt="Document Image" />
            </ImageViewer>
            <span className='ms-2'>{row.document?.name}</span>
          </div>
        )
      }
    },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Board / University', accessor: 'board_university' },
    { Header: 'Marks %', accessor: 'marks' },
    { Header: 'Passing Year', accessor: 'year' },
    {
      Header: 'List',
      HeaderClass: 'd-none',
      DataClass: 'd-none',
      list: (row) => {
        const document_split = row.document?.path.split('.');
        const extension = document_split?.reverse()[0];
        const imageExtensions = ['png', 'jpg', 'jpeg'];
        const imageUrl = ASSET_URL + row.document?.path;
        return (
          <div className="d-flex">
            <div className="flex-grow-1" data-id="1">
              <div className='d-flex align-items-center justify-content-start'>
                <ImageViewer image={imageExtensions.includes(extension) ? imageUrl : extension}>
                  <img
                    className="me-2 rounded-circle header-profile-user"
                    style={{ objectFit: 'cover' }}
                    src={imageExtensions.includes(extension) ? imageUrl : extension}
                    alt="Document Image" />
                </ImageViewer>
              </div>
              <h5 className="fs-13 mb-1">
                <a href="#">{row.name}</a>
              </h5>
              <p className="text-muted mb-0">{row.document?.name} </p>
            </div>
            <div className="flex-shrink-0">
              {row.marks} %
            </div>
          </div>
        )
      }
    }
  ]);
  const year=() => {
    const start = 1991;
    const currentYear = new Date().getFullYear();
    const yearArray = [];
    for (let year = 1991; year <= currentYear; year++) {
      yearArray.push({ label: year, value: year });
    }
    return yearArray
  }
  return (
    <>

      <div className="mb-4"><h5 className="mb-1 text-center text-primary">Qualification</h5></div>
      <Card>
        <Card.Body>
          <div className="d-flex align-items-center mb-4">
            <h5 className="card-title flex-grow-1 mb-0">Documents</h5>
            <div className="flex-shrink-0">
              {editAdmission ? '' : <button type="button" onClick={() => toggleModal()} className='btn btn-soft-success add-btn waves-effect mx-2'><i className="ri-add-line align-bottom me-1"></i><span>Add</span></button>}
            </div>
          </div>
          <Row>
            <Col lg={12}>
              {editAdmission ? '' :
                <TableResponsive
                  columns={documentListColumns}
                  data={documents}
                  isShowingPageLength={false}
                  showFilter={false}
                  showCustomOptionPage={false}
                  isPagination={false}
                  noRecord='No Documents Found' />}
              <div className="">

                {
                  editAdmission ?
                    <table className="table table-borderless align-middle mb-0">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">File Name</th>
                          <th scope="col">Type</th>
                          <th scope="col">Size</th>
                          <th scope="col">Upload Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          editAdmission?.documents?.map((item, idx) => (
                            <tr key={idx}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="avatar-sm">
                                    <div className="avatar-title bg-soft-primary text-primary rounded fs-20">
                                      <ImageViewer image={ASSET_URL + item.path} thumbnail>
                                        <CardImg src={ASSET_URL + item.path} style={{ aspectRatio: '16/7', objectFit: 'cover', height: 'auto' }} alt="issue screenshot" />
                                      </ImageViewer>
                                    </div>
                                  </div>
                                  <div className="ms-3 flex-grow-1">
                                    <h6 className="fs-15 mb-0"><a href="javascript:void(0)">{item.name}</a>
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td>PNG</td>
                              <td>2 MB</td>
                              <td>{formatDate(item.created_at)}</td>
                              <td>{item.approved === 1 ? <span className='badge badge-soft-info px-2 ms-1 mx-2'>Approved</span> : <span className='badge badge-soft-danger px-2 ms-1 mx-2'>Reject</span>}</td>
                              <td>
                                <div className="dropdown">
                                  <a href="javascript:void(0);" className="btn btn-light btn-icon" id="dropdownMenuLink15" data-bs-toggle="dropdown" aria-expanded="true">
                                    <i className="ri-equalizer-fill"></i>
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink15">
                                    <li><a className="dropdown-item" href="javascript:void(0);" onClick={() => { toggleModal(); setEditDocument(item) }}   ><i className="ri-pencil-fill me-2 align-middle text-muted"></i>Edit</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-download-2-fill me-2 align-middle text-muted"></i>Download</a></li>
                                    <li className="dropdown-divider"></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>Delete</a></li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                    : ''
                }

              </div>
            </Col>
          </Row>
        </Card.Body>
        {
          editAdmission ? '' :

            <Card.Footer className='text-end'>
              <button
                type="button"
                className="btn btn-primary btn-label right ms-auto nexttab nexttab"
                onClick={() => { toast.success("Qualification Saved"); setActiveStep(5) }}>
                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Save & Next
              </button>
            </Card.Footer>
        }
      </Card>

      <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal} >
        <Modal.Header closeButton>Add Qualification</Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Row>

              <div className="col-6 mb-2">
                <input type="hidden" name="user_id" id='user_id' defaultValue={studentId} />
                <div>
                  <label htmlFor="name" className="form-label">Highest Qualification</label>
                  <CustomSelect name="name" id="name" options={[
                    { value: 'matriculation', label: 'matriculation' },
                    { value: 'intermediate', label: 'intermediate' },
                    { value: 'graduation', label: 'graduation' },
                    { value: 'masters', label: 'masters' },
                    { value: 'none', label: 'none' },
                  ]} />
                </div>
              </div>
              <div className="col-6 mb-2">
                <div>
                  <label htmlFor="board_university" className="form-label">Board Name</label>
                  <input type='text' name='board_university' id='board_university' defaultValue={editDocument?.board_university} className='form-control' />
                  {/* <CustomSelect name="board_university" id="board_university"  defaultValue={editDocument?.board_university} options={[
                    { value: 'up_Board', label: 'UP Board' },
                    { value: 'CBSC_Board', label: 'CBSC Board' },
                    { value: 'graduation', label: 'graduation' },
                    { value: 'masters', label: 'masters' },
                    { value: 'other', label: 'other' },
                  ]} /> */}
                </div>
              </div>
              <div className="col-6 mb-2">
                <div>
                  <label htmlFor="year" className="form-label">Passing Year</label>
                  {/* <input type='number' name='year' id='year' className='form-control' /> */}
                  <CustomSelect isSearchable options={year()} name='year' id='year' />
                </div>
              </div>
              <div className="col-6 mb-2">
                <div>
                  <label htmlFor="marks" className="form-label">Marks in %</label>
                  <input type='number' name='marks' id='marks' className='form-control' />
                </div>
              </div>
              <div className="col-6 mb-2">
                <div>
                  <label htmlFor="doc_name" className="form-label">Document Name</label>
                  <input type='text' name='doc_name' id='doc_name' defaultValue={editDocument?.name} className='form-control' />
                </div>
              </div>
              <div className="col-6 mb-2">
                <div>
                  <label htmlFor="doc" className="form-label">Upload</label>
                  <input type='file' name='doc' id='doc' className='form-control' />
                </div>
              </div>
              <div className="d-flex align-items-start gap-3 mt-4">
                <button type="submit" data-nexttab="pills-other-info-tab" className="btn btn-primary btn-label right ms-auto nexttab nexttab" ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Save</button>
              </div>
            </Row>
          </form>
        </Modal.Body>
      </Modal>

    </>
  )
}
