import React, { useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { course,} from '../../../helper/api_url'
import { Tooltip } from 'antd';
import { TextEditor } from '../../../components/common/TextEditor';
import {useModalHandler} from '../../../helper/custom_hook';

export function UpateCourseModal({ data, apiHandler }) {
    const {toggleModal,status} = useModalHandler();
    const textData = useRef();
    const eligibility=[{value:'matriculation',label:'matriculation'},{value:'intermediate',label:'intermediate'},{value:'graduation',label:'graduation'},{value:'masters',label:'masters'},{value:'none',label:'none'},]
    const courseType=[{value:'computer course',label:'coumputer course'},{value:'one Day Exam',label:'one Day Exam'},{value:'other',label:'other'}]
    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-success me-1'><i className="ri-pencil-fill"></i>  </button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Update Course</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={e => apiHandler.handleUpdate(e,course.update,toggleModal)}>
                        <div className="row g-3">
                            <input type="hidden" name="course_id" defaultValue={data.id} />
                            <div className="col-12">
                                <div>
                                    <label htmlFor="courseName" className="form-label">Name</label>
                                    <input type="text" className="form-control" id='courseName' name="name" defaultValue={data.name} placeholder="Enter Course Name" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="type" className="form-label">Type</label>
                                    {/* <Tooltip  placement="top" title="Short Name" ><i className='text-danger ps-1 align-center bx bx-help-circle' /></Tooltip> */}
                                    <select className='form-control' id='type'  name='type' defaultValue={data.type}>
                                        {courseType.map((p, id) => (
                                            <option key={id} value={p.value}>{p.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="code" className="form-label">Code</label>
                                    {/* <Tooltip  placement="top" title="Unique Code" ><i className='text-danger ps-1 align-center bx bx-help-circle' /></Tooltip> */}
                                    <input type="text" className="form-control" id='code' name='code'  defaultValue={data.code} placeholder="Enter Course Code"  />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="semesters" className="form-label">Semester</label>
                                    <input type="number" className="form-control" id='semesters' name="semesters" defaultValue={data.semesters} placeholder='Enter Course Semester' />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="sem_duration" className="form-label">Semester Duration</label>
                                    <input type="number" className="form-control" id='sem_duration' name="sem_duration" defaultValue={data.sem_duration} placeholder='Enter Course Duration' />
                                </div>
                            </div>
                            <div className="col-12">
                                <div>
                                    <label htmlFor="eligibility" className="form-label">Eligibility</label>
                                    <Tooltip  placement="top" title="Matriculation | Intermediate | Graduation | Masters" ><i className='text-danger ps-1 align-center bx bx-help-circle' /></Tooltip>
                                    <select className='form-control' defaultValue={data.min_eligibility} name='min_eligibility'>
                                        {eligibility.map((p, id) => (
                                            <option key={id} value={p.value}>{p.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <div>
                                    <label htmlFor="desc" className="form-label">Description</label>
                                    <Tooltip  placement="top" title="Hint: (Related Course Chapter)" ><i className='text-danger ps-1 align-center bx bx-help-circle' /></Tooltip>
                                    <TextEditor id="description" defaultValue={data.description} textData={textData} />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                    <button type="submit" className="btn btn-primary">update</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}