import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Badge, Card, CardBody, CardFooter } from 'react-bootstrap'
import '../../assets/css/custom_style.css'
import img from '../../helper/image_helper'

const courseInfo = [
    { course: 'ADCA', CourseName: 'Advance Diploma In Computer Application', img: img.courseLogo1, duration: "1 Year", session: 'offline/online' },
    { course: 'DOAP', CourseName: 'Diploma In Office Automation & Publishing', img: img.courseLogo2, duration: "1 Year", session: 'offline/online' },
    { course: 'CDEO', CourseName: 'Certificate In Data Entry Operator', img: img.courseLogo5, duration: "6 Month", session: 'offline/online' },
    { course: 'CAC', CourseName: 'Computer Appreciation Course', img: img.courseLogo1, duration: "6 Month", session: 'offline/online' },
    { course: 'CCC', CourseName: 'Course on Computer Concepts', img: img.courseLogo3, duration: "6 Month", session: 'offline/online' },
    { course: 'O-Level', CourseName: '"O" Level (NIELIT Courses)', img: img.courseLogo4, duration: "1 Year", session: 'offline/online' },
    { course: 'PGDCA', CourseName: 'Post Graduate Diploma in Computer Application', img: img.courseLogo5, duration: "1 Year", session: 'offline/online' },
    { course: 'BCA', CourseName: 'Bachelor of Computer Application ', img: img.courseLogo3, duration: "3 Year's", session: 'offline/online' }

]
export const CourseDetails = () => {
    return (
        <>
            <section className="section" id="course" style={{backgroundImage: `url(${img.coursebg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center',}}>
                <Container>
                    <Row className="row justify-content-center">
                        <Col lg={8}>
                            <div className="text-center mb-5">
                                <h1 className="mb-3 ff-secondary fw-semibold lh-base">Our Course</h1>
                                <p className="text-muted">To enhance computer education, it's important to establish uniform guidelines for communication, ensuring consistent language usage and using terms that are universally understood.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="g-2">
                            {
                                courseInfo.map((item, id) => (
                                    <Col key={id} lg={3} xs={12}  className=" px-2">
                                        <div className="h-100">
                                        <Card>
                                            <CardBody className="">
                                                <Badge className="bg-primary">{item.course}</Badge>
                                                <img src={item.img} width="" height="" loading="lazy" alt="Competitive Strategy law for all students" className="img-cover" />
                                                <div className="d-flex mb-1">
                                                    <p className="mb-0 flex-grow-1 text-muted"><i className="bx bx-book">Classes:</i> {item.session}</p>
                                                </div>
                                                <div className="text-center">
                                                    <Link to="/" className="text-body">
                                                        <b className="mt-1 mb-1 ">{item.CourseName}</b>
                                                    </Link>
                                                </div>
                                            </CardBody>
                                            <CardFooter>
                                                <div className="d-flex">
                                                    <p className="mb-0 flex-grow-1 text-muted"><b><i className='bx bx-timer' /></b> {item.duration}</p>
                                                    <p className="mb-0 text-muted"><i className='bx bx-user-plus' /><b>47</b> Students</p>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </div>
                                    </Col>
                                ))
                            }
                    </Row>

                </Container>

            </section>
        </>
    )
}
