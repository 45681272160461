import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { batch,course} from '../../../helper/api_url'
import { Tooltip } from 'antd';
import {usePageInitialtor, useModalHandler } from '../../../helper/custom_hook';
import {timeFormat } from '../../../helper/formatDate';
import CustomSelect from '../../../components/CustomSelect';
export function NewBatchModal({ apiHandler }) {
    const {toggleModal,status} = useModalHandler();
    const {tableData} = usePageInitialtor(course);
    const handlegetId = (e) => {
        const course_id=e.value;
        document.getElementById('course_id').value = course_id;
      };
    ;


    return (
        <>
            <button onClick={toggleModal} className='btn btn-soft-success add-btn waves-effect'>
                <i className="ri-add-line align-bottom me-1"></i>
                <span>Add Batch</span>
            </button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>New Batch</h5></Modal.Title>
                </Modal.Header>
                    <form onSubmit={e => apiHandler.handleSubmit(e,batch.add,toggleModal)}>
                <Modal.Body>
                        <div className="row g-3">
                            {/* <div className="col-12">
                                <div>
                                    <label htmlFor="course_name" className="form-label">Select Course </label>
                                    <CustomSelect name="course_data_id" isSearchable options={
                                        tableData.length ? tableData.map(course=>({label:course.name,value:course.id }) ) : []
                                    } onChange={handlegetId} />
                                </div>
                            </div> */}
                            <div className="col-12">
                                <div>
                                    {/* <label htmlFor="batchName" className="form-label">Batch Name</label> */}
                                    <input type="hidden" className="form-control" id="batchName" name="name" value={Date.now()}placeholder="" />
                                </div>
                            </div>
                            {/* <div className="col-12">
                                <div>
                                    <label htmlFor="semester" className="form-label">Semester</label>
                                    <input type="number" className="form-control" id="semester" name="semester" placeholder="" />
                                </div>
                            </div> */}
                            <div className="col-12">
                                <div>
                                    <label htmlFor="announce_date" className="form-label">Announce Date</label>
                                    <input type="date" className="form-control" id="announce_date" name="announce_date"   placeholder="" />
                                </div>
                            </div>
                            
                            <div className="col-6">
                                <div>
                                    <label htmlFor="start_time" className="form-label">Start Time</label>
                                    {/* <input type="hidden" name="start_time" id='stime' /> */}
                                    <input type="time" className="form-control" id='start_time' name="start_time"   placeholder=""  />
                                </div>
                            </div>

                            <div className="col-6">
                                <div>
                                    <label htmlFor="end_time" className="form-label">End Time</label>
                                    {/* <input type="hidden" name="end_time" id='etime' /> */}
                                    <input type="time" className="form-control" id='end_time' name="end_time"  placeholder='' />
                                </div>
                            </div>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                            <div className="col-lg-12">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </div>
                            </Modal.Footer>
                    </form>
            </Modal>
        </>
    )
}