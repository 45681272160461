import React, { useEffect, useMemo } from 'react'
import BreadCrumb from "../../../components/common/BreadCrumb";
import { Card, CardBody, CardHeader, Col, Row, Button } from "react-bootstrap";
import { TableResponsive } from "../../../components/common/TableResponsive";
import { NewFeeModal } from './NewFeeModal';
import { UpdateFeeModal } from './UpdateFeeModal';
import { ViewFeesModal } from './ViewFeesModal';
import { fee } from '../../../helper/api_url';
import { usePageInitialtor } from '../../../helper/custom_hook';
function Fees() {
    const {tableData,apiHandler} = usePageInitialtor(fee);
    const handleFeeDelete = (feeId,name) =>apiHandler.handleDelete({name:name,id:feeId});
    const columns = useMemo(()=>[
        {Header: "Name", accessor: "name"},
        {Header: "Amount", accessor: "amount"},
        {Header: "Type", accessor: "type"},
        {Header: "Description", accessor: "description"},
        {
            Header: "Action",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell: (cell) => {
              return ( 
                <div className=""> 
                    <ViewFeesModal view={cell.row.original} />
                    <UpdateFeeModal data={cell.row.original} apiHandler={apiHandler}  />
                    <Button onClick={()=>{handleFeeDelete(cell.row.original.id,cell.row.original.name)}} className="btn btn-sm btn-soft-danger me-1" >
                        <i className="ri-delete-bin-fill" />  
                    </Button>
                </div>
              )
            },
          },
          {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                return (
                <div className="d-flex">
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                        <ViewFeesModal view={row}><a href="#">{row.name}</a></ViewFeesModal>
                        </h5>
                        <p className="text-muted mb-0">Rs : {row.amount} | {row.type} </p>
                    </div>
                    <div className="flex-shrink-0">
                        <div>
                            <UpdateFeeModal data={row} apiHandler={apiHandler}  />
                            <button onClick={()=>{handleFeeDelete(row.id,row.name)}} className="btn btn-sm btn-soft-danger me-1" data-id="1"> <i className="ri-delete-bin-fill"></i> </button>
                        </div>
                    </div>
                </div>
                )
            }
          }
    ]);
    return (
        <>
        <BreadCrumb title="Fees" prevPage="Home" prevPath="/dashboard" />
        <Row>
            <Col xs={12}>
                <Card>
                    <CardHeader className="d-flex align-items-center justify-content-between">
                        <h5 className="card-title mb-0">Fee List</h5>
                        <NewFeeModal apiHandler={apiHandler} />
                    </CardHeader>
                    <CardBody className="">
                        <TableResponsive columns={columns} data={tableData} noRecord="No Record Fees List" />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
    )
}
export default Fees