import React, { useEffect, useMemo, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from "../../../components/common/BreadCrumb";
import { Card, CardBody, CardHeader, Col, Row, Button } from "react-bootstrap";
import { TableResponsive } from "../../../components/common/TableResponsive";
import { ASSET_URL, invoice } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { usePageInitialtor } from '../../../helper/custom_hook';

function InvoiceList() {
    const {tableData,setTableData,tableLoading,apiHandler} = usePageInitialtor(invoice);
    const {handleDelete} = apiHandler;
    const navigate = useNavigate();
    const handleViewInvoice = (e,id) => {
        navigate('/view-invoice', { state: { invoiceId:id } });
    }
    const columns = useMemo(()=>[
        {
            Header: "Payer",
            Cell:(cell)=> {
                const imageUrl =ASSET_URL + cell.row.original.payer?.avatar;
                return (<span> 
                            <img 
                                className="me-2 rounded-circle header-profile-user" 
                                style={{objectFit:'cover'}} 
                                src={imageUrl} alt="User Avatar"
                            />
                            {cell.row.original.user_name}
                        </span>)
            }
        },
        {
            Header: "Invoice",
            accessor: "name",
            HeaderClass:'',
            DataClass:'',
        },
        {
            Header: "Phone",
            accessor: "user_phone",
            HeaderClass:'',
            DataClass:'',
        },
        // {
        //     Header: "Total Amount",
        //     accessor: "total_amount",
        //     HeaderClass:'',
        //     DataClass:'',
        // },
        {
            Header: "Payment Mode",
            accessor: "payment_mode",
            HeaderClass:'',
            DataClass:'',
        },
        {
            Header: "Accountant",
            accessor: "staff_name",
            HeaderClass:'',
            DataClass:'',
        },
        {
            Header: "Action",
            Cell:cell=>{
                const row=cell.row.original;
                return (<div>
                        <button onClick={(e)=>handleViewInvoice(e,row.id)} className='btn btn-sm btn-soft-info me-1'> <i className="ri-eye-fill" /> </button>
                        <Button onClick={()=>handleDelete({name:row.payer_name,id:row.id})} className="btn btn-sm btn-soft-danger me-1" >
                            <i className="ri-delete-bin-fill" />  
                        </Button>
                    </div>)
            }
        },
        // {
        //     Header: "List",
        //     HeaderClass:'d-none',
        //     DataClass:'d-none',
        //     list:(row)=>{
        //         const imageUrl = ASSET_URL+row.payer.avatar;
        //         return (
        //         <div className="d-flex">
        //             <img className="me-2 rounded-circle header-profile-user" src={imageUrl} alt="User Avatar" />
        //             <div className="flex-grow-1" data-id="1">
        //                 <h5 className="fs-13 mb-1">
        //                     <a href="#" className="link text-dark"></a>
        //                     <a href="#">{row.payer_name}</a>
        //                 </h5>
        //                 <p className="text-muted mb-0">
        //                     <a href={`tel:${row.payer.phone}`}><i className='bx bx-phone text-success' />{row.phone}</a>
        //                     <span className='badge badge-soft-warning mx-2' >Paid Amount {row.total_amount}</span>
        //                 </p>
                        

        //             </div>
        //             <div className="flex-shrink-0">
        //                 <div>
        //                     <button onClick={(e)=>handleViewInvoice(e,row.id)} className='btn btn-sm btn-soft-info me-1'> <i className="ri-eye-fill" /> </button>
        //                     <Button onClick={()=>handleDelete({name:row.payer_name,id:row.id})} className="btn btn-sm btn-soft-danger me-1" >
        //                         <i className="ri-delete-bin-fill" />  
        //                     </Button>
        //                 </div>
        //             </div>
        //         </div>
        //         )
        //     }
        // },
    ]);
    return (
        <>
            <BreadCrumb title="Invoices" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Invoices</h5>
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={tableLoading} columns={columns} data={tableData} noRecord="No Record Found" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default InvoiceList