import React from "react";
import { Container, Row } from "react-bootstrap";

function LayoutFooter() {
  return (
    <footer className="footer">
      <Container fluid>
        <Row>
          <div className="col-sm-6">
            <script>{new Date().getFullYear()}</script>© SAJAG.
          </div>
          <div className="col-sm-6">
            <div className="text-sm-end d-none d-sm-block">
              Design & Develop by Lal Mishra | SHUBHAM
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default LayoutFooter;
