export const subject = [
    { label: 'Computer Fundamental', value: 'computer_fundamental' },
    { label: 'Operating System', value: 'operating_system' },
    { label: 'MS Word', value: 'ms_word' },
    { label: 'MS Excel', value: 'ms_excel' },
    { label: 'MS PowerPoint', value: 'ms_powerpoint' },
    { label: 'Introduction to Internet and WWW', value: 'introduction_to_internet_and_www' },
    { label: 'E-mail Social Networking', value: 'e-mail_social_networking' },
    { label: 'Cyber Security', value: 'cyber_security' },
    { label: 'Python', value: 'Python' },
    { label: 'HTML', value: 'HTML' },
    { label: 'CSS', value: 'CSS' },
    { label: 'JavaScript', value: 'JavaScript' },
];

export const allChapters = {
    computer_fundamental: [
        { label: "Computer and Latest IT gadgets", value: 'computer_and_lastest_it_gadgets' },
        { label: "Introduction of computer", value: 'introduction_of_computer' },
        { label: "Type of Computer", value: 'type_of_computer' },
        { label: "Characteristics of Computers", value: 'characteristics_of_computers' },

    ],
    operating_system: [
        { label: "Basic Opterating System", value: 'basic_operating_system' },
        { label: "DOS", value: 'DOS' },
    ],
    Python: [
        { label: "Python", value: 'Python' },
    ],
};

export const AssignmentSubject=[
    {label:'Computer Fundamental',value:'Computer Fundamental'},
    {label:'Operating System',value:'Operating System'},
    {label:'Ms Word',value:'Ms Word'},
    {label:'Ms Excel',value:'Ms Excel'},
    {label:'Ms PowerPoint',value:'Ms PowerPoint'},
    {label:'HTML',value:'HTML'},
    {label:'CSS',value:'CSS'},
    {label:'JavaScript',value:'JavaScript'},
    {label:'Python',value:'Python'},
    {label:'IOT',value:'IOT'},
]

export const AssignmentName=[
    {label:'Assignment-1',value:'Assignment-1'},
    {label:'Assignment-2',value:'Assignment-2'},
    {label:'Assignment-3',value:'Assignment-3'},
    {label:'Assignment-4',value:'Assignment-4'},
    {label:'Assignment-5',value:'Assignment-5'},
    {label:'Assignment-6',value:'Assignment-6'},
    {label:'Assignment-7',value:'Assignment-7'},
    {label:'Assignment-8',value:'Assignment-8'},
    {label:'Assignment-9',value:'Assignment-9'},
    {label:'Assignment-10',value:'Assignment-10'},
    {label:'Assignment-11',value:'Assignment-11'},
    {label:'Assignment-12',value:'Assignment-12'},
    {label:'Assignment-13',value:'Assignment-13'},
    {label:'Assignment-14',value:'Assignment-14'},
    {label:'Assignment-15',value:'Assignment-15'},
    {label:'Assignment-16',value:'Assignment-16'},
    {label:'Assignment-17',value:'Assignment-17'},
    {label:'Assignment-18',value:'Assignment-18'},
    {label:'Assignment-19',value:'Assignment-19'},
    {label:'Assignment-20',value:'Assignment-20'},
    {label:'Assignment-21',value:'Assignment-21'},
    {label:'Assignment-22',value:'Assignment-22'},
    {label:'Assignment-23',value:'Assignment-23'},
    {label:'Assignment-24',value:'Assignment-24'},
    {label:'Assignment-25',value:'Assignment-25'},
    {label:'Assignment-26',value:'Assignment-26'},
    {label:'Assignment-27',value:'Assignment-27'},
    {label:'Assignment-28',value:'Assignment-28'},
    {label:'Assignment-29',value:'Assignment-29'},
    {label:'Assignment-30',value:'Assignment-30'},
]