import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { invoice } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { dateTimeSaparator } from '../../../helper/formatDate';
import { ADDRESS, CONTCAT, EMAIL, PINCODE, WEBSITE, WEBSITEURL } from '../../../config';

function ViewInvoice() {
    const {state} = useLocation();
    const [invoicData,setInvoiceData] = useState(null);
    const invoiceId = state && state.invoiceId;
    useEffect(()=> {
        if(invoiceId)
        invoice.get(invoiceId)
        .then(res=> setInvoiceData(res.data.invoices))
        .catch(e=>swal.error('Ooopss ! ',e.response?e.response.data.message:e.message))
    },[invoiceId])
    const date = new Date();
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const currentMonth = monthNames[date.getMonth()]; 
    const currentYear = date.getFullYear().toString().slice(-2); 
    return (
        <>
            <div className="row justify-content-center">
                <div className="col-xxl-9">
                    <div className="card" id="demo">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-header border-bottom-dashed p-4">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <img src="http://localhost:3000/static/media/logofinal.2905f62a6c0ace45a9b6.png" className="card-logo card-logo-dark" alt="logo dark" height="47" />
                                            <img src="http://localhost:3000/static/media/logofinal.2905f62a6c0ace45a9b6.png" className="card-logo card-logo-light" alt="logo light" height="47" />
                                            <div className="mt-sm-5 mt-4">
                                                <h6 className="text-muted text-uppercase fw-semibold">Address</h6>
                                                <p className="text-muted mb-1" id="address-details">{ADDRESS}</p>
                                                <p className="text-muted mb-0" id="zip-code"><span>PinCode: </span>{PINCODE}</p>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 mt-sm-0 mt-3">
                                            <h6><span className="text-muted fw-normal">Legal Registration No:</span><span id="legal-register-no"> SK01{currentMonth}{currentYear}0{invoicData?.id}</span></h6>
                                            <h6><span className="text-muted fw-normal">Email:</span><span id="email"> {EMAIL}</span></h6>
                                            <h6><span className="text-muted fw-normal">Website:</span> <a href={WEBSITEURL} className="link-primary" target="_blank" id="website"> {WEBSITE}</a></h6>
                                            <h6 className="mb-0"><span className="text-muted fw-normal">Contact No: </span><span id="contact-no"> {CONTCAT}</span></h6>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-lg-12">
                                <div className="card-body p-4">
                                    <div className="row g-3">
                                        <div className=" col-4">
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Invoice No</p>
                                            <h5 className="fs-14 mb-0">#SK01<span id="invoice-no">{invoicData?.id}</span></h5>
                                        </div>
                                        
                                        <div className="col-4">
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Date</p>
                                            <h5 className="fs-14 mb-0"><span id="invoice-date">{dateTimeSaparator(invoicData?.created_at).date}</span> <small className="text-muted" id="invoice-time">{dateTimeSaparator(invoicData?.created_at).time}</small></h5>
                                        </div>
                                        
                                        <div className="col-4">
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Total Amount</p>
                                            <h5 className="fs-14 mb-0">&#8377;<span id="total-amount"> {invoicData?.transactions?.reduce((acc,tr)=>acc+parseFloat(tr.paidamt),0).toFixed(2)}</span></h5>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <div className="col-lg-12">
                                <div className="card-body p-4 border-top border-top-dashed">
                                    <div className="row g-3">
                                        <div className="col-6">
                                            <h6 className="text-muted text-uppercase fw-semibold mb-3">User Details</h6>
                                            <p className="fw-medium mb-2" id="billing-name">{invoicData?.payer?.first_name} {invoicData?.payer?.last_name}</p>
                                            <p className="text-muted mb-1" id="billing-address-line-1">{invoicData?.payer?.email}</p>
                                            <p className="text-muted mb-1"><span>Phone: +91 </span><span id="billing-phone-no">{invoicData?.payer?.phone}</span></p>
                                            <p className="text-muted mb-0"><span>User Id : </span><span id="billing-tax-no"> SK01{currentMonth}{currentYear}0{invoicData?.payer?.id}</span> </p>
                                        </div>
                                        
                                        <div className="col-6">
                                            <h6 className="text-muted text-uppercase fw-semibold mb-3">Accountant Details</h6>
                                            <p className="fw-medium mb-2" id="billing-name">{invoicData?.accountant?.first_name} {invoicData?.accountant?.last_name}</p>
                                            <p className="text-muted mb-1" id="billing-address-line-1">{invoicData?.accountant?.email}</p>
                                            <p className="text-muted mb-1"><span>Phone: +91 </span><span id="billing-phone-no">7{invoicData?.accountant?.phone}</span></p>
                                            <p className="text-muted mb-0"><span>User Id : </span><span id="billing-tax-no"> #{invoicData?.accountant?.id}</span> </p>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <div className="col-lg-12">
                                <div className="card-body p-4">
                                    <div className="table-responsive">
                                        <table className="table table-borderless text-center table-nowrap align-middle mb-0">
                                            <thead>
                                                <tr className="table-active">
                                                    <th scope="col" style={{width: '50px'}}>#</th>
                                                    <th scope="col">Perticulars</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Balance</th>
                                                    <th scope="col">Discount %</th>
                                                    <th scope="col" className="text-end">Paid Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody id="products-list">
                                                {
                                                    invoicData?.transactions?.map((transaction,index)=>transaction.paidamt>0 &&(
                                                        <tr key={index} >
                                                            <th scope="row">1</th>   
                                                            <td className="text-start">
                                                                <span className="fw-medium">{transaction.particulars}</span>
                                                                <p className="text-muted mb-0">full paid</p>
                                                            </td>
                                                            <td>&#8377; {transaction.amount}</td>
                                                            <td>&#8377; {transaction.balance}</td>
                                                            <td>0 %</td>
                                                            <td className="text-end">&#8377; {transaction.paidamt}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="border-top border-top-dashed mt-2">
                                        <table className="table table-borderless table-nowrap align-middle mb-0 ms-auto" style={{width:"250px"}}>
                                            <tbody>
                                                <tr>
                                                    <td>Sub Total</td>
                                                    <td className="text-end">&#8377; {invoicData?.transactions?.reduce((acc,tr)=>acc+parseFloat(tr.paidamt),0).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Discount <small className="text-muted">(--)</small></td>
                                                    <td className="text-end">-&#8377; 0</td>
                                                </tr>
                                                <tr className="border-top border-top-dashed fs-15">
                                                    <th scope="row">Total Amount</th>
                                                    <th className="text-end">&#8377; {invoicData?.transactions?.reduce((acc,tr)=>acc+parseFloat(tr.paidamt),0).toFixed(2)}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                    <div className="mt-3">
                                        <h6 className="text-muted text-uppercase fw-semibold mb-3">Payment Details:</h6>
                                        <p className="text-muted mb-1">Payment Method: <span className="fw-medium" id="payment-method">{invoicData?.payment_mode}</span></p>
                                        <p className="text-muted">Total Amount: <span className="fw-medium" id=""> </span><span id="card-total-amount">&#8377; {invoicData?.transactions?.reduce((acc,tr)=>acc+parseFloat(tr.paidamt),0).toFixed(2)}</span></p>
                                    </div>
                                    {/* <div className="mt-4">
                                        <div className="alert alert-info">
                                            <p className="mb-0"><span className="fw-semibold">NOTES:</span>
                                                <span id="note">All accounts are to be paid within 7 days from receipt of invoice. To be paid by cheque or
                                                    credit card or direct payment online. If account is not paid within 7
                                                    days the credits details supplied as confirmation of work undertaken
                                                    will be charged the agreed quoted fee noted above.
                                                </span>
                                            </p>
                                        </div>
                                    </div> */}
                                    <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                        <a href="javascript:window.print()" className="btn btn-success"><i className="ri-printer-line align-bottom me-1"></i> Print</a>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default ViewInvoice