import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../components/common/BreadCrumb'
import { Card, CardBody, CardHeader, Col, Row, Button } from "react-bootstrap";
import { TableResponsive } from "../../../components/common/TableResponsive";
import CustomSelect from '../../../components/CustomSelect';
import { Link } from 'react-router-dom';
import { ASSET_URL, attendance, batch, course } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import SimpleBar from 'simplebar-react';
import ImageViewer from '../../../components/ImageViewer';
import { AttendanceModalList } from './AttendanceModalList';
import { batchSetCode } from '../../../helper/formatDate';

export const AttendanceList = () => {
    const [batches, setBatches] = useState([]);
    const currentDate = new Date();
    const [generatedColumns, setGeneratedColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [month,setMonth]=useState('');
    const dispatch = useDispatch();
    const MONTHS = [
        { days: '31', value: 0, label: "January" },
        { days: '28', value: 1, label: "February" },
        { days: '31', value: 2, label: "March" },
        { days: '30', value: 3, label: "April" },
        { days: '31', value: 4, label: "May" },
        { days: '30', value: 5, label: "June" },
        { days: '31', value: 6, label: "July" },
        { days: '31', value: 7, label: "August" },
        { days: '30', value: 8, label: "September" },
        { days: '31', value: 9, label: "October" },
        { days: '30', value: 10, label: "November" },
        { days: '31', value: 11, label: "December" }
    ];
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const currentDay = daysOfWeek[currentDate.getDay()];
    useEffect(() => {
        batch.list().then(r => setBatches(r.data.batches)).catch(e => console.error(e));
    }, [])
    const generateDynamicColumns = (month) => {
        const daysInMonth = MONTHS.filter(i => i.value === month)[0].days;
        const generatedColumns = [];
        for (let day = 1; day <= daysInMonth; day++) {
            const column = {
                Header: day.toString(),
                HeaderClass: (currentDate.getDate() == day && currentDate.getMonth() == month) ? 'text-center bg-soft-warning' : 'text-center',
                DataClass: (currentDate.getDate() == day && currentDate.getMonth() == month) ? 'text-center bg-soft-warning' : 'text-center',
                Cell: cell => {
                    const attendanceData = cell.row.original.attendance.find(i => {
                        const date = new Date(i.attendance_date);
                        return date.getDate() === day;
                    });
                    return attendanceData
                        ? (<span className={(attendanceData.status == 'P' ? 'text-success' : (attendanceData.status == 'A' ? 'text-danger' : (attendanceData.status == 'L' ? 'text-warning' : '')))} >{attendanceData.status} </span>)
                        : (currentDate.getMonth() > month) ? 'N/A' : ((currentDate.getDate() >= day && (currentDate.getMonth() == month) ? 'N/A' : '-'));
                }
            };
            generatedColumns.push(column);
        }
        return generatedColumns;
    }
    const handleFetchFormSubmit = e => {
        dispatch(setPreloader({ loader: true, message: "Fetching Attendance Please wait ..." }));
        e.preventDefault();
        const formData = new FormData(e.target);
        const month = parseInt(formData.get('month')) + 1;
        setMonth(month);
        const batch_id = formData.get('batch_id');
        if (!(batch_id && month)) {
            swal.error('Opppsss...!', 'Please select Month and Batch')
            return
        }
        attendance.list_by_batch(batch_id, month, currentDate.getFullYear())
            .then(res => {
                setGeneratedColumns(generateDynamicColumns(month - 1))
                console.log(generatedColumns);
                setTableData(res.data.attendance);
            })
            .catch(e => swal.error(e.response ? e.response.data.message : e.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: "" })))
    }
    return (
        <>
            <BreadCrumb title="Attendance List" prevPage="Attendance" prevPath="/attendance" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Attendance List</h5>
                            <Link to="/attendance" className='btn btn-soft-success add-btn waves-effect me-2'>
                                <i className="ri-contacts-fill pt-1"></i> New Attendance
                            </Link>
                        </CardHeader>
                        <CardBody className="">
                            <form onSubmit={handleFetchFormSubmit}>
                                <Row className='my-3'>
                                    <div className="col-5 mt-2">
                                        <div>
                                            <label htmlFor="date" className="form-label">Month</label>
                                            <CustomSelect defaultValue={MONTHS.filter(i => i.value === currentDate.getMonth())} name='month' id='date' options={MONTHS} />
                                        </div>
                                    </div>
                                    <div className="col-5 mt-2">
                                        <div>
                                            <label htmlFor="select_batch_input" className="form-label">Select Batch</label>
                                            <CustomSelect id="select_batch_input" name="batch_id" isSearchable options={batches?.map(i => ({ label: batchSetCode(i), value: i.id }))} />
                                        </div>
                                    </div>
                                    <div className="col-2 mt-2">
                                        <label htmlFor="fetch" style={{ opacity: 0 }} className="form-label">Fetch</label>
                                        <input className='form-control btn btn-soft-success' type='submit' value={'Fetch'} />
                                    </div>
                                </Row>
                            </form>
                            <div className='d-none  d-lg-block'>
                                {tableData.length ? (
                                    <div className='d-flex flex-row justify-content-between'>
                                        <div>
                                            <TableResponsive
                                                customPageSize={1000}
                                                isPagination={false}
                                                isShowingPageLength={false}
                                                showCustomOptionPage={false}
                                                showFilter={false}
                                                noRecord=''
                                                columns={[
                                                    {
                                                        Header: "Student",
                                                        HeaderClass: 'text-left',
                                                        DataClass: 'text-left',
                                                        Cell: (cell) => {
                                                            const imageUrl = ASSET_URL + cell.row.original.avatar;
                                                            return (
                                                                <ImageViewer image={imageUrl}>
                                                                    <span> <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover', width: '20px', height: '20px' }} src={imageUrl} alt="User Avatar" /><span style={{ width: "80px" }}>

                                                                        {`${cell.row.original.first_name} ${cell.row.original.last_name}`.length > 10
                                                                            ? `${cell.row.original.first_name} ${cell.row.original.last_name}`.slice(0, 15) + '...'
                                                                            : `${cell.row.original.first_name} ${cell.row.original.last_name}`}</span></span>
                                                                </ImageViewer>
                                                            )
                                                        }
                                                    },

                                                ]}
                                                data={tableData}
                                            />
                                        </div>
                                        <div className='' style={{ maxWidth: "800px" }}>
                                            <SimpleBar className='custom-scrollbar' id="scrollbar" style={{ maxHeight: "calc(100vh - 130px)", maxWidth: "1000px", }}>
                                                <TableResponsive
                                                    customPageSize={1000}
                                                    isPagination={false}
                                                    isShowingPageLength={false}
                                                    showCustomOptionPage={false}
                                                    showFilter={false}
                                                    columns={generatedColumns}
                                                    data={tableData}
                                                />
                                            </SimpleBar>
                                        </div>
                                        <div className=''>
                                            <TableResponsive
                                                customPageSize={1000}
                                                isPagination={false}
                                                isShowingPageLength={false}
                                                showCustomOptionPage={false}
                                                showFilter={false}
                                                columns={[
                                                    {
                                                        Header: "Present",
                                                        HeaderClass: 'text-center',
                                                        DataClass: 'text-center text-success',
                                                        Cell: cell => {
                                                            const row = cell.row.original;
                                                            return row.attendance?.reduce((total, att) => att.status === "P" ? total + 1 : total + 0, 0)
                                                        }
                                                    },
                                                    {
                                                        Header: "Absent",
                                                        HeaderClass: 'text-center',
                                                        DataClass: 'text-center text-danger',
                                                        Cell: cell => {
                                                            const row = cell.row.original;
                                                            return row.attendance?.reduce((total, att) => att.status === "A" ? total + 1 : total + 0, 0)
                                                        }
                                                    },
                                                    {
                                                        Header: "Leave",
                                                        HeaderClass: 'text-center',
                                                        DataClass: 'text-center text-warning',
                                                        Cell: cell => {
                                                            const row = cell.row.original;
                                                            return row.attendance?.reduce((total, att) => att.status === "L" ? total + 1 : total + 0, 0)
                                                        }
                                                    }
                                                ]}
                                                data={tableData}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <Row>
                                        <Col>
                                            <Card>
                                                <Card.Body className="d-flex bg-light bg-opacity-50 rounded align-items-center justify-content-center p-5">
                                                    <h5 className='text-center'>No Data Found</h5>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                            <div className='d-lg-none d-xs-block'>
                                <TableResponsive
                                    columns={[
                                        {
                                            Header: 'List',
                                            HeaderClass: 'd-none',
                                            DataClass: 'd-none',
                                            list: (row) => {
                                                const imageUrl = ASSET_URL + row?.avatar;
                                                return (
                                                    <div className="d-flex">
                                                        <ImageViewer image={imageUrl}>
                                                            <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={imageUrl} alt="User Avatar" />
                                                        </ImageViewer>
                                                        <div className="flex-grow-1" data-id="1">
                                                            <p className="text-muted mb-0" style={{ whiteSpace: 'nowrap' }}> {row.first_name} {row.last_name} </p>
                                                        </div>
                                                        <div className="flex-grow-1" >
                                                            Present- {row.attendance?.reduce((total, att) => att.status === "P" ? total + 1 : total + 0, 0)} | Absent - {row.attendance?.reduce((total, att) => att.status === "A" ? total + 1 : total + 0, 0)} | Leave - {row.attendance?.reduce((total, att) => att.status === "L" ? total + 1 : total + 0, 0)}
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div>
                                                                {/* <button className="btn btn-sm btn-soft-info me-1" data-id="1"> <i className="ri-eye-fill"></i> </button> */}
                                                                <AttendanceModalList  row={row} month={month} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }
                                    ]}
                                    data={tableData}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
