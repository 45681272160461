import React from 'react'
import { ASSET_URL } from '../../helper/api_url'
import { CardImg, Image } from 'react-bootstrap'
import { formatDate } from '../../helper/formatDate'
import ImageViewer from '../../components/ImageViewer'

export const Document = ({documents}) => {
    return (
        <>
            <div className="tab-pane fade" id="documents" role="tabpanel">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-4">
                            <h5 className="card-title flex-grow-1 mb-0">Documents</h5>
                            <div className="flex-shrink-0">
                                <input className="form-control d-none" type="file" id="formFile" disabled/>
                                <label htmlFor="formFile" className="btn btn-danger"><i className="ri-upload-2-fill me-1 align-bottom"></i> Upload File</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-borderless align-middle mb-0">
                                        <thead className="table-light">
                                            <tr>
                                                <th scope="col">File Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Size</th>
                                                <th scope="col">Upload Date</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                documents?.map((item, idx) => (
                                                    <tr key={idx}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar-sm">
                                                                    <div className="avatar-title bg-soft-primary text-primary rounded fs-20">
                                                                        <ImageViewer image={ASSET_URL+item.path} thumbnail>
                                                                        <CardImg src={ASSET_URL + item.path} style={{ aspectRatio: '16/7', objectFit: 'cover', height: 'auto' }} alt="issue screenshot" />
                                                                        </ImageViewer>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-3 flex-grow-1">
                                                                    <h6 className="fs-15 mb-0"><a href="javascript:void(0)">{item.name}</a>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>PNG</td>
                                                        <td>2 MB</td>
                                                        <td>{formatDate(item.created_at)}</td>
                                                        <td>{item.approved===1?<span className='badge badge-soft-info px-2 ms-1 mx-2'>Approved</span>  : <span className='badge badge-soft-danger px-2 ms-1 mx-2'>Reject</span>}</td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <a href="javascript:void(0);" className="btn btn-light btn-icon" id="dropdownMenuLink15" data-bs-toggle="dropdown" aria-expanded="true">
                                                                    <i className="ri-equalizer-fill"></i>
                                                                </a>
                                                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink15">
                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill me-2 align-middle text-muted"></i>View</a></li>
                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-download-2-fill me-2 align-middle text-muted"></i>Download</a></li>
                                                                    <li className="dropdown-divider"></li>
                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>Delete</a></li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                ))
                                            }


                                        </tbody>
                                    </table>
                                </div>
                                {/* <div className="text-center mt-3">
                                                        <a href="javascript:void(0);" className="text-success"><i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Load more </a>
                                                    </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
