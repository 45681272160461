import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { Modal } from 'react-bootstrap'
import { countriesData, course, } from '../../../helper/api_url'
import Camera from '../../../components/common/Camera';
import SimpleBar from 'simplebar-react';
import { useModalHandler, useWebCam } from '../../../helper/custom_hook';
import ModalProfileImage from '../../../components/common/ModalProfileImage';
import { enquiry } from '../../../helper/api_url';
import CustomSelect from '../../../components/CustomSelect';
import ImageViewer from '../../../components/ImageViewer';
import { useNavigate } from 'react-router-dom';
export function NewEnquiryModal({ apiHandler }) {
    const navigate = useNavigate();
    const { genders, toggleModal, status, userProfile, setUserProfile } = useModalHandler();
    const { webcam, toggleWebcam, setCapturedImageToInput } = useWebCam(setUserProfile);
    const { handleSubmit } = apiHandler;
    const [coursesData, setCoursesData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const onSave = f => { setCapturedImageToInput(f, document.getElementById('avatarInput')) }
    useEffect(() => {
        if (status)
            countriesData.citylist()
                .then(res => {setStateData([...res.data[99].states.map(state=>({value:state.name,label:state.name}))]);})
                .catch(err => console.log(err.response ? err.response.msg : err.msg))
            course.list()
                .then(res => setCoursesData([...res.data.course.map(course => ({ value: course.id, label: course.name }))]))
                .catch(err => console.log(err.response ? err.response.data.message : err.message))
    }, [status]);
    return (
        <>
            <button onClick={toggleModal} className='btn btn-soft-success add-btn waves-effect'>
                <i className="ri-add-line align-bottom me-1"></i>
                <span>Add Enquiry</span>
            </button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal} >
                <Modal.Header closeButton>
                    <Modal.Title><h5>New Enquiry</h5></Modal.Title>
                </Modal.Header>
                <form onSubmit={e => handleSubmit(e, enquiry.add, (res)=>{
                    toggleModal();
                    navigate('/enquiry-slip', { state: { id:res.data.enquiry.id } });
                })}>
                    <SimpleBar id="scrollbar" className='custom-scrollbar' style={{ maxHeight: "calc(90vh - 100px)" }} >
                        <Modal.Body>
                            <div className='position-relative'>
                                {webcam ? (
                                    <div className='position-absolute top-0 bottom-0 d-flex justify-content-center align-items-center start-0 end-0 modal-content border-0' style={{ zIndex: 9 }}>
                                        <Camera onSave={f => { onSave(f); toggleWebcam() }} onClose={() => toggleWebcam()} />
                                    </div>) : null}
                                <div className="row g-3">
                                    {userProfile &&
                                     <ImageViewer image={userProfile}>
                                         <ModalProfileImage viewimage={userProfile}  />
                                     </ImageViewer>
                                        
                                     }
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="enquiryName" className="form-label">Name</label>
                                            <input type="text" className="form-control" id='enquiryName' name="name" placeholder="Enter Full Name" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="enquiryFatherName" className="form-label">Fathsr's Name</label>
                                            <input type="text" className="form-control" id='enquiryFatherName' name="father_name" placeholder="Enter Father's Name" />
                                        </div>
                                    </div>
                                    
                                    <div className="col-6">
                                        <label htmlFor="genderInput" className="form-label">Gender</label>
                                        <CustomSelect options={genders} isSearchable name='gender' />
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="qualification" className="form-label">Qualification</label>
                                            <input type="text" className="form-control" id='qualification' name="qualification" placeholder="Enter Your Qualification" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="phoneNumber" className="form-label">Phone</label>
                                            <input type="tel" className="form-control" name='phone' id="phoneNumber" placeholder="Enter Phone number" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="whatsappNumber" className="form-label">Whatsapp</label>
                                            <input type="tel" className="form-control" name='whatsapp' id="whatsappNumber" placeholder="Enter Whatsapp number" />
                                        </div>
                                    </div>
                                    
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="address1" className="form-label">Address 1</label>
                                            <input type="text" className="form-control" id='address1' name="address_1" placeholder="Enter Your Address" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="state" className="form-label">State</label>
                                            <CustomSelect id='state' name="state" placeholder="Select" options={stateData} isSearchable  />
                                            
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="district" className="form-label">District</label>
                                            <input type="text" className="form-control" id='district' name="district" placeholder="Enter district" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="pin" className="form-label">Pin code</label>
                                            <input type="number" className="form-control" id='pin' name="pin" placeholder="Enter pin code" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="course" className="form-label">Select a Course</label>
                                            <CustomSelect name="course_id" isSearchable id='course' placeholder="Select Course" options={coursesData} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="preffered_batch" className="form-label">Preffered Batch</label>
                                            <CustomSelect name="preffered_batch" id="preffered_batch" options={[{ value: 'morning', label: 'Morning' }, { value: 'evening', label: 'Evening' }, { value: 'afternoon', label: 'Afternoon' },]} />
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="avatarInput" className="form-label">Profile Image</label>
                                        <div className="input-group mb-3">
                                            <input type="file" name="avatar" id="avatarInput" onChange={(e) => onSave(e.target.files[0])} className='form-control' />
                                                <button onClick={toggleWebcam} type='button' className='btn btn-soft-dark input-group-text'><i className='bx bx-camera'></i></button>
                                        </div>
                                    </div>
                                    
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="tryal_date" className="form-label">Trail start Date</label>
                                            <input type="date" className="form-control" id='tryal_date' name="tryal_date" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="tryal_period" className="form-label">Trail Period</label>
                                            <input type="number" defaultValue={4} className="form-control" id='tryal_period' name="tryal_period" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="joining_date" className="form-label">When to Join</label>
                                            <input type="date" className="form-control" id='joining_date' name="joining_date" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </SimpleBar>
                    <Modal.Footer>
                        <div className="col-lg-12">
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}