import React, { useState } from 'react'
import {Link, Navigate} from 'react-router-dom';
import { Modal,Row,Col} from 'react-bootstrap'
import {ASSET_URL } from '../../../helper/api_url'
import ModalProfileImage from '../../../components/common/ModalProfileImage';
import { formatDate } from '../../../helper/formatDate';
import ImageViewer from '../../../components/ImageViewer';
export function ViewFollowModal(data) {
    const [status,setStatus] = useState();
    const toggleModal = () => setStatus(!status);
    const viewimage = ASSET_URL + data.follow.enquiry.avatar;
    const trial_end = data.follow.enquiry.tryal_date != null ? new Date(data.follow.enquiry.tryal_date) : null;
    if (trial_end != null) trial_end.setDate(trial_end.getDate() + data.follow.enquiry.tryal_period);
    // const handleReport = (data) => {
    //     Navigate('/report', { state: { data } });
    // }
    return (
        <>
            {data.children ? (<div onClick={toggleModal}>{data.children}</div>) : (
                <button onClick={toggleModal} className='btn btn-sm btn-soft-info me-1'>
                    <i className="ri-eye-fill" />
                </button>
            )}
            <Modal className="fade" centered={true} show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Follow By {data.follow.enquiry?.created_by?.first_name}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <ImageViewer image={viewimage}>
                    <ModalProfileImage viewimage={viewimage} />
                </ImageViewer>
                    <Row className='px-2 text-center mt-2'>
                        <Col xs={12} >
                            <ul className="list-group">
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Candidate Name </span></Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-nowrap'> {data.follow.enquiry.name}
                                                {(trial_end != null)
                                                    ? (trial_end > Date.now()
                                                        ? (<span className='badge badge-soft-success px-1 ms-1'>On Trail</span>)
                                                        : (<span className='badge badge-soft-danger px-1 ms-1'>Trail Expired</span>))
                                                    : null}
                                            </span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Father's Name </span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-nowrap'> {data.follow.enquiry.father_name} </span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Course </span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-nowrap'> {data.follow.enquiry.course.name} </span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Starting Date </span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-nowrap'>{formatDate(data.follow.enquiry.created_at)}</span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Address </span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-wrap'> {data.follow.enquiry.address_1} </span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Status </span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-wrap'> {data.follow.status} </span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Remark </span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-wrap'> {data.follow.remark} </span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Contact </span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-wrap'><i className='bx bx-phone-outgoing text-success' /> {data.follow.enquiry.phone} <br /> <i className='bx bxl-whatsapp text-success' />{data.follow.enquiry.whatsapp}</span>
                                        </Col>
                                    </Row>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className='w-100 d-flex align-items-center justify-content-between px-2'>
                        <a href={`tel:${data.follow.enquiry.phone}`} className="btn btn-soft-info" >
                            <i className='bx bx-phone-outgoing' />
                        </a>
                        <button className="btn btn-sm btn-outline-success" /*onClick={() => handleReport(data.follow)}*/ >View Detailed Profile</button>
                        <a href={`whatsapp://send?phone=+91${data.follow.enquiry.whatsapp}&text=Hello%20${data.follow.enquiry.name}%0A%0A`} className="btn ms-2 btn-soft-success" >
                            <i className='bx bxl-whatsapp' />
                        </a>
                    </div>
                </Modal.Footer>
            </Modal>
        </>

    );
}