import { post, get, del,countries, put, use, API_URL } from './api_helper';
export const ASSET_URL = "https://skicst.org/backend/";
export const APP_NAME = "SKICST"
export const auth = {
    login: data => post("/login", data),
    validate: () => get('/validate'),
    logout: () => get("/logout"),
}

export const allUser = {
    users: (data) => post("/user", data),
    changeStatus: (id) => get(`/user/${id}/toggle/is_active`),
    roles: () => get("/roles"),
    list: () => get("/users"),
    // delete:(id)=>del(`/user/${id}`),
    delete: (id) => get(`/user/${id}/toggle/is_deleted`),
    update: (data) => post("/user/update", data),
}
export const staff = {
    users: (data) => post("/user", data),
    list: () => get("/users/type/staff"),
    roles: () => get("/roles"),
    changeStatus: (data) => get(`/user/${data}/toggle/is_active`),
    delete: (id) => del(`/user/${id}`)
}
export const student = {
    // admission
    add: (data) => post("/admission", data),
    add_other_details: (data) => post("/admission/other-details", data),
    add_address: (data) => post("/admission/address", data),

    list: () => get("/users/type/student"),
    roles: () => get("/roles"),
    changeStatus: (data) => get(`/user/${data}/toggle/is_active`),
    delete: (id) => del(`/user/${id}`),
    enrollement: (data) => post(`/enroll`,data),
    profile: (id) => get(`/users/${id}/profile`)
}
export const userAddress = {
    add: (data) => post("/user/address", data),
}
export const userQualification = {
    add: (data) => post("/user/qualification", data),
    list: (uid) => get(`/user/${uid}/qualifications`)
}
export const teacher = {
    users: (data) => post("/user", data),
    list: () => get("/users/type/teacher"),
    roles: () => get("/roles"),
    changeStatus: (data) => get(`/user/${data}/toggle/is_active`),
    delete: (id) => del(`/user/${id}`)
}
export const visitor = {
    users: (data) => post("/user", data),
    list: () => get("/users/type/visitor"),
    roles: () => get("/roles"),
    changeStatus: (data) => get(`/user/${data}/toggle/is_active`),
    delete: (id) => del(`/user/${id}`)
}
export const enquiry = {
    list: () => get("/enquiries"),
    fetchSingle: (id) => get(`/enquiry/${id}`),
    get: (id) => get(`/enquiry/${id}`),
    add: (data) => post(`/enquiry`, data),
    update: (data) => post('/enquiry/update', data),
    delete: (id) => del(`/enquiry/${id}`),
    addFollow: (data) => post('/enquiry/add-followup', data),
}
export const followup = {
    pending: (date) => get(`followups/pending/${date}`)
}

export const course = {
    list: () => get("/courses"),
    add: (data) => post(`/course`, data),
    addFee: (cid, fid) => get(`/course/${cid}/fees/${fid}`),
    update: (data) => post(`/course/update`, data),
    delete: (id) => del(`/course/${id}`)
}
export const batch = {
    add: (data) => post(`/course/batch`, data),
    list: () => get(`/batches`),
    update: (data) => post(`/update/batch`, data),
    delete: (id) => del(`/batch/${id}`),
    students:(batch_id)=>get(`/students-by-batch/${batch_id}`)
}
export const role = {
    list: () => get("/roles"),
    add: (data) => post(`/role`, data),
    update: (data) => post(`/role/change-priority`, data),
    delete: (id) => del(`/role/${id}`)
}
export const fee = {
    list: () => get("/fees"),
    fetchSingle: (id) => get(`/fee/${id}`),
    add: (data) => post(`/fee`, data),
    update: (data) => post(`/fee/update`, data),
    delete: (id) => del(`/fee/${id}`),
    dueslist: () => get(`/fees/dues-list`),
    studentDuesList: () => get(`/fees/student-dues-list`),
    duesPayment:(data)=>post('/dues/payment',data),
}

export const support = {
    addIssue: data => post(`/issue`, data),
    list: data => get(`/issues`, data),
    markProgress: id => get(`/issue/${id}/seen`),
    markResolved: id => get(`/issue/${id}/resolve`),
    delete: id => del(`/issue/${id}`),
    comment: (data) => post('/issue/comment', data),
    commentlist: (pid) => get(`/issue/${pid}/comments`),
    Commentdelete: (id) => del(`/issue/comment/${id}`),
}

export const accounts = {
    txnHistory:()=>get('/transactions'),
    txnDetails:(id)=>get(`/transaction/${id}`),
}

export const countriesData = {
    citylist: ()=> countries(`/states`),
}
export const attendance = {
    add: (data)=> post(`/attendance`,data),
    list:(filter)=>post('/get-attendance',filter),
    list_by_batch:(batch_id,month,year='')=>get(`/attendance/${batch_id}/${month}/${year}`),
}

export const transaction  = {
    list:()=>get('/transactions'),
    get:(id)=>get(`/transaction/${id}`),
    today:()=>get('/transactions/today'),
}
export const invoice = {
    list:()=>get('/invoices'),
    get:(id)=>get('/invoices/'+id),
    delete:(id)=>del('/invoice/'+id),
    
}

export const exam = {
    create_question:(data)=>post('/question',data),
    create_assignments:(data)=>post('/assignment',data),
    read_question_or_assignment:(data)=>post('/question-get',data),
    delete_question_or_assignment:(id)=>del(`/question/${id}`),
    impost_assignment:(data)=>post('/impose-assignment',data),
    staff_given_assignments:(user_id)=>get(`/staff-assignment/${user_id}`),
    students_assignments:(user_id)=>get(`/student-assignment/${user_id}`),

    assignment_doc_upload:(data)=>post('/assignment-doc-upload',data),
    assignment_doc_delete:(data)=>del('/assignment-doc-delete',data),
    question_paper:(data)=>post('/question-paper',data),
}
