import React from 'react'
import { Modal} from 'react-bootstrap'
import { fee } from '../../../helper/api_url';
import { useModalHandler } from '../../../helper/custom_hook';
export function NewFeeModal({ apiHandler }) {
    const {toggleModal,status} = useModalHandler();
    return (
        <>
            <button onClick={toggleModal} className='btn btn-soft-success add-btn waves-effect'>
                <i className="ri-add-line align-bottom me-1"></i>
                <span>Add Fee</span>
            </button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>New Fee</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={e => apiHandler.handleSubmit(e,fee.add,toggleModal)}>
                        <div className="row g-3">
                            <div className="col-12">
                                <div>
                                    <label htmlFor="feeName" className="form-label">Fee Name</label>
                                    <input type="text" className="form-control" id='feeName' name="name" placeholder="Enter Fee Name" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="amount" className="form-label">Fee Amount</label>
                                    <input type="number" className="form-control" id='amount' name="amount" placeholder="Enter Fee Amount" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="type" className="form-label">Fee Type</label>
                                    <select className='form-control' name="type">
                                        <option value='one-time' >One Time</option>
                                        <option value='monthly' >Monthly</option>
                                        <option value='annual' >Yearly</option>
                                        <option value='bi-annual' >Half-Yearly</option>
                                        <option value='quaterly' >Quaterly</option>
                                        <option value='daily' >Daily</option>
                                        <option value='weekly' >Weekly</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-12">
                                <div>
                                    <label htmlFor="priority" className="form-label">Description</label>
                                    <textarea className='form-control' name='description'></textarea>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-4">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    );
}