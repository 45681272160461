export const priority = [
    {color:'danger',key:"Highest",value:100},
    {color:'danger',key:"High",value:90},
    {color:'warning',key:"Top Staff",value:80},
    {color:'warning',key:"Medium High" ,value:70},
    {color:'info',key:"Medium",value:60},
    {color:'primary',key:"Medium Low",value:50},
    {color:'primary',key:"Bottom Staff" ,value:40},
    {color:'success',key:"Low",value:30},
    {color:'muted',key:"Lowest",value:0}
];
const next_pervious = (number)=>{
    if(number <= 10) return {previous: 0 , next:10}
    let mod = number%10
    if(mod == 0) return {previous:number-10,next:number}
    return {previous:number-mod,next:(number+(10-mod))}
}
export const getPriorityValue = p => {
    if(p>100) return priority[0];
    if(next_pervious(p).next <=20) return priority[priority.length-1]
    return priority.find(role=>role.value === next_pervious(p).next)
}
export const paymentModes = [
    {label:'UPI',value:'upi'},
    {label:'CASH',value:'cash'},
    {label:'Online',value:'online'},
    {label:'NEFT',value:'neft'},
    {label:'RTGS',value:'rtgs'},
]