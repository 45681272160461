import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
export const WorkProcess = () => {
  return (
    <>
            <section className="section" id='plans'>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <div className="text-center mb-5">
                            <h3 className="mb-3 fw-semibold">Our Work Process</h3>
                            <p className="text-muted mb-4 ff-secondary">In an optimal scenario within a computer institute, the workflow should ideally include students acknowledging the importance of acquiring web-related skills before the instructional design phase commences.</p>
                        </div>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col lg={4}>
                        <div className="process-card mt-4">
                            <div className="process-arrow-img d-none d-lg-block">
                                <img src="assets/images/landing/process-arrow-img.png" alt="" className="img-fluid" />
                            </div>
                            <div className="avatar-sm icon-effect mx-auto mb-4">
                                <div className="avatar-title bg-transparent text-success rounded-circle fs-1">
                                    <i className="bx bx-help-circle"></i>
                                </div>
                            </div>
                            <h5>Enquiry</h5>
                            <p className="text-muted ff-secondary">Let us know your field of study, the educational institution, and your aspirations to excel in your academic journey</p>
                        </div>
                    </Col>   
                    <Col lg={4}>
                        <div className="process-card mt-4">
                            <div className="process-arrow-img d-none d-lg-block">
                                <img src="assets/images/landing/process-arrow-img.png" alt="" className="img-fluid" />
                            </div>
                            <div className="avatar-sm icon-effect mx-auto mb-4">
                                <div className="avatar-title bg-transparent text-success rounded-circle h1">
                                    <i className="ri-user-follow-line"></i>
                                </div>
                            </div>
                            <h5>Get Free Trail</h5>
                            <p className="text-muted ff-secondary">On a three-day trial offer and ignite your educational journey with courses designed to inspire and empower your learning. Unleash your potential and take the first step towards a brighter future!</p>
                        </div>
                    </Col>
                    
                    <Col lg="4">
                        <div className="process-card mt-4">
                            <div className="avatar-sm icon-effect mx-auto mb-4">
                                <div className="avatar-title bg-transparent text-success rounded-circle h1">
                                    <i className="bx bxs-school"></i>
                                </div>
                            </div>
                            <h5>Admission</h5>
                            <p className="text-muted ff-secondary">Secure your admission after experiencing our enriching three-day trial. Elevate your learning journey and join us as we shape a path to your academic success</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
  )
}
