import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/common/BreadCrumb';
import { Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap';
import CustomSelect from '../../../components/CustomSelect';
import { Tooltip } from 'antd';
import { course, fee } from '../../../helper/api_url';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { swal } from '../../../helper/swal';
import { useDispatch } from 'react-redux';

export const AddCourseFees = () => {
    const [tableLoading,setTableLoading] = useState(true);
    const dispatch=useDispatch();
    const courseType = [
        { value: 'Computer Course', label: 'Computer Course' },
        { value: 'one Day Exam', label: 'one Day Exam' },
        { value: 'other', label: 'other' }
    ];

    const courseName = [
        { label: 'DOAP', value: 'DOAP' },
        { label: 'ADCA', value: 'ADCA' },
        { label: 'DCA', value: 'DCA' },
        { label: 'DCAA', value: 'DCAA' },
        { label: 'PGDCA', value: 'PGDCA' },
        { label: 'Tally', value: 'Tally' },
        { label: 'BCA', value: 'BCA' },
        { label: 'MCA', value: 'MCA' },
        { label: 'BBA', value: 'BBA' },
        { label: 'BA', value: 'BA' },
        { label: 'MA', value: 'MA' },
        { label: 'B.Tech', value: 'B.Tech' },
        { label: 'M.Tech', value: 'M.Tech' },
    ];

    const [selectedCourseType, setSelectedCourseType] = useState(null);
    const handleCourseTypeChange = (selectedOption) => { setSelectedCourseType(selectedOption); };
    const eligibility = [{ value: 'matriculation', label: 'matriculation' }, { value: 'intermediate', label: 'intermediate' }, { value: 'graduation', label: 'graduation' }, { value: 'masters', label: 'masters' }, { value: 'none', label: 'none' },]
    const [formFee, setFormFee] = useState(0);
    const [registrationFee, setRegistrationFee] = useState(0);
    const [courseFee, setCourseFee] = useState(0);
    const [examFee, setExamFee] = useState(0);
    const totalFee = formFee + registrationFee + courseFee + examFee;
    const handleCourseFeeAddSubmit = e => {
        e.preventDefault();
        setTableLoading(true);
        dispatch(setPreloader({ loader: true, message: "Please wait ..." }));
        const formData = new FormData(e.target);
        course.add(formData)
            .then((res) => {
                swal.success(res.message);
            })
            .catch((err) => swal.error(err.response ? err.response.data.message : err.message))
            .finally(() => {
                dispatch(setPreloader({ loader: false, message: "" }));
                setTableLoading(false);
            });
    }
    return (
        <>
            <BreadCrumb title="Add Course" prevPage="Home" prevPath="/course" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Create Course & Fees</h5>
                        </CardHeader>
                        <CardBody className="">
                            <form onSubmit={handleCourseFeeAddSubmit}>
                                <Row>
                                    <Col xs={4}>
                                        <Card>
                                            <div>
                                                <label htmlFor="courseType" className="form-label">Type</label>
                                                <CustomSelect isSearchable name='type' id="courseType" options={courseType} onChange={handleCourseTypeChange} />
                                            </div>
                                        </Card>
                                    </Col>
                                    {selectedCourseType?.value === 'Computer Course' && (
                                        <>
                                            <Col xs={4}>
                                                <Card>
                                                    <div>
                                                        <label htmlFor="name" className="form-label">Course Name</label>
                                                        {/* <CustomSelect isSearchable name='name' id="name" options={name} /> */}
                                                        <input type="text" id='name' name='name' className='form-control' placeholder='Course Name' />
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col xs={4}>
                                                <div>
                                                    <label htmlFor="courseName" className="form-label">Course Code</label>
                                                    <Tooltip placement="top" title="Unique Code" ><i className='text-danger ps-1 align-center bx bx-help-circle' /></Tooltip>
                                                    <input type="text" className="form-control" id='code' name="code" placeholder="Hint: A12DCA" />
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <label htmlFor="eligibility" className="form-label">Eligibility</label>
                                                <Tooltip placement="top" title="Matriculation | Intermediate | Graduation | Masters" ><i className='text-danger ps-1 align-center bx bx-help-circle' /></Tooltip>
                                                <select className='form-control' name='min_eligibility'>
                                                    {eligibility.map((p, id) => (
                                                        <option key={id} value={p.value}>{p.label}</option>
                                                    ))}
                                                </select>
                                            </Col>
                                            <Col xs={4}>
                                                <div>
                                                    <Tooltip placement="top" title="Year wise" ><label htmlFor="semesters" className="form-label">Total Semesters</label>
                                                        <i className='text-danger ps-1 align-center bx bx-help-circle' /></Tooltip>
                                                    <input type="number" className="form-control" id='semesters' name="semesters" />
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <div>
                                                    <Tooltip placement="top" title="Month wise" ><label htmlFor="sem_duration" className="form-label">sem-Duration</label>
                                                        <i className='text-danger ps-1 align-center bx bx-help-circle' /></Tooltip>
                                                    <input type="number" defaultValue="6" className="form-control" id='sem_duration' name="sem_duration" placeholder='Enter Course Duration' />
                                                </div>
                                            </Col>
                                            <hr className='mt-3' />
                                            <Col xs={12} className='my-3'><h5><u><strong>Create Fees</strong></u></h5></Col>
                                            <Col xs={12}>
                                                <table className='table table-bordered dt-responsive nowrap table-striped align-middle' >
                                                    <thead className='table-light text-center'>
                                                        <tr>
                                                            <th>Form</th>
                                                            <th>Registration</th>
                                                            <th>Course</th>
                                                            <th>Exam </th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='text-center'>
                                                        <tr>
                                                            <td><input name='form_fees' type="number" className='form-control border-0 bg-transparent text-center' defaultValue={formFee} onChange={(e) => setFormFee(Number(e.target.value))} /></td>
                                                            <td><input name='registration_fees' type="number" className='form-control border-0 bg-transparent text-center' defaultValue={registrationFee} onChange={(e) => setRegistrationFee(Number(e.target.value))} /></td>
                                                            <td><input name='total_tution_fees' type="number" className='form-control border-0 bg-transparent text-center' defaultValue={courseFee} onChange={(e) => setCourseFee(Number(e.target.value))} /></td>
                                                            <td><input name='exam_fees' type="number" className='form-control border-0 bg-transparent text-center' defaultValue={examFee} onChange={(e) => setExamFee(Number(e.target.value))} /></td>
                                                            <td><input type="number" className='form-control border-0 bg-transparent text-center' value={totalFee} readOnly /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Col>

                                            <Col xs={12} className='my-3'><h5><u><strong>Course Fee Discounts</strong></u></h5></Col>
                                            <Col xs={4}>
                                                <div>
                                                    <label htmlFor="" className="form-label">One Time Fees Deposit</label>
                                                    <div className="input-group mb-3">
                                                        <input name="one_time_discount" type="number" class="form-control" />
                                                        <span className="input-group-text">% Dis</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <div>
                                                    <label htmlFor="" className="form-label">Two Time Fees Deposit</label>
                                                    <div className="input-group mb-3">
                                                        <input name="two_time_discount" type="number" class="form-control" />
                                                        <span className="input-group-text">% Dis</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <div>
                                                    <label htmlFor="" className="form-label">Montly Fees Deposit</label>
                                                    <div className="input-group mb-3">
                                                        <input name="monthly_discount" type="number" class="form-control" />
                                                        <span className="input-group-text">% Dis</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={12}>
                                                <div className="d-flex align-items-start gap-3 mt-4">
                                                    <button type="submit" className="btn btn-primary btn-label right ms-auto nexttab nexttab" ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Submit</button>
                                                </div>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
