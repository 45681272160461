export const formatDate = (inputDate, format = null, delimiter = '-') => {
    if (format) {
        const date = new Date(inputDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        let formattedDate = format
            .replace('DD', day)
            .replace('MM', month)
            .replace('YYYY', year);
        formattedDate = formattedDate.split('-').join(delimiter);
        return formattedDate;
    }
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = new Date(inputDate).toLocaleDateString('en-US', options);
    return formattedDate;
};


export const isDate1BeforeOrEqual = (dateString1, dateString2) => {
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);

    return date2 <= date1;
}

export const timeFormat = (inputTime) => {
    const fixedDate = new Date(`2000-01-01T${inputTime}`);
    const hours = fixedDate.getHours();
    const minutes = fixedDate.getMinutes();
    const formattedHours = hours % 12 || 12;
    const period = hours < 12 ? 'AM' : 'PM';
    const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
    return formattedTime;
}

export const dateTimeSaparator = (dateString) => {
    if (!dateString) return {
        date: '',
        time: ''
    };
    const date = new Date(dateString);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const formattedDate = `${day} ${month}, ${year}`;
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}${ampm}`;
    return {
        date: formattedDate,
        time: formattedTime
    };
}

export const batchSetCode = (row) => {
    const date = new Date(row.announce_date);
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);
    const startTime = row.start_time; // Assume start_time is in HH:mm format
    const [hours, minutes] = startTime.split(':').map(Number);
    let shift = '';

    if (hours < 12) {
        shift = 'M'; // Morning
    } else if (hours < 18) {
        shift = 'A'; // Afternoon
    } else {
        shift = 'E'; // Evening
    }

    return shift + row.id + year + month;
}