import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import img from "../../helper/image_helper"
export const OurTeam = () => {
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };
    return (
        <>

            <section className="section bg-primary" id="teams"   style={{backgroundImage: `url(${img.teambg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center',}}>
                <h3 className=" fw-semibold text-center text-light mb-4">Our <span className="text-danger">Team</span></h3>
                <div className="bg-overlay bg-overlay-pattern"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="text-center">
                                <div className='col-lg-4 mx-auto'>
                                    <img src={img.profile} className="card-img-top rounded-circle"  alt="" />
                                </div>
                                <h4 className="text-white mt-2 mb-3"><span className="text-success">Director Desk</span></h4>


                                <div className="swiper client-review-swiper rounded" dir="ltr">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="row justify-content-center">
                                                <div className="col-10">
                                                    <div className="text-white">
                                                        <b>Dear Students,</b>
                                                        <p className="fs-20 ff-secondary">
                                                            The objectives of the S K Group of Institutions family are clear and the intentions are strong. In the present time the education system is changing. In which more emphasis is being laid on technical education. It is our endeavor that we train every student in such a way that they can get a good job or also create their self-employment on the strength of their skill and hard work. At present, in the name of preparation for one day examination in coaching etc., high fees are being charged and taught in the old system. We are trying to prepare according to the syllabus of every subject with new technical mediums and shortcut techniques.
                                                        </p>
                                                        <div className={showMore ? 'd-block' : 'd-none'}>
                                                            <p className="fs-20 ff-secondary">
                                                                Technical and computer education instills confidence in the person. And inspires you to do something on your own.
                                                            </p>
                                                            <p className="fs-20 ff-secondary">
                                                                SK Group of Institutions under SK Institute of Computer Science and Technology guarantees training and certified certification through expert trainers in computer education who can be employed in any part of the country in government or non-government jobs
                                                            </p>
                                                            <p className="fs-20 ff-secondary">
                                                                The team of enlightened mentors at SK Institute of One Day Exams fully assists the students in cracking the one day examinations.
                                                            </p>
                                                            <p className="fs-20 ff-secondary">
                                                                “All my students are family member of this institute. Whom selection yet proved own success.”
                                                            </p>
                                                            <p className="fs-20 ff-secondary">
                                                                Our mantra is based on the principle of “Sankalpit Rahe Aap Samarpit Rahe Ham”. Our dedication to your resolve is our core.
                                                            </p>
                                                        </div>
                                                        <Button color="primary" outline className="btn-end" onClick={toggleShowMore}>
                                                            {showMore ? 'View Less' : 'View More'}
                                                        </Button>

                                                        <div>
                                                            <h5 className="text-white">Mr. Girijesh Kumar Kesarwani</h5>
                                                            <p>- Director of SK Group of Institutions</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="swiper-slide">
                                            <div className="row justify-content-center">
                                                <div className="col-10">
                                                    <div className="text-white-50">
                                                        <p className="fs-20 ff-secondary mb-4">" Awesome support. Had few issues while setting up because of my device, the support team helped me fix them up in a day. Everything looks clean and good. Highly recommended! "</p>

                                                        <div>
                                                            <h5 className="text-white">GeekyGreenOwl</h5>
                                                            <p>- Skote User</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="swiper-slide">
                                            <div className="row justify-content-center">
                                                <div className="col-10">
                                                    <div className="text-white-50">
                                                        <p className="fs-20 ff-secondary mb-4">" Amazing template, Redux store and components is nicely designed. It's a great start point for an admin based project. Clean Code and good documentation. Template is completely in React and absolutely no usage of jQuery "</p>

                                                        <div>
                                                            <h5 className="text-white">sreeks456</h5>
                                                            <p>- Veltrix User</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="swiper-button-next bg-white rounded-circle"></div>
                                    <div className="swiper-button-prev bg-white rounded-circle"></div>
                                    <div className="swiper-pagination position-relative mt-2"></div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </section>
        </>
    )
}
