import React, { useState } from 'react';
import { Card, CardHeader, Modal } from 'react-bootstrap';
import { course, exam } from '../../../helper/api_url';
import { usePageInitialtor, useModalHandler } from '../../../helper/custom_hook';
import { subject, allChapters } from '../../../helper/examData';
import CustomSelect from '../../../components/CustomSelect';
import { toast } from 'react-toastify';
import { swal } from '../../../helper/swal';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import * as XLSX from 'xlsx';

export function CreateQuestionModal({ setTableData, tableData }) {
    const dispatch = useDispatch();
    const { toggleModal, status } = useModalHandler();
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [chapters, setChapters] = useState([]);
    const [excelData, setExcelData] = useState(null);

    const handleSubjectChange = (e) => {
        setSelectedSubject(e.value);
        setChapters(allChapters[e.value] || []);
    };

    const handleAnswerChange = (e) => {
        setCorrectAnswer(e.target.value);
    };

    const [options, setOptions] = useState({
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOptions({ ...options, [name]: value });
    };

    const handleAddQuestion = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const optionsArray = Object.entries(options).map(([key, value]) => ({
            option_text: value,
            is_answer: key === correctAnswer
        }));
        optionsArray.forEach(option => {
            formData.append('options[]', JSON.stringify(option));
        });
        if (!correctAnswer) {
            swal.error("Please select the correct answer.");
            return;
        } else {
            dispatch(setPreloader({ loader: true, message: 'please wait...' }))

            exam.create_question(formData).then(res => {
                setTableData(state => [res.data.question, ...state]);
                toast.success(res.message);
                e.target.reset();
                setOptions({ optionA: '', optionB: '', optionC: '', optionD: '' });
                setCorrectAnswer('');
            })
                .catch(err => swal.error(err.response ? err.response.data.message : err.message))
                .finally(() => dispatch(setPreloader({ loader: false, message: '' })))
        }

    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheetName], { header: 1 });
            setExcelData(worksheet);
        };
        reader.readAsBinaryString(file);
    };

    const handleAddFromExcel = () => {
        if (!excelData) {
            swal.error("Please upload an Excel file.");
            return;
        }

        const formData = new FormData();
        const questions = excelData.slice(1).map((row) => {
            if (row.length < 7) return null; // skip incomplete rows
            const [Subject, Question, optionA, optionB, optionC, optionD, correct] = row;
            const optionsArray = [
                { option_text: optionA, is_answer: correct === 'A' },
                { option_text: optionB, is_answer: correct === 'B' },
                { option_text: optionC, is_answer: correct === 'C' },
                { option_text: optionD, is_answer: correct === 'D' },
            ];
        
            return {
                type: 'question',
                subject: Subject,
                instruction: Question,
                options: optionsArray,
            };
        }).filter(item => item !== null);
        
        formData.append('data', JSON.stringify(questions));
        exam.create_question(formData).then(res => {
            setTableData(state => [...res.data.question, ...state]);
            toast.success(res.message);
            setExcelData(null);
        })
            .catch(err => swal.error(err.response ? err.response.data.message : err.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: '' })))
    };

    return (
        <>
            <div className="col-3 d-none d-lg-block">
                Upload Excel File:
                <div class="input-group">
                    <input type="file" class="form-control" accept='.xls,.xlsx' onClick={handleFileUpload}/>
                    <button type="button" className="input-group-text btn btn-primary" onClick={handleAddFromExcel}>Save</button>
                </div>

            </div>
            <button onClick={toggleModal} className='btn btn-soft-success add-btn waves-effect'>
                <i className="ri-add-line align-bottom me-1"></i>
                <span>Add Question</span>
            </button>
            <Modal className="fade modal-lg" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Create Question</h5></Modal.Title>
                </Modal.Header>
                <form onSubmit={handleAddQuestion}>
                    <Modal.Body >
                        <div className="row g-3">
                            <div className="col-12">
                                <input type="hidden" className="form-control" name="type" value="question" />
                            </div>
                            <div className="col-6">
                                <label htmlFor="subject" className="form-label">Subject:</label>
                                <CustomSelect options={subject} isSearchable name='subject' onChange={handleSubjectChange} />
                            </div>
                            <div className="col-6">
                                <label htmlFor="subject" className="form-label">Chapter:</label>
                                <CustomSelect options={chapters} isSearchable name='chapter' />
                            </div>
                            <div className="col-12">
                                <label htmlFor="instruction" className="form-label">Question:</label>
                                <textarea name="instruction" id="instruction" rows={2} className="form-control"></textarea>
                            </div>
                            <div className="col-4">
                                <label htmlFor="image_video" className="form-label">Image: (Optional)</label>
                            </div>
                            <div className="col-6">
                                <input type="file" name="image_video" id="image_video" />
                            </div>
                            <div className="col-6">
                                <div className="form-check form-radio-success mb-1">
                                    <input type="radio" name="correctAnswer" value="optionA" className="form-check-input" checked={correctAnswer === 'optionA'} onChange={handleAnswerChange} id="ansA" />
                                    <label className="form-check-label" htmlFor="ansA">Option A</label>
                                </div>
                                <input type="text" className="form-control" id="optionA" name="optionA" value={options.optionA} onChange={handleInputChange} />
                            </div>
                            <div className="col-6">
                                <div className="form-check form-radio-success mb-1">
                                    <input type="radio" name="correctAnswer" value="optionB" className="form-check-input" checked={correctAnswer === 'optionB'} onChange={handleAnswerChange} id="ansB" />
                                    <label className="form-check-label" htmlFor="ansB">Option B</label>
                                </div>
                                <input type="text" className="form-control" id="optionB" name="optionB" value={options.optionB} onChange={handleInputChange} />
                            </div>
                            <div className="col-6">
                                <div className="form-check form-radio-success mb-1">
                                    <input type="radio" name="correctAnswer" value="optionC" className="form-check-input" checked={correctAnswer === 'optionC'} onChange={handleAnswerChange} id="ansC" />
                                    <label className="form-check-label" htmlFor="ansC">Option C</label>
                                </div>
                                <input type="text" className="form-control" id="optionC" name="optionC" value={options.optionC} onChange={handleInputChange} />
                            </div>
                            <div className="col-6">
                                <div className="form-check form-radio-success mb-1">
                                    <input type="radio" name="correctAnswer" value="optionD" className="form-check-input" checked={correctAnswer === 'optionD'} onChange={handleAnswerChange} id="ansD" />
                                    <label className="form-check-label" htmlFor="ansD">Option D</label>
                                </div>
                                <input type="text" className="form-control" id="optionD" name="optionD" value={options.optionD} onChange={handleInputChange} />
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                            <button type="submit" className="btn btn-primary">Add</button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
