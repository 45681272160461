import React, { useState } from 'react';
import { Card, CardHeader, Modal } from 'react-bootstrap';
import { course, exam } from '../../../helper/api_url';
import { usePageInitialtor, useModalHandler } from '../../../helper/custom_hook';
import { AssignmentSubject, AssignmentName } from '../../../helper/examData';
import CustomSelect from '../../../components/CustomSelect';
import { toast } from 'react-toastify';
import { swal } from '../../../helper/swal';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';


export function CreateAssignmentModal({ setTableData, tableData }) {
    const dispatch = useDispatch();
    const { toggleModal, status } = useModalHandler();
    const handleAddAssignment = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

            dispatch(setPreloader({ loader: true, message: 'please wait...' }))

            exam.create_assignments(formData).then(res => {
                setTableData(state => [res.data.assignment, ...state]);
                toast.success(res.message);
                e.target.reset();
            })
                .catch(err => swal.error(err.response ? err.response.data.message : err.message))
                .finally(() => dispatch(setPreloader({ loader: false, message: '' })))
    };


    return (
        <>
            <button onClick={toggleModal} className='btn btn-soft-success add-btn waves-effect'>
                <i className="ri-add-line align-bottom me-1"></i>
                <span>Add Assignment</span>
            </button>
            <Modal className="fade modal-lg" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Create Assignment</h5></Modal.Title>
                </Modal.Header>
                <form onSubmit={handleAddAssignment}>
                    <Modal.Body >
                        <div className="row g-3">
                            <div className="col-12">
                                <input type="hidden" className="form-control" name="type" value="assignment" />
                            </div>
                            <div className="col-6">
                                <label htmlFor="subject" className="form-label">Subject</label>
                                <CustomSelect options={AssignmentSubject} isSearchable name='subject' />
                            </div>
                            <div className="col-6">
                                <label htmlFor="chapter" className="form-label">Assignment Name</label>
                                <CustomSelect options={AssignmentName} isSearchable name='chapter' />
                            </div>
                            <div className="col-12">
                                <label htmlFor="instruction" className="form-label">Question:</label>
                                <textarea name="instruction" id="instruction" rows={2} className="form-control"></textarea>
                            </div>
                            <div className="col-4">
                                <label htmlFor="image_video" className="form-label">Image: (Optional)</label>
                            </div>
                            <div className="col-6">
                                <input type="file" class="form-control"  name="image_video" id="image_video" />
                            </div>
                            <div className='row mt-3'>
                                <div className='col-3'><label htmlFor="assignmentfile" className="form-label">Upload Assignment</label></div>
                                <div className='col-5'>
                                    <div class="input-group">
                                        <input type="file" name="assignment_file" id='assignmentfile' class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                            <button type="submit" className="btn btn-primary">Add</button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
