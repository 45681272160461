import React from "react";
import BreadCrumb from "../../components/common/BreadCrumb";
import { Card, CardBody, Row,Col } from "react-bootstrap";

function Locked() {
    return (
        <>
            <BreadCrumb title="Locked" />
            <Card className="border border-primary">
                <Card.Body className="bg-soft-light">
                    <div style={{height:'300px'}} className="d-flex flex-column align-items-center justify-content-center">
                    <lord-icon
                        src="https://cdn.lordicon.com/usownftb.json"
                        trigger="loop"
                        state="loop-oscillate"
                        colors="primary:#405189,secondary:#f06548"
                        style={{width:"112px",height:"112px"}}>
                    </lord-icon>
                        <Card.Title className="mt-3"><span className="badge badge-soft-danger fs-16">This Module is Locked</span></Card.Title>
                        <Card.Text className="mt-3"><button className="btn btn-soft-success"> <i className=" ri-lock-unlock-line me-2"></i>Unlock Now</button></Card.Text>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default Locked