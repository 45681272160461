import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { ASSET_URL, enquiry } from '../../../helper/api_url';
import image_helper from '../../../helper/image_helper';
import {formatDate} from '../../../helper/formatDate';
import QRCode from 'react-qr-code';
import { useLocation } from 'react-router-dom';

function EnquirySlip() {    
    const {state} = useLocation();
    const [enquiryData,setEnquiryData] = useState(null);
    const id = state && state.id;
    useEffect(()=>{
        enquiry.get(id).then(res=>{
            setEnquiryData(res.data.enquiry);
            console.log(enquiryData);
        }).catch(e=>console.error(e));
    },[]);
    const handleTryalClass = enquiry => {
        if(enquiry){
            const trial_end = enquiry.tryal_date != null ? new Date(enquiry?.joining_date) : null;
            if (trial_end != null) trial_end.setDate(trial_end.getDate() + enquiry.tryal_period);
            return (trial_end != null)? formatDate(trial_end): 'not opted';
        }
        return '--';
    }
    return (
        <>
            <Row>
                <Col xs={12} className='d-flex justify-content-center'>
                    <Card style={{maxWidth:'1100px'}}>
                        <Card.Header>
                            <Card.Title className='text-center fs-4'><span style={{paddingRight:'80px'}}><i className='ri-record-circle-fill' /></span><span  style={{paddingLeft:'80px'}}><i className='ri-record-circle-fill' /></span></Card.Title>
                        </Card.Header>
                        {/* <Card.Body>
                            <Row>
                                <Col xs={12} className='mt-3 mb-5 d-flex align-items-center justify-content-between'>
                                    <div><span className='fw-bold'>Date :</span><span className='mx-1'>{formatDate(enquiryData?.created_at)}</span></div>
                                    <div><span className='fw-bold'>Enquiry Id :</span><span className='mx-1'>#SKENQ{enquiryData?.id}</span></div>
                                </Col>
                                <Col xs={6} className='mb-4 d-flex'> 
                                    <span className='fw-bold text-nowrap' style={{whiteSpace:'nowrap'}} >Candidate Name : </span> 
                                    <span className='border-bottom px-4 w-100'>{enquiryData?.name}</span>
                                </Col>
                                <Col xs={6} className='mb-4 d-flex'>
                                    <span className='fw-bold text-nowrap' style={{whiteSpace:'nowrap'}}>Father's Name : </span> 
                                    <span className='border-bottom px-4 w-100'>{enquiryData?.father_name}</span>
                                </Col>

                                <Col xs={6} className='mb-4 d-flex'>
                                    <span className='fw-bold text-nowrap' style={{whiteSpace:'nowrap'}}>Enquiry Course : </span> 
                                    <span className='border-bottom px-4 w-100'>{enquiryData?.course?.name}</span>
                                </Col>
                                <Col xs={6} className='mb-4 d-flex'>
                                    <span className='fw-bold text-nowrap' style={{whiteSpace:'nowrap'}}>Candidate Qualification : </span> 
                                    <span className='border-bottom px-4 w-100'>{enquiryData?.qualification}</span>
                                </Col>

                                <Col xs={6} className='mb-4 d-flex'>
                                    <span className='fw-bold text-nowrap' style={{whiteSpace:'nowrap'}}>Mobile Number : </span> 
                                    <span className='border-bottom px-4 w-100'>+91 {enquiryData?.phone}</span>
                                </Col>
                                <Col xs={6} className='mb-4 d-flex'>
                                    <span className='fw-bold text-nowrap' style={{whiteSpace:'nowrap'}}>Whatsapp Number : </span> 
                                    <span className='border-bottom px-4 w-100'>+91 {enquiryData?.whatsapp}</span>
                                </Col>
                                
                                <Col xs={6} className='mb-4 d-flex'>
                                    <span className='fw-bold text-nowrap' style={{whiteSpace:'nowrap'}} >Address : </span> 
                                    <span className='border-bottom px-4 w-100'>{enquiryData?.address_1}</span>
                                </Col>
                                <Col xs={6} className='mb-4 d-flex'>
                                    <span className='fw-bold text-nowrap' style={{whiteSpace:'nowrap'}} >District : </span> 
                                    <span className='border-bottom px-4 w-100'>{enquiryData?.district}</span>
                                </Col>
                                <Col xs={6} className='mb-4 d-flex'>
                                    <span className='fw-bold text-nowrap' style={{whiteSpace:'nowrap'}} >State : </span> 
                                    <span className='border-bottom px-4 w-100'>{enquiryData?.state}</span>
                                </Col>
                                <Col xs={6} className='mb-4 d-flex'>
                                    <span className='fw-bold text-nowrap' style={{whiteSpace:'nowrap'}} >Pin : </span> 
                                    <span className='border-bottom px-4 w-100'>{enquiryData?.pin}</span>
                                </Col>
                                <Col xs={6} className='mb-4 d-flex'>
                                    <span className='fw-bold text-nowrap' style={{whiteSpace:'nowrap'}} >Enquiry By: </span> 
                                    <span className='border-bottom px-4 w-100'>{enquiryData?.created_by?.first_name} {enquiryData?.created_by?.last_name}</span>
                                </Col>
                                <Col xs={6} className='mb-4 d-flex'>
                                    <span className='fw-bold text-nowrap' style={{whiteSpace:'nowrap'}} >Trail End: </span> 
                                    <span className='border-bottom px-4 w-100'>{handleTryalClass(enquiryData)}</span>
                                </Col>
                            </Row>
                        </Card.Body> */}
                         <Card.Body>
                            <Row>
                                <Col xs={12}>        
                                    <table className="table dt-responsive nowrap table-striped align-middle">
                                        <tbody style={{fontSize:'12px'}}>
                                            <tr>
                                                <th colSpan={2}><span className='fw-bold'>Date :</span><span className='mx-1'>{formatDate(enquiryData?.created_at)}</span></th>
                                                <th colSpan={2} className='text-left'> <Card.Title className='text-center fs-4'>Enquiry Slip</Card.Title></th>
                                                <th colSpan={2} className='text-end'><span className='fw-bold'>Enquiry Id :</span><span className='mx-1'>#SKENQ{enquiryData?.id}</span></th>
                                            </tr>
                                            <tr>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap'  > Name : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span>{enquiryData?.name}</span>
                                                </td>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >Qualification : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span>{enquiryData?.qualification}</span>
                                                </td>
                                                <td rowSpan={3} colSpan={2} className='p-0 border border-top-0'>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        <img src={ASSET_URL + enquiryData?.avatar} style={{aspectRatio:'1/1',width:'116px',objectFit:'cover'}} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap'  >Father's Name : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span>{enquiryData?.father_name}</span>                                                   
                                                </td>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >Enquiry Course : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span>{enquiryData?.course?.name}</span>
                                                </td>
                                               
                                                
                                            </tr>
                                            <tr>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >Mobile : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span>+91 {enquiryData?.phone}</span>
                                                </td>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >Whatsapp : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span>+91 {enquiryData?.whatsapp}</span>
                                                </td>
                                                
                                                
                                            </tr>
                                            <tr>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap'>Address : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span>{enquiryData?.address_1}</span>
                                                </td>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >District : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span >{enquiryData?.district}</span>
                                                </td>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >State : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span >{enquiryData?.state} ({enquiryData?.pin})</span>
                                                </td>
                                                
                                            </tr>
                                            <tr>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap'>Enquiry Date : </span> 
                                                </td>
                                                <td className='py-2' >
                                                    <span>{formatDate(enquiryData?.created_at)}</span>
                                                </td>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >Trail Start : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span >{formatDate(enquiryData?.joining_date)}</span>
                                                </td>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >Trail End : </span> 
                                                </td>
                                                <td className='py-2' >
                                                    <span >{handleTryalClass(enquiryData)}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                                <Col xs={6} className='my-2 px-4 d-flex'> 
                                    <span className='fw-bold text-nowrap' >Enquiry By : </span> 
                                    <span className='border-bottom px-4 w-100'>{enquiryData?.created_by?.first_name} {enquiryData?.created_by?.last_name}</span>
                                </Col>
                                <Col xs={6} className='my-2 px-4 d-flex'> 
                                    <span className='fw-bold text-nowrap' >Preffered Batch:  </span> 
                                    <span className='border-bottom px-4 w-100'>{enquiryData?.preffered_batch?.charAt(0).toUpperCase()+ enquiryData?.preffered_batch?.slice(1).toLowerCase()}</span>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <div className='d-flex align-items-center d-lg-none'>
                    <i className='ri-scissors-cut-line text-muted'></i>
                    <hr className='w-100' style={{borderTop: 'dotted 1px'}}/>
                    <i className='ri-scissors-cut-line text-muted' style={{transform:'rotate(180deg)'}}></i>
                </div>
                <Col xs={12} className='mt-1 d-flex justify-content-center'>
                    <Card className='border' style={{maxWidth:'1100px'}}>
                        <Card.Header className='d-flex flex-column align-items-center justify-content-center  pt-3'>
                            <div>
                                <img src={image_helper.logo} className="card-logo card-logo-dark" alt="logo dark" height="38" />
                                <img src={image_helper.logo} className="card-logo card-logo-light" alt="logo light" height="38" />
                            </div>
                                <Card.Title className='mt-1 fw-bold'>Kotwan Road Hanumanganj Prayagraj</Card.Title>
                                <span className=' text-nowrap' ><i className=' ri-phone-line ps-1'/>+91 8840532253 | <i className='ri-whatsapp-line ps-1'/>+91 9453360943 </span> 
                                <span className=' text-nowrap' ><i className='ri-global-line'/> www.skicst.org</span> 
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs={12}>        
                                    <table className="table dt-responsive nowrap table-striped align-middle">
                                        <tbody style={{fontSize:'12px'}}>
                                            <tr>
                                                <th colSpan={6} ><Card.Title className='text-center fs-4'>Trail Slip</Card.Title></th>
                                            </tr>
                                            <tr>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap'  > Name : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span>{enquiryData?.name}</span>
                                                </td>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >Qualification : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span>{enquiryData?.qualification}</span>
                                                </td>
                                                <td rowSpan={3} colSpan={2} className='p-0 border border-top-0'>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        <img src={ASSET_URL + enquiryData?.avatar} style={{aspectRatio:'1/1',width:'116px',objectFit:'cover'}} />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap'  >Father's Name : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span>{enquiryData?.father_name}</span>                                                   
                                                </td>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >Enquiry Course : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span>{enquiryData?.course?.name}</span>
                                                </td>
                                               
                                                
                                            </tr>
                                            <tr>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >Mobile : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span>+91 {enquiryData?.phone}</span>
                                                </td>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >Whatsapp : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span>+91 {enquiryData?.whatsapp}</span>
                                                </td>
                                                
                                                
                                            </tr>
                                            <tr>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap'>Address : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span>{enquiryData?.address_1}</span>
                                                </td>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >District : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span >{enquiryData?.district}</span>
                                                </td>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >State : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span >{enquiryData?.state} ({enquiryData?.pin})</span>
                                                </td>
                                                
                                            </tr>
                                            <tr>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap'>Enquiry Date : </span> 
                                                </td>
                                                <td className='py-2' >
                                                    <span>{formatDate(enquiryData?.created_at)}</span>
                                                </td>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >Trail Start : </span> 
                                                </td>
                                                <td className='py-2'>
                                                    <span >{formatDate(enquiryData?.joining_date)}</span>
                                                </td>
                                                <td className='py-2'>
                                                    <span className='fw-bold text-nowrap' >Trail End : </span> 
                                                </td>
                                                <td className='py-2' >
                                                    <span >{handleTryalClass(enquiryData)}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                                <Col xs={6} className='my-4 px-4 d-flex'> 
                                    <span className='fw-bold text-nowrap' >Enquiry By : </span> 
                                    <span className='border-bottom px-4 w-100'>{enquiryData?.created_by?.first_name} {enquiryData?.created_by?.last_name}</span>
                                </Col>
                                <Col xs={6} className='my-2 px-4 d-flex'> 
                                    <span className='fw-bold text-nowrap' >Preffered Batch:  </span> 
                                    <span className='border-bottom px-4 w-100'>{enquiryData?.preffered_batch?.charAt(0).toUpperCase()+ enquiryData?.preffered_batch?.slice(1).toLowerCase()}</span>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} className=''>
                    <div className="hstack gap-2 justify-content-center d-print-none mt-4">
                        <a href="javascript:window.print()" className="btn btn-success  px-5"><i className="ri-printer-line align-bottom me-1"></i> Print</a>
                    </div>
                </Col>
            </Row>  
        </>
    )
}  
export default EnquirySlip