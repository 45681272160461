import React, { useEffect, useMemo, useState } from 'react'
import { ASSET_URL, fee, transaction } from '../../../helper/api_url'
import BreadCrumb from '../../../components/common/BreadCrumb'
import { Card, CardBody, CardHeader, Col, Row } from "react-bootstrap"
import { TableResponsive } from '../../../components/common/TableResponsive'
import { formatDate } from '../../../helper/formatDate'
import PaymentFormModal from './PaymentFormModal'


function Index() {
    const [tableData,setTableData] = useState([]);
    const [todayTransactions,setTodayTransactions] = useState(null);
    const [todayInvoices,setTodayInvoices] = useState(null);
    useEffect(()=>{
        fee.studentDuesList().then(res=>setTableData(res.data.students)).catch(e=>console.error(e));
        transaction.today().then(res=>{
            setTodayTransactions(res.data.transactions);
            setTodayInvoices(res.data.invoices);
        }).catch(e=>console.error(e));
    },[]);
    useEffect(()=>{
        if(todayTransactions)
            console.log(todayInvoices)
    },[todayTransactions]);
    const columns = useMemo(()=>[
        {
            Header: "User",
            HeaderClass:'text-center',
            accessor: "first_name",
            Cell:(cell)=> {
                const imageUrl = ASSET_URL + cell.row.original.avatar;
                return (<span> <img className="me-2 rounded-circle header-profile-user" style={{objectFit:'cover'}} src={imageUrl} alt="User Avatar" />{cell.row.original.first_name} {cell.row.original.last_name}</span>)
            }
        },
        {Header: "Email",HeaderClass:'text-center', DataClass:'text-center', accessor: "email"},
        {Header: "Phone",HeaderClass:'text-center', DataClass:'text-center', accessor: "phone"},
        {
            Header: "Total Dues",HeaderClass:'text-center', DataClass:'text-center',
            Cell:cell=>{
                const row = cell.row.original;
                return <span>&#8377; {(row.fees?.reduce((total, fee) => total +  (parseFloat(fee.amount) * parseInt(fee.total_installments)) - parseFloat(fee.pivot.paid_amt) - (fee.pivot.discount/100 * (parseFloat(fee.amount) * parseInt(fee.total_installments))), 0)).toFixed(2)}</span>;
            }
        },
        {
            Header: "Action",HeaderClass:'text-center', DataClass:'text-center',
            Cell:cell=>{
                const row = cell.row.original;
                return (<div>
                    <PaymentFormModal setTableData={setTableData} data={row} />
                    <button className='btn btn-sm btn-soft-info me-1'><i className="ri-eye-fill" /></button>
                </div>)
            }
        },

    ]);
    return (
        <>
            <BreadCrumb title="Fees Management" prevPage="Home" prevPath="/dashboard" />  
            <Row>
                <Col lg={3} sm={6} xs={12}>
                    <Card>
                        <Card.Body>
                            <h4>Total Dues</h4>
                            <h2>&#8377;  
                                {tableData.reduce((t,row)=>
                                t + (row.fees?.reduce((total, fee) => 
                                    total +  
                                    (parseFloat(fee.amount) * 
                                    parseInt(fee.total_installments)) - 
                                    parseFloat(fee.pivot.paid_amt) - 
                                    (fee.pivot.discount/100 * 
                                    (parseFloat(fee.amount) * 
                                    parseInt(fee.total_installments))), 0)),
                                0).toFixed(2)} /-
                            </h2>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} sm={6} xs={12}>
                    <Card>
                        <Card.Body>
                            <h4>Today's Collection</h4>
                            <h2>&#8377;  {todayInvoices?.reduce((total,inv)=>total+inv?.transactions?.reduce((t,tr)=>t+parseFloat(tr.paidamt),0),0).toFixed(2)}</h2>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} sm={6} xs={12}>
                    <Card>
                        <Card.Body>
                            <h4>Collection in Cash</h4>
                            <h2>&#8377;  {todayInvoices?.filter(tinv=>tinv.payment_mode === 'cash')?.reduce((total,inv)=>total+inv?.transactions?.reduce((t,tr)=>t+parseFloat(tr.paidamt),0),0).toFixed(2)}</h2>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} sm={6} xs={12}>
                    <Card>
                        <Card.Body>
                            <h4>Online Collection</h4>
                            <h2>&#8377;  {todayInvoices?.filter(tinv=>tinv.payment_mode !== 'cash')?.reduce((total,inv)=>total+inv?.transactions?.reduce((t,tr)=>t+parseFloat(tr.paidamt),0),0).toFixed(2)}</h2>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Dues List</h5>
                            {/* <NewFeeModal apiHandler={apiHandler} /> */}
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive columns={columns} data={tableData} noRecord="No Records in Dues List" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Index