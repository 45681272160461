import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Card, CardHeader, CardBody, Modal, Button, Nav, Tab } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { swal } from '../../../helper/swal';
import Camera from '../../../components/common/Camera';
import { setPreloader } from "../../../features/Ui/uiSlice";
import BreadCrumb from '../../../components/common/BreadCrumb'
import { useModalHandler, useWebCam } from '../../../helper/custom_hook';
import { ASSET_URL, student, enquiry } from '../../../helper/api_url';
import CustomSelect from "../../../components/CustomSelect";
import dummyUser from "../../../assets/images/users/user-dummy-img.jpg"
import { OtherDetail } from "./StepForm/Otherdetail";
import { AddressDetail } from "./StepForm/AddressDetail";
import { QualificationDetail } from "./StepForm/QualificationDetail";
import { EnrollementDetail } from "./StepForm/EnrollementDetail";
import { toast } from "react-toastify";
function Admission() {
  const dispatch = useDispatch();
  const {state} = useLocation();
  const admission_id = state && state.id;
  const { genders, status, toggleModal, userProfile, setUserProfile } = useModalHandler();
  const [currentGeneratedStudentId, setStudentId] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const { webcam, toggleWebcam, setCapturedImageToInput } = useWebCam(setUserProfile);
  const [enquiryData, setEnquiryData] = useState();
  const [editAdmission,setEditAdmission]=useState();
  const onSave = f => { setCapturedImageToInput(f, document.getElementById('profile-img-file-input')) }
  const handleSubmit = e => {
    dispatch(setPreloader({ loader: true, message: 'please wait...' }))
    e.preventDefault();
    const formData = new FormData(e.target);
    student.add(formData).then(res => {
      setStudentId(res.data.student.id);
      toast.success("Success create", res.message);
      setActiveStep(2);
      document.getElementById('activeStep1').onclick=() => setActiveStep(1);
      document.getElementById('activeStep2').onclick=() => setActiveStep(2);
      // e.target.reset();
      setUserProfile();
      setEnquiryData([]);
    })
      .catch(err => swal.error(err.response ? err.response.data.message : err.message))
      .finally(() => dispatch(setPreloader({ loader: false, message: '' })))
  }

  const [findEnquiry, setFindEnquiry] = useState([]);
  useEffect(() => {
    enquiry.list().then(r => setFindEnquiry(r.data.enquiry.map((enquiry) => ({ label: enquiry.name + ' (' + enquiry.father_name + ') ' + enquiry.phone, value: enquiry.id, }))))
      .catch(e => console.log(e));
    if(admission_id){
      student.profile(admission_id).then(r =>{setEditAdmission(r.data.profile);setUserProfile(ASSET_URL + r.data.profile.avatar)}).catch(e => console.log(e));
      
    }  
  }, [admission_id])
  const fetchEnquiryData = (e) => {
    e.preventDefault();
    const formdata = new FormData(e.target);
    enquiry.fetchSingle(formdata.get('enquiry_id') || '0').then(r => {
      setEnquiryData(r.data.enquiry);
      setUserProfile(ASSET_URL + r.data.enquiry.avatar);
      toggleModal();
    }).catch(err => { swal.error(err.response ? err.response.data.message : err.message); })
  }
  
  return (
    <>
      {/* <BreadCrumb title="Addmission" prevPage="Home" prevPath="/dashboard" /> */}
      <Modal className="fade" centered={true} backdrop="static" show={admission_id?status:!status} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title><h5>Fetch Enquiry</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <form onSubmit={(e) => fetchEnquiryData(e)}>
            <div className="row g-3">
              <div className="col-12">
                <div>
                  <label htmlFor="enquiry_id" className="form-label">Enquiry Id</label>
                  <CustomSelect name="enquiry_id" isSearchable id='enquiry_id' placeholder="Select Enquiry" options={findEnquiry} />
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-3">
              <div className="hstack gap-2 justify-content-end">
                <button type="submit" className="btn btn-primary">Next</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between">
              <h5 className="card-title mb-0">{admission_id?"Edit Addmission":"New Addmission "}</h5>
              <div>
                <Link to="/admission" className='btn btn-soft-dark add-btn waves-effect me-2'><i className="ri-contacts-fill me-2"></i>Back</Link>
               {admission_id?'': <button onClick={() => toggleModal()} className='btn btn-soft-success add-btn waves-effect'><i className="ri-add-line align-bottom me-1"></i><span>Fetch Enquiry</span></button>}
              </div>
            </CardHeader>

            <CardBody className="" >

              <Row className="g-3 mx-lg-5">
                <div id="custom-progress-bar" className="progress-nav mb-4 mx-0">
                  <div className="progress" style={{ height: "1px" }}>
                    <div className="progress-bar" role="progressbar" style={{ width: "0%" }}  aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <Nav variant="pills" className="px-2">
                    <Nav.Item ><Nav.Link className={`rounded-pill d-flex justify-content-center align-items-center ${activeStep === 1 ? 'active' : ''}`} onClick={()=>admission_id?setActiveStep(1):''} id="activeStep1">1</Nav.Link></Nav.Item>
                    <Nav.Item > <Nav.Link className={`rounded-pill d-flex justify-content-center align-items-center ${activeStep === 2 ? 'active' : ''}`} onClick={()=>admission_id?setActiveStep(2):''} id="activeStep2">2</Nav.Link></Nav.Item>
                    <Nav.Item > <Nav.Link className={`rounded-pill d-flex justify-content-center align-items-center ${activeStep === 3 ? 'active' : ''}`} onClick={()=>admission_id?setActiveStep(3):''} id="activeStep3">3</Nav.Link></Nav.Item>
                    <Nav.Item > <Nav.Link className={`rounded-pill d-flex justify-content-center align-items-center ${activeStep === 4 ? 'active' : ''}`} onClick={()=>admission_id?setActiveStep(4):''} id="activeStep4">4</Nav.Link></Nav.Item>
                    <Nav.Item > <Nav.Link className={`rounded-pill d-flex justify-content-center align-items-center ${activeStep === 5 ? 'active' : ''}`} onClick={()=>admission_id?setActiveStep(5):''} id="activeStep5">5</Nav.Link></Nav.Item>
                  </Nav>
                </div>
                <Tab.Container activeKey={activeStep}>
                  <Tab.Content>
                    <Tab.Pane eventKey={1}>
                      <div className="" id="">
                        <div className="mb-4">
                          <h5 className="mb-1 text-center text-primary">Personal Information</h5>
                        </div>
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col className="text-center">
                              <div className="profile-user position-relative d-inline-block mx-auto mb-2">
                                <label htmlFor="profile-img-file-input" className="cursor-pointer"> <img src={userProfile ? userProfile : dummyUser} htmlFor="profile-img-file-input" className="rounded-circle avatar-lg img-thumbnail user-profile-image cursor" alt="" /></label>
                                <div className="avatar-xs ms-0 me-5 mb-2 rounded-circle profile-photo-edit" style={{ marginLeft: "31px" }}>
                                  <input id="profile-img-file-input" type="file" name="avatar" className="profile-img-file-input" accept="image/png, image/jpeg, image/jpg" onChange={(e) => onSave(e.target.files[0])} defaultValue="" />
                                </div>
                                <div className="avatar-xs rounded-circle profile-photo-edit">
                                  <div onClick={toggleWebcam} className="avatar-title rounded-circle bg-light text-body"><i className="ri-camera-fill"></i></div>
                                </div>
                              </div>
                              {webcam ? (<div className='position-relative top-0 bottom-0 d-flex justify-content-center align-items-center start-0 end-0 modal-content border-0' style={{ zIndex: 9 }}><Camera onSave={f => { onSave(f); toggleWebcam() }} onClose={() => toggleWebcam()} /></div>) : null}
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <div className="col-6 mb-2">
                              <input type="hidden" name="enquiry_avatar" defaultValue={enquiryData ? enquiryData.avatar : null} />
                              <input type="hidden" name="enquiry_id" defaultValue={enquiryData ? enquiryData.id : null} />
                              <div>
                                <label htmlFor="firstName" className="form-label">First Name <span className="text-danger px-1">*</span></label>
                                <input type="text" className="form-control" id='firstName' name="first_name" defaultValue={enquiryData? enquiryData.name : '' || editAdmission?.id?editAdmission.first_name:''} />
                              </div>
                            </div>
                            <div className="col-6 mb-2">
                              <div>
                                <label htmlFor="last_name" className="form-label">Last Name <span className="text-danger px-1">*</span></label>
                                <input type="text" className="form-control" id='last_name' defaultValue={editAdmission?.id?editAdmission.last_name:''} name="last_name" />
                              </div>
                            </div>
                            <div className="col-6 mb-2">
                              <div>
                                <label htmlFor="gender" className="form-label">Gender <span className="text-danger px-1">*</span></label>
                                <CustomSelect name='gender' id="gender" defaultValue={{ value: enquiryData?.gender, label: enquiryData?.gender }} options={genders} />
                              </div>
                            </div>
                            <div className="col-6 mb-2">
                              <div>
                                <label htmlFor="dob" className="form-label">Date of Birth <span className="text-danger px-1">*</span></label>
                                <input type="date" className="form-control" id='dob' name="dob" defaultValue={editAdmission?.id?editAdmission.dob:''} />
                              </div>
                            </div>
                            <div className="col-6 mb-2">
                              <div>
                                <label htmlFor="phone" className="form-label">Phone <span className="text-danger px-1">*</span></label>
                                <input type="text" className="form-control" id='phone' name="phone" defaultValue={enquiryData ? enquiryData?.phone : ''|| editAdmission?.id?editAdmission?.phone:''}  disabled={editAdmission?.id}/>
                              </div>
                            </div>
                            <div className="col-6 mb-2">
                              <div>
                                <label htmlFor="alternate_phone" className="form-label">Alternate Phone</label>
                                <input type="text" className="form-control" id='alternate_phone' name="alternate_phone" defaultValue={enquiryData?.whatsapp ||editAdmission?.id?editAdmission?.phone:''} />
                              </div>
                            </div>
                            <div className="col-6 mb-2">
                              <div>
                                <label htmlFor="email" className="form-label">Email <span className="text-danger px-1">*</span></label>
                                <input type="email" className="form-control" id='email' name="email" defaultValue={editAdmission?.id?editAdmission?.email:''} disabled={editAdmission?.id}/>
                              </div>
                            </div>
                            <div className="col-6 mb-2">
                              <div>
                                <label htmlFor="alternate_email" className="form-label">Alternate Email</label>
                                <input type="email" className="form-control" id='alternate_email' name="alternate_email" />
                              </div>
                            </div>
                            <div className="col-6 mb-2">
                              <div>
                                <label htmlFor="marital_status" className="form-label">Marital Status <span className="text-danger px-1">*</span></label>
                                <CustomSelect isSearchable name="marital_status" defaultValue={editAdmission?.id?editAdmission?.personal_details.marital_status:''} options={[
                                  { value: 'unmarried', label: 'UnMarried' },
                                  { value: 'married', label: 'Married' },
                                  { value: 'widowed', label: 'widowed' },
                                  { value: 'divorced', label: 'Divorced' },
                                  { value: 'separated', label: 'Separated' },
                                  { value: 'other', label: 'Other' },
                                ]} />
                              </div>
                            </div>
                            <div className="col-6 mb-2">
                              <div>
                                <label htmlFor="adhaar" className="form-label">Adhar Number </label>
                                <input type="number" className="form-control" id='adhaar' name="adhaar" defaultValue={editAdmission?.id?editAdmission?.personal_details.adhaar:''}/>
                              </div>
                            </div>
                            <div className="d-flex align-items-start gap-3 mt-4">
                              <button type="submit" className="btn btn-primary btn-label right ms-auto nexttab nexttab" ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Save & Next</button>
                            </div>
                          </Row>
                        </form>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={2}>
                      <OtherDetail studentId={currentGeneratedStudentId} enquiryData={enquiryData} editAdmission={editAdmission} setActiveStep={setActiveStep}/>
                    </Tab.Pane>
                    <Tab.Pane eventKey={3}>
                      <AddressDetail studentId={currentGeneratedStudentId} enquiryData={enquiryData} editAdmission={editAdmission} setActiveStep={setActiveStep}/>
                    </Tab.Pane>
                    <Tab.Pane eventKey={4}>
                      <QualificationDetail studentId={currentGeneratedStudentId} enquiryData={enquiryData?.user_id} editAdmission={editAdmission} setActiveStep={setActiveStep} />
                    </Tab.Pane>
                    <Tab.Pane eventKey={5}>
                      {editAdmission?''
                      
                      :<EnrollementDetail studentId={currentGeneratedStudentId} setActiveStep={setActiveStep} />}
                    </Tab.Pane>      
                  </Tab.Content>
                </Tab.Container> 
              </Row>
            </CardBody>

          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Admission