import React, { useEffect, useState } from 'react'
import { useModalHandler } from '../../../helper/custom_hook';
import { Modal } from 'react-bootstrap';
import CustomSelect from '../../../components/CustomSelect';
import { batch, course, student } from '../../../helper/api_url';
import { timeFormat } from '../../../helper/formatDate';
import { swal } from '../../../helper/swal';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const EnrollementCourse = ({data,Emp=false,userId,setUserData}) => {
    const {toggleModal,status} = useModalHandler();
    const dispatch=useDispatch();
    const [findCourse,setFindCourse]=useState([]);
    const [findBatch,setFindBatch]=useState([]);
    const navigate = useNavigate();
    useEffect(()=>{
        if(status)
        course.list().then(r => setFindCourse(r.data.course.map((course) => ({ label: course.name, value: course.id, })))).catch(e => console.log(e));
        batch.list().then(r => setFindBatch(r.data.batches.map((batch) => ({ label: batch.name +" ("+timeFormat(batch.start_time)+" to "+timeFormat(batch.end_time) +") ", value: batch.id, })))).catch(e => console.log(e));
    },[status])
    const handleSubmit=(e)=>{
        e.preventDefault();
        dispatch(setPreloader({ loader: true, message: 'please wait...' }))
        const formdata = new FormData(e.target);
        student.enrollement(formdata)
        .then((res)=>{
            swal.success(res.data.message)
            setUserData(res.data.student)
            navigate('/admission-slip', { state: { id:res.data.student.id } });
            e.target.reset()
            dispatch(setPreloader({ loader: false, message: '' }))
        })
        .catch((err)=>{swal.error(err.response ? err.response.data.message : err.message);dispatch(setPreloader({ loader: false, message: '' }))})
    }
  return (
    <>
    {Emp==true?<button onClick={toggleModal} className='btn btn-sm btn-soft-info'><i className="ri-add-line align-bottom me-1"></i><span>Enroll</span></button>:
     <button type='button' onClick={toggleModal}  className="btn btn-sm btn-success btn-end"><i className="bx bx-plus"></i>Course Enrollement</button>
    }
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Course Enrollement</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={(e)=>handleSubmit(e)}>
                        <div className="row g-3">
                            <div className="col-12">
                                <input type="hidden" name="user_id" id='user_id' defaultValue={userId} />
                                <div>
                                    <label htmlFor="course_id" className="form-label">Select Course</label>
                                    <CustomSelect name="course_id" id="course_id" options={findCourse} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div>
                                    <label htmlFor="batch_id" className="form-label">Batch</label>
                                    <CustomSelect name="batch_id" id="batch_id" options={findBatch} />
                                </div>
                            </div>
                           
                            <div className="col-lg-12 mt-4">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
    </>
  )
}
