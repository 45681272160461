import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, CardBody, } from 'react-bootstrap'
import ClientSlider from '../../components/ClientSlider';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/landingCrousel/img1.jpg'
import img2 from '../../assets/images/landingCrousel/img2.jpg'
import img3 from '../../assets/images/landingCrousel/img3.jpg'
import { CourseDetails } from '../../components/LandingPage/CourseDetails';
import { WorkProcess } from '../../components/LandingPage/WorkProcess';
import { OurTeam } from '../../components/LandingPage/OurTeam';
import img from '../../helper/image_helper';
import { AboutUs } from '../../components/LandingPage/AboutUs';
function LandingPage() {
    const crouseImages = [
        img1,
        img2,
        img3,
    ]
    const [sliderPerPage, setSliderPerPage] = useState(3);
    const screenSize = s => {
        let sizes = { sm: 768, md: 992, lg: 1200 }
        return s >= sizes.md ? 'lg' : (s < sizes.sm ? 'sm' : 'md');
    }
    const setSliderPerView = () => {
        const s = screenSize(window.innerWidth);
        setSliderPerPage(s == 'sm' ? 1 : (s == 'md' ? 3 : 4));
    }
    useEffect(() => {
        window.addEventListener('scroll', e => {
            e.preventDefault();
            var e = document.getElementById("navbar");
            e && (50 <= document.body.scrollTop || 50 <= document.documentElement.scrollTop ? e.classList.add("is-sticky") : e.classList.remove("is-sticky")
            );
        });
        window.addEventListener('resize', function () {
            setSliderPerView()
        });
        setSliderPerView();
    }, []);
    return (
        <div className="layout-wrapper landing">
            <nav className="navbar navbar-expand-lg navbar-landing fixed-top" id="navbar">
                <div className="container">
                    <Link to="/" className="navbar-brand">
                        <img src={img.logo} className="card-logo card-logo-dark" alt="logo dark" width="300px" height="50" />
                        <img src={img.logo} className="card-logo card-logo-light" alt="logo light" width="300px" height="50" />
                    </Link>
                    <button className="navbar-toggler py-0 fs-20 text-body" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="mdi mdi-menu"></i>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example">
                            <li className="nav-item">
                                <a className="nav-link active" href="#hero">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#course">Course</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#plans">Plans</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#teams">Team</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#reviews">Reviews</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#about">About us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#contact">Contact</a>
                            </li>
                        </ul>

                        <div className="">
                            <Link to="/login" className="btn btn-primary">Log In</Link>
                        </div>
                    </div>

                </div>
            </nav>

            <div className="vertical-overlay" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent.show"></div>

            <section className="section hero-section" id="hero">
                <div className="bg-overlay bg-overlay-pattern"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-sm-10">
                            <div className="text-center mt-lg-5 mt-5 pt-2">
                                <h1 className="display-6 fw-semibold mb-3 lh-base">Skills, Education Inspires:<br/> <span className="text-success">S.K. Institute Computer Science & Technology </span></h1>
                                <div className="d-flex gap-2 justify-content-center mt-4">
                                    <Link to="/login" className="btn btn-primary">Login <i className="ri-arrow-right-line align-middle ms-1"></i></Link>
                                    <Link to="/" className="btn btn-danger">Send Enquiry <i className="ri-eye-line align-middle ms-1"></i></Link>
                                </div>
                            </div>

                            <div className="mt-4 mt-sm-5 pt-sm-5 mb-sm-n5 demo-carousel">
                                <div className="demo-img-patten-top d-none d-sm-block">
                                    <img src="assets/images/landing/img-pattern.png" className="d-block img-fluid" alt="..." />
                                </div>
                                <div className="demo-img-patten-bottom d-none d-sm-block">
                                    <img src="assets/images/landing/img-pattern.png" className="d-block img-fluid" alt="..." />
                                </div>
                                <div className="carousel slide carousel-fade" data-bs-ride="carousel">
                                    <div className="carousel-inner shadow-lg p-2 bg-white rounded">
                                        {crouseImages.map((img, id) => (
                                            <div className="carousel-item active" key={id} data-bs-interval="2000">
                                                <img src={img} className="d-block w-100" alt="..." style={{ aspectRatio: '4/3', objectFit: 'cover' }} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="position-absolute start-0 end-0 bottom-0 hero-shape-svg">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1440 120">
                        <g mask="url(&quot;#SvgjsMask1003&quot;)" fill="none">
                            <path d="M 0,118 C 288,98.6 1152,40.4 1440,21L1440 140L0 140z">
                            </path>
                        </g>
                    </svg>
                </div>

            </section>



            <div className="pt-5 mt-5">
                <Container>
                    <Row>
                        <Col lg={12}>

                            <div className="text-center mt-5">
                                <h5 className="fs-20">Trusted <span className="text-primary text-decoration-underline">by</span> the world's best</h5>
                                <div className="mt-sm-5 mt-4 mb-sm-5 mb-4">
                                    <ClientSlider sliderPerPage={sliderPerPage} />
                                </div>
                            </div>

                        </Col>
                    </Row>

                </Container>

            </div>


            <CourseDetails />

            <WorkProcess />

            <OurTeam />

            <section className="py-5 position-relative bg-light" id='reviews'>
                <div className="container">
                    <div className="row text-center gy-4">
                        <div className="col-lg-3 col-6">
                            <div>
                                <h2 className="mb-2"><span className="counter-value" data-target="100">10</span><i className='bx bx-plus text-success' /></h2>
                                <div className="text-muted">Projects Completed</div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-6">
                            <div>
                                <h2 className="mb-2"><span className="counter-value" data-target="24">24</span></h2>
                                <div className="text-muted">Win Awards</div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-6">
                            <div>
                                <h2 className="mb-2"><span className="counter-value" data-target="20.3">200</span>k <i className='bx bx-plus text-success' /></h2>
                                <div className="text-muted">Satisfied Student's</div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-6">
                            <div>
                                <h2 className="mb-2"><span className="counter-value" data-target="50">10</span></h2>
                                <div className="text-muted">Employees</div>
                            </div>
                        </div>

                    </div>

                </div>

            </section>

            <section className="section">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="text-center mb-5">
                                <h3 className="mb-3 fw-semibold">COURSE CATEGORIES</h3>
                                <p className="text-muted mb-4 ff-secondary">Popular Topics To Learn</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} className='col-xs-12'>
                            <Card className=''>
                                <CardBody>
                                    <Row>
                                        <Col lg={2} className='col-xs-6'>
                                            <div className="avatar-sm icon-effect mx-auto mb-4">
                                                <div className="avatar-title bg-transparent rounded-circle fs-1">
                                                    <img src={img.icon1} className='' width="50px" height="50px" alt='' />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={9} className='col-xs-6'>
                                            <h3 className="h3 card-title">
                                                <a href="#">Personal Development</a>
                                            </h3>

                                            <span className="card-meta">39 Course</span>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6} className='col-xs-12'>
                            <Card className=''>
                                <CardBody>
                                    <Row>
                                        <Col lg={2} className='col-xs-6'>
                                            <div className="avatar-sm icon-effect mx-auto mb-4">
                                                <div className="avatar-title bg-transparent rounded-circle fs-1">
                                                    <img src={img.icon2} className='' width="50px" height="50px" alt='' />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={9} className='col-xs-6'>
                                            <h3 className="h3 card-title">
                                                <a href="#">Web Development</a>
                                            </h3>

                                            <span className="card-meta">39 Course</span>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6} className='col-xs-12'>
                            <Card className=''>
                                <CardBody>
                                    <Row>
                                        <Col lg={2} className='col-xs-6'>
                                            <div className="avatar-sm icon-effect mx-auto mb-4">
                                                <div className="avatar-title bg-transparent rounded-circle fs-1">
                                                    <img src={img.icon3} className='' width="50px" height="50px" alt='' />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={9} className='col-xs-6'>
                                            <h3 className="h3 card-title">
                                                <a href="#">Advance Excel</a>
                                            </h3>

                                            <span className="card-meta">39 Course</span>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6} className='col-xs-12'>
                            <Card className=''>
                                <CardBody>
                                    <Row>
                                        <Col lg={2} className='col-xs-6'>
                                            <div className="avatar-sm icon-effect mx-auto mb-4">
                                                <div className="avatar-title bg-transparent rounded-circle fs-1">
                                                    <img src={img.icon1} className='' width="50px" height="50px" alt='' />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={9} className='col-xs-6'>
                                            <h3 className="h3 card-title">
                                                <a href="#">Communication Skill</a>
                                            </h3>

                                            <span className="card-meta">4 Step</span>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>

            </section>
            <AboutUs />
            <section className="section" id="contact">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="text-center mb-5">
                                <h3 className="mb-3 fw-semibold">Get In Touch</h3>
                                <p className="text-muted mb-4 ff-secondary">We excel in creating new and smart ideas, and we believe that good ideas should show clear and measurable results</p>
                            </div>
                        </div>
                    </div>


                    <div className="row gy-4">
                        <div className="col-lg-4">
                            <div>
                                <div className='row'>
                                    <div className='col-xs-6'>
                                        <div className="mt-4">
                                            <h5 className="fs-13 text-muted text-uppercase">Office Address 1:</h5>
                                            <div className="ff-secondary fw-semibold">Near Hanumanganj Tiraha Kotwan Road, <br />PIN 221505</div>
                                        </div>
                                        <div className="mt-4">
                                            <h5 className="fs-13 text-muted text-uppercase">Office Address 2:</h5>
                                            <div className="ff-secondary fw-semibold">Hanumanganj Prayagraj ,  India, Uttar Pradesh </div>
                                        </div>
                                    </div>
                                    <div className='col-xs-6'>
                                        <div className="mt-4">
                                            <h5 className="fs-13 text-muted text-uppercase">Contact:</h5>
                                            <div className="ff-secondary fw-semibold">+91 9453360943</div>
                                        </div>
                                        <div className="mt-4">
                                            <h5 className="fs-13 text-muted text-uppercase">Working Hours:</h5>
                                            <div className="ff-secondary fw-semibold">7:00am to 6:00pm</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <div>
                                <form>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label htmlFor="name" className="form-label fs-13">Name</label>
                                                <input name="name" id="name" type="text" className="form-control bg-light border-light" placeholder="Your name*" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label htmlFor="email" className="form-label fs-13">Email</label>
                                                <input name="email" id="email" type="email" className="form-control bg-light border-light" placeholder="Your email*" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="mb-4">
                                                <label htmlFor="subject" className="form-label fs-13">Subject</label>
                                                <input type="text" className="form-control bg-light border-light" id="subject" name="subject" placeholder="Your Subject.." />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="mb-3">
                                                <label htmlFor="comments" className="form-label fs-13">Message</label>
                                                <textarea name="comments" id="comments" rows="3" className="form-control bg-light border-light" placeholder="Your message..."></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 text-end">
                                            <input type="submit" id="submit" name="send" className="submitBnt btn btn-primary" value="Send Message" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <section className="py-5 bg-primary position-relative">
                <div className="bg-overlay bg-overlay-pattern opacity-50"></div>
                <div className="container">
                    <div className="row align-items-center gy-4">
                        <div className="col-sm">
                            <div>
                                <h4 className="text-white mb-0 fw-semibold">Festival offer! Enroll in our computer classes now for exclusive discounts. Elevate your tech skills with this limited-time opportunity!</h4>
                            </div>
                        </div>

                        <div className="col-sm-auto">
                            <div className='text-end me-3'>
                                <a href="#" target="_blank" className="btn bg-gradient btn-danger"><i className="bx bxs-offer align-middle me-1"></i> OFFER</a>
                            </div>
                        </div>

                    </div>

                </div>

            </section>

            <footer className="custom-footer bg-dark py-5 position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mt-4">
                            <div>
                                <div>
                                    <img src={img.logo} alt="logo light" height="50" />
                                </div>
                                <div className="mt-4 fs-13">
                                    <p>Empower your learning journey with SKICST Education</p>
                                    <p className="ff-secondary"> A cutting-edge platform dedicated to enhancing your educational experience.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 ms-lg-auto">
                            <div className="row">
                                <div className="col-sm-4 col-xs-4 mt-4">
                                    <h5 className="text-white mb-0">Company</h5>
                                    <div className="text-muted mt-3">
                                        <ul className="list-unstyled ff-secondary footer-list">
                                            <li><a href="#about">About Us</a></li>
                                            <li><a href="#plans">Plan</a></li>
                                            <li><a href="#teams">Teams</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-xs-4 mt-4">
                                    <h5 className="text-white mb-0">Apps Pages</h5>
                                    <div className="text-muted mt-3">
                                        <ul className="list-unstyled ff-secondary footer-list">
                                            <li><a href="#">Calendar</a></li>
                                            <li><a href="#">Mailbox</a></li>
                                            <li><a href="#contact">Chat</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-xs-4 mt-4">
                                    <h5 className="text-white mb-0">Support</h5>
                                    <div className="text-muted mt-3">
                                        <ul className="list-unstyled ff-secondary footer-list">
                                            <li><a href="#">FAQ</a></li>
                                            <li><a href="#contact">Contact</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row text-center text-sm-start align-items-center mt-5">
                        <div className="col-sm-6">

                            <div>
                                <p className="copy-rights mb-0">
                                    {new Date().getFullYear()} SAJAG. Crafted with- <i className="mdi mdi-heart text-danger"></i> by{" "}
                                    <a href="https://lalkumarmitra.web.app/">Lal Mitra </a>|{" "}
                                    <a href="">Shubham</a>
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="text-sm-end mt-3 mt-sm-0">
                                <ul className="list-inline mb-0 footer-social-link">
                                    <li className="list-inline-item">
                                        <a href="#" className="avatar-xs d-block">
                                            <div className="avatar-title rounded-circle">
                                                <i className="ri-facebook-fill"></i>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="#" className="avatar-xs d-block">
                                            <div className="avatar-title rounded-circle">
                                                <i className="ri-github-fill"></i>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="#" className="avatar-xs d-block">
                                            <div className="avatar-title rounded-circle">
                                                <i className="ri-linkedin-fill"></i>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="#" className="avatar-xs d-block">
                                            <div className="avatar-title rounded-circle">
                                                <i className="ri-google-fill"></i>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="#" className="avatar-xs d-block">
                                            <div className="avatar-title rounded-circle">
                                                <i className="ri-dribbble-line"></i>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className='text-end mx-2 fixed-bottom rounded'>
                <a href='#hero' className="btn btn-danger btn-icon landing-back-top p-3" style={{ zIndex: '9999' }} >
                    <i className="bx bx-up-arrow-alt"></i>
                </a>
            </div>
        </div>
    )
}

export default LandingPage