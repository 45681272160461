import React from 'react'
import { useModalHandler } from '../../../helper/custom_hook';
import { Col, Modal, Row } from 'react-bootstrap';
import { ASSET_URL } from '../../../helper/api_url';
import ImageViewer from '../../../components/ImageViewer';

export const AttendanceModalList = ({ row, month }) => {
    const { status, setStatus, toggleModal } = useModalHandler();
    const imageUrl = ASSET_URL + row?.avatar;
    const MONTHS = [
        { days: '31', value: 1, label: "January" },
        { days: '28', value: 2, label: "February" },
        { days: '31', value: 3, label: "March" },
        { days: '30', value: 4, label: "April" },
        { days: '31', value: 5, label: "May" },
        { days: '30', value: 6, label: "June" },
        { days: '31', value: 7, label: "July" },
        { days: '31', value: 8, label: "August" },
        { days: '30', value: 8, label: "September" },
        { days: '31', value: 10, label: "October" },
        { days: '30', value: 11, label: "November" },
        { days: '31', value: 12, label: "December" }
    ];
    const daysInMonth = MONTHS.filter(i => i.value === month)[0].days;
    const monthName = MONTHS.filter(i => i.value === month)[0].label;
    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-info me-1'><i className="ri-eye-fill" /></button>
            <Modal className="fade" centered={true} show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Attendance list </h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>SUN</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>1</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>8</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>15</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>22</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>29</Col>
                    </Row>
                    <Row>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>MON</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>2</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>9</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>8</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>9</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>10</Col>
                    </Row>
                    <Row>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>TUE</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>3</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>10</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>17</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>24</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>31</Col>
                    </Row>
                    <Row>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>WED</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>4</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>11</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>18</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>25</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>-</Col>
                    </Row>
                    <Row>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>THU</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>5</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>12</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>19</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>26</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>-</Col>
                    </Row>
                    <Row>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>FRI</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>6</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>13</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>20</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>27</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>-</Col>
                    </Row>
                    <Row>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>SAT</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>7</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>14</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>21</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>28</Col>
                       <Col className='p-3 border d-flex justify-content-center align-items-center' xs={2}>-</Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
