import React,{useState} from 'react'
import SimpleBar from 'simplebar-react';
// Import Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState, ContentState, convertFromHTML, convertToRaw} from 'draft-js';
import "../../assets/css/custom_style.css"
export const TextEditor = ({textData,defaultValue}) => {
    const [editorState, setEditorState] = useState(() => {
        const defaultContent = defaultValue?defaultValue:'';
        const blocksFromHTML = convertFromHTML(defaultContent);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        return EditorState.createWithContent(contentState);
      });
      const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
        const editorContent = convertToRaw(newEditorState.getCurrentContent());
        const htmlContent = editorContent.blocks.map((block) => block.text).join('\n');
        textData.current = htmlContent;
      };
    return (
        <>
            <SimpleBar className='custom-scrollbar' id="scrollbar" style={{ maxHeight: "calc(20vh - 30px)" }} >
                <div className="editor-wrapper">
                    <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        placeholder="Type course related description"
                        toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                                options: ['bold', 'italic', 'underline'],
                            },
                            list: {
                                options: ['unordered', 'ordered'],
                            },
                            textAlign: {
                                options: ['left', 'center', 'right'],
                            },
                        }}
                        editorState={editorState}
                        onEditorStateChange={handleEditorChange}
                    />
                </div>
            </SimpleBar>
        </>
    )
}
