import React, { useState } from 'react'
import BreadCrumb from '../../../components/common/BreadCrumb';
import { Row, Col, Card, CardHeader, CardBody } from 'react-bootstrap';
import profileBg from '../../../assets/images/auth-one-bg.jpg'
import { Route, useLocation } from 'react-router-dom';
import { ASSET_URL, followup } from '../../../helper/api_url';
import { formatDate } from '../../../helper/formatDate';
import Error404 from '../../Error/404';
import { FollowUpBtn } from './FollowUpBtn';
import ImageViewer from '../../../components/ImageViewer';
const Modal_profile_image = ({ viewimage }) => {
    return (
        <Row className='my-2 mb-3'>
            <Col lg="4" xs={4} className=' mx-auto text-center'>
                <img src={viewimage} alt='Not image' style={{ width: "100px", aspectRatio: '1/1', objectFit: 'cover', height: "100px", borderRadius: "50%" }} />
            </Col>
        </Row>
    );
}

const EnquiryHistroy = () => {
    const { state } = useLocation();
    // const report = state && state.data;
    const [report, setReport] = useState(state && state.data)
    function refershReport(followup) {
        delete followup.enquiry;
        let report_new = report;
        report_new.followups = [...report_new.followups.map(f => {
            f.status = 'postpond';
            return f;
        }), followup]
        setReport(report_new);
        console.log('Submmited', report_new);
    }
    if (report !== null) {
        let currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`

        const trial_end = report.tryal_date != null ? new Date(report.tryal_date) : null;
        if (trial_end != null) trial_end.setDate(trial_end.getDate() + report.tryal_period);

        const folloupStatus = report.followups[report.followups.length - 1].status;
        return (
            <>
                <BreadCrumb title="History" prevPage="Enquiry" prevPath="/enquiry" />
                <Row>
                    <Col lg={4} xs={12}>
                        <Card>
                            <CardHeader className="d-flex align-items-center justify-content-between">
                                <h5 className="card-title mb-0">Profile History</h5>
                            </CardHeader>
                            <CardBody className="">
                                <ImageViewer image={ASSET_URL + report.avatar}>
                                    <Modal_profile_image viewimage={ASSET_URL + report.avatar} />
                                </ImageViewer>
                                <ul className="list-group">
                                    <li className='list-group-item'>
                                        <Row>
                                            <Col xs={5}><span className='fw-bold'>Candidate Name </span></Col>
                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                            <Col className='text-start' xs={5}>{report.name}</Col>
                                        </Row>
                                    </li>
                                    <li className='list-group-item'>
                                        <Row>
                                            <Col xs={5}><span className='fw-bold'>Father Name </span></Col>
                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                            <Col className='text-start' xs={5}>{report.father_name}</Col>
                                        </Row>
                                    </li>

                                    <li className='list-group-item'>
                                        <Row>
                                            <Col xs={5}><span className='fw-bold'>Qualification </span></Col>
                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                            <Col className='text-start' xs={5}>{report.qualification}</Col>
                                        </Row>
                                    </li>
                                    <li className='list-group-item'>
                                        <Row>
                                            <Col xs={5}><span className='fw-bold'>Gender </span></Col>
                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                            <Col className='text-start' xs={5}>{report.gender}</Col>
                                        </Row>
                                    </li>
                                    <li className='list-group-item'>
                                        <Row>
                                            <Col xs={5}><span className='fw-bold'>Entry Date </span></Col>
                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                            <Col className='text-start' xs={5}>{formatDate(report.created_at)}</Col>
                                        </Row>
                                    </li>
                                    <li className='list-group-item'>
                                        <Row>
                                            <Col xs={5}><span className='fw-bold'>Phone </span></Col>
                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                            <Col className='text-start' xs={5}>{report.phone}</Col>
                                        </Row>
                                    </li>
                                    <li className='list-group-item'>
                                        <Row>
                                            <Col xs={5}><span className='fw-bold'>Whatsapp </span></Col>
                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                            <Col className='text-start' xs={5}>{report.whatsapp}</Col>
                                        </Row>
                                    </li>
                                    <li className='list-group-item'>
                                        <Row>
                                            <Col xs={5}><span className='fw-bold'>Address </span></Col>
                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                            <Col className='text-start' xs={5}>{report.address_1}</Col>
                                        </Row>
                                    </li>
                                    <li className='list-group-item'>
                                        <Row>
                                            <Col xs={5}><span className='fw-bold'>PinCode </span></Col>
                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                            <Col className='text-start' xs={5}>{report.pin}</Col>
                                        </Row>
                                    </li>
                                    <li className='list-group-item'>
                                        <Row>
                                            <Col xs={5}><span className='fw-bold'>State </span></Col>
                                            <Col xs={2}><span className='fw-bold'>:</span></Col>
                                            <Col className='text-start' xs={5}>{report.state}</Col>
                                        </Row>
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={8} xs={12}>
                        <Card>
                            <CardHeader className="d-flex align-items-center justify-content-between">
                                <h5 className="card-title mb-0">Enquiry Status Details</h5>
                            </CardHeader>
                            <CardBody className="">
                                <Row>
                                    <Col lg={6} xs={12}>
                                        <ul className="list-group">
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'>Batch Preffered</span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>{report.preffered_batch}</Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'>FeedBack</span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>{report.feedback ? report.feedback : " - "}</Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'>Enquiry By</span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>{report?.created_by?.first_name} {report?.created_by?.last_name}</Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'>FollowUp Status</span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>
                                                        {/* <span className='badge badge-soft-warning  px-1 ms-1'>{report.followups[report.followups.length-1].status}...</span> */}
                                                        {folloupStatus == "pending" ? <span className='badge badge-soft-warning  px-1 ms-1'>pending...</span> : null}
                                                        {folloupStatus == "deleted" ? <span className='badge badge-soft-danger  px-1 ms-1'>Not Intrested</span> : null}
                                                    </Col>
                                                </Row>
                                            </li>
                                        </ul>
                                    </Col>
                                    <Col lg={6} xs={12}>
                                        <ul className="list-group">
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'>Course Preffered</span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>{report.course.name}</Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'>Trail Status</span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>{(trial_end != null) ? (trial_end > Date.now() ? (<span className='badge badge-soft-success px-1 ms-1'>On Trail</span>)
                                                        : (<span className='badge badge-soft-danger px-1 ms-1'>Trail Expired</span>)) : null}
                                                    </Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'>Trail Start Date</span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>{(trial_end != null) ? formatDate(report.tryal_date) : "-"}
                                                    </Col>
                                                </Row>
                                            </li>
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={5}><span className='fw-bold'>Trail End Date</span></Col>
                                                    <Col xs={2}><span className='fw-bold'>:</span></Col>
                                                    <Col className='text-start' xs={5}>{(trial_end != null) ? formatDate(trial_end) : "-"}
                                                    </Col>
                                                </Row>
                                            </li>

                                        </ul>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader className="d-flex align-items-center justify-content-between">
                                <h5 className="card-title mb-0">Follow Up History</h5>
                            </CardHeader>
                            <CardBody className="">
                                <ul className="verti-timeline list-unstyled" style={{ borderLeft: '3px dashed #eff2f7', margin: '0 10px' }}>
                                    {
                                        (report.followups || []).map((item, index) => (
                                            <>
                                                <li key={index}>
                                                    <div className="flex-shrink-0 d-flex mt-3">
                                                        <i className={`bx bx-right-arrow-circle  font-size-20 pt-1 ${formatDate(formattedDate, item.joining_date) == true ? "bx-fade-right text-danger" : 'text-info'}`} />
                                                        <div className="ms-3 ">
                                                            <h5 className="font-size-10">
                                                                {formatDate(item.created_at)}
                                                                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                                            </h5>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div>{item.remark}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                                {item.status == "pending" ?
                                                    <li key={index + 1}>
                                                        <div className="flex-shrink-0 d-flex mt-3">
                                                            <i className={`bx bx-right-arrow-circle  font-size-20 pt-1 bx-fade-right text-warning text-info`} />
                                                            <div className="ms-3 ">
                                                                <h5 className="font-size-10">
                                                                    {formatDate(item.joining_date)}
                                                                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                                                </h5>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <div><span className='badge badge-soft-warning px-1 ms-1'>{item.status}....</span> <FollowUpBtn onSuccess={refershReport} follow={{ ...item, enquiry: { name: report.name, id: report.id } }} /> </div>
                                                            </div>
                                                        </div>
                                                    </li> :
                                                    (item.status == 'deleted' ?
                                                        <li key={index + 1}>
                                                            <div className="flex-shrink-0 d-flex mt-3">
                                                                <i className={`bx bx-right-arrow-circle  font-size-20 pt-1 bx-fade-right text-danger text-info`} />
                                                                <div className="ms-3 ">
                                                                    <h5 className="font-size-10">
                                                                        {formatDate(item.created_at)}
                                                                        <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                                                    </h5>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <div><span className='badge badge-soft-danger px-1 mx-1'>Not Intrested</span>{report.feedback}</div>
                                                                </div>
                                                            </div>
                                                        </li> :
                                                        ""
                                                    )
                                                }
                                            </>
                                        ))


                                    }
                                </ul>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <>
                <Error404 />
            </>
        )
    }

}
export default EnquiryHistroy;